import React, {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import ReactInputVerificationCode from "react-input-verification-code";
import {useSelector, useDispatch} from "react-redux";
import {FlapperSpinner} from "react-spinners-kit";
import {CardBody, Form, Row} from "reactstrap";
import {createSession} from "../../tools/Tools";

function Validation(props) {

    const dispatch = useDispatch()
    const isMounted = useRef(false);
    const {email, closeModal, forgotValidate} = props
    const [loading, setLoading] = useState(false);
    // const socket = useSelector(state => state.global.socket);
    const auditor = useSelector(state => state.axios.auditor);
    const ipAddress = useSelector(state => state.global.ipAddress);
    const credentials = useSelector(state => state.registerCredentials.credentials);

    const validate = async (keys) => {
        await setLoading(true)
        await auditor.post("users/get_if_keys_validate", {email, keys}).then(async (resp) => {
            toast.success('Code validé')
            if (credentials && !forgotValidate) {
                await createSession(credentials, dispatch, ipAddress, setLoading, closeModal, true)
            }
            setLoading(false)
            closeModal()
        }).catch(error => {
            setLoading(false)
            toast.error("Code invalide");
        })
    }

    useEffect(() => {

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div className="justify-content-center">
            <CardBody>
                <Form>
                    <Row>
                        <div className="m-auto">
                            <div className="custom-styles">
                                <div className="text-center">
                                    <h5>Entrez le code de validation</h5>
                                </div>
                                <ReactInputVerificationCode
                                    autoFocus
                                    placeholder=""
                                    length={6}
                                    onChange={(e) => e.length === 6 && validate(e)}/>
                            </div>
                        </div>
                    </Row>
                    <br/>
                    <Row>
                        <div className="m-auto">
                            <small className="text-center border-bottom">
                                Un code vous a été envoyé par émail
                            </small>
                        </div>
                    </Row>
                    <div className="d-flex mt-4 justify-content-md-center align-items-center">
                        <FlapperSpinner size={30} color="#6bd098" loading={loading}/>
                    </div>
                </Form>
            </CardBody>
        </div>
    );
}

export default Validation;
