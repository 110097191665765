import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {addReservationLastName, addReservationName} from "../../../reducer";
import {changeFields, ITextField} from "../../../tools/Tools";
import {Col, FormGroup, Row} from "reactstrap";

function AddPersonInfo() {

    const dispatch = useDispatch();
    const {name, lastname} = useSelector(state => state.contactForm);

    return (
        <Row className="justify-content-center">
            <Col md="6">
                <FormGroup>
                    {ITextField(
                        'Nom',
                        null,
                        name,
                        (e) => changeFields(null, e, addReservationName, dispatch),
                        "• Champ necessaire"
                    )}
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    {ITextField(
                        'Prénom',
                        null,
                        lastname,
                        (e) => changeFields(null, e, addReservationLastName, dispatch),
                        "• Champ necessaire"
                    )}
                </FormGroup>
            </Col>
        </Row>
    )

}

export default AddPersonInfo;
