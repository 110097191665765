import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Calendar from "../../components/FixedPlugin/Calendar";
import {Row} from "reactstrap";


function Agenda() {

    const navigate = useNavigate();
    const auth = useSelector(state => state.session.authenticated);

    useEffect(() => {
        !auth && navigate('/')
    }, [auth, navigate])

    return (
        <div className="content pt-3">
            <Row className="justify-content-center card-flex-row ml-2 mr-2 mt-2 bg-transparent border-0 overflow-auto"
                 style={{height: "80vh"}}>
                <Calendar/>
            </Row>
        </div>
    );
}

export default Agenda;
