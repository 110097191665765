import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FeedIcon from '@mui/icons-material/Feed';
import {Card, Row} from "reactstrap";
import {useGmailTabItemStyles, useGmailTabsStyles} from '@mui-treasury/styles/tabs';
import {Info, InfoSubtitle, InfoTitle} from '@mui-treasury/components/info';
import {useMusicInfoStyles} from '@mui-treasury/styles/info/music';

const indicatorColors = ['#ed1c24', '#ed1c24', '#ed1c24', '#ed1c24'];

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs({materials, rPolicy, description}) {

    const [bgColor] = useState('#ffffff');
    const classes = useOverShadowStyles({bgColor});
    const [value, setValue] = useState(0);
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const tabItemStyles = useGmailTabItemStyles({color: '#ed1c24'});

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            {!value
                ? <Card className="border-0 pt-2" style={{height: 120, width: '100%'}}>
                    <Row className="justify-content-center">
                        <Info useStyles={useMusicInfoStyles} className="text-center">
                            <InfoTitle>À propos de la fiche</InfoTitle>
                            <InfoSubtitle>{description ? description : 'Pas de description'}</InfoSubtitle>
                        </Info>
                    </Row>
                </Card> : value === 1
                    ? <Card className="border-0 pt-2" style={{height: 120, width: '100%'}}>
                        <Row className="justify-content-center">
                            <Info useStyles={useMusicInfoStyles} className="text-center">
                                <InfoTitle>Materiels</InfoTitle>
                                {materials?.list_of_materials.length && materials?.list_of_materials[0] !== ''
                                    ? <div className="row justify-content-center overflow-auto"
                                           style={{maxHeight: 80}}>
                                        {materials?.list_of_materials.map((val, index) =>
                                            <small
                                                key={index}
                                                className="btn btn-grey text-lowercase text-secondary ml-3 mr-3 r-5">
                                                {val}
                                            </small>
                                        )}
                                    </div>
                                    : <small className="text-secondary">Pas de matériel</small>}
                                {/*<InfoCaption>voir plus</InfoCaption>*/}
                            </Info>
                        </Row>
                    </Card>
                    : <Card className="border-0 pt-2" style={{height: 120, width: '100%'}}>
                        <Row className="justify-content-center">
                            <Info useStyles={useMusicInfoStyles} className="text-center">
                                <InfoTitle>Politique</InfoTitle>
                                <InfoSubtitle>{rPolicy === 'strict' ? 'stricte' : rPolicy}</InfoSubtitle>
                                {/*<InfoCaption>voir plus</InfoCaption>*/}
                            </Info>
                        </Row>
                    </Card>}
            <Box sx={{flexGrow: 1, display: 'flex'}} classes={classes} borderRadius={2}>
                <Tabs
                    value={value}
                    classes={tabsStyles}
                    onChange={handleChange}
                    sx={{borderBottom: 1, borderColor: 'divider'}}
                >
                    <Tab classes={tabItemStyles} icon={<FeedIcon/>} {...a11yProps(0)} />
                    <Tab classes={tabItemStyles} icon={<HomeRepairServiceIcon/>} {...a11yProps(1)} />
                    <Tab classes={tabItemStyles} icon={<LocalPoliceIcon/>} {...a11yProps(2)} />
                </Tabs>
            </Box>
        </div>
    );
}
