import React, {useImperativeHandle, forwardRef, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {addWallet, addWalletTransfers} from "../../../reducer";
import {checkErrorMessage, useWindowSize} from "../../../tools/Tools";
import {international_payout_minimum, national_payout_minimum} from "../../../tools/Variables";
import SkeletonRectangular from "../../skeletonCustom/SkeletonRectangular";
import PayOut from "./PayOut";

/* eslint-disable-next-line no-empty-pattern */
export const  Wallet =  forwardRef(({}, ref) => {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const {wallets, transfers, selected} = useSelector(state => state.wallet);
    const [out, setOut] = useState(false);
    const [toPayOut, setToPayOut] = useState(0);
    const [loading, setLoading] = useState(false);
    const [orangeM, setOrangeM] = useState(false);
    const [walletCurrency, setWalletCurrency] = useState('');
    const [walletCurrencyCode, setWalletCurrencyCode] = useState('');
    const auditor = useSelector(state => state.axios.auditor);
    const {countries, countryCode} = useSelector(state => state.global);
    const {banking} = useSelector(state => state.profile);
    const wallet = wallets[selected]
    const [walletPayoutConfigs, setWalletPayoutConfigs] = useState({
        'national_payout_min': 0,
        'international_payout_min': 0
    });

    const checkIfPayoutExist = () => {
        let toPayOutNb = Number(toPayOut)

        if (!banking['id'] && !orangeM) {
            setLoading(false)
            toast.error("Veuillez Enregistrer Votre RIB dans la Rubrique Coordonnées bancaires")
        } else if (!wallet.balance) {
            setLoading(false)
            toast.error("Avant de retirer des fonds, veuillez attendre que votre solde soit positif.")
        } else if (wallet.balance < parseFloat(toPayOut).toFixed(2)) {
            setLoading(false)
            toast.error("Nous ne pouvons pas faire ce retrait")
        } else {
            if (orangeM) {
                return true
            } else {
                let bankingCountryCode = banking.iban.slice(0, 2).toUpperCase()
                let isNational = bankingCountryCode === countryCode
                let msg = 'Le montant minimal autorisé pour un retrait est de '
                if (isNational && walletPayoutConfigs.national_payout_min > toPayOutNb) {
                    setLoading(false)
                    toast.error(
                        msg + walletPayoutConfigs.national_payout_min + ' ' + walletCurrency + '.'
                    )
                } else if (!isNational && walletPayoutConfigs.international_payout_min > toPayOutNb) {
                    setLoading(false)
                    toast.error(
                        msg + walletPayoutConfigs.international_payout_min + ' ' + walletCurrency + '.'
                    )
                } else {
                    return true
                }
            }
        }
        return false;
    }

    const payOutToBankAccount = () => {
        setLoading(true)
        if (checkIfPayoutExist()) {
            auditor.post(apis.payOut +  '/' + parseFloat(toPayOut).toFixed(2) + '/' + selected + '/' + walletCurrencyCode).then(resp => {
                let new_wallet = resp.data;
                let tmpWallet = [...wallets]
                tmpWallet[selected] = new_wallet
                dispatch(addWallet(tmpWallet))
                setOut(false)
                setLoading(false)
                toast.success("Le transfert de fonds s'est déroulé avec succès.")
            }).catch(error => {
                setLoading(false)
                let errorMessage = checkErrorMessage(error);
                toast(errorMessage.message)
            })
        }
    }

    const payOutToOrangeMoney = () => {
        setLoading(true)
        if (checkIfPayoutExist()) {
            auditor.post(apis.payOutOrangeMoney +  '/' + parseFloat(toPayOut).toFixed(2) + '/' + selected).then(resp => {
                let data = resp.data;
                let tmpWallet = [...wallets]
                tmpWallet[selected] = data['wallet']
                dispatch(addWallet(tmpWallet))
                let tmpTransfers = [...transfers]
                tmpTransfers.push(data['new_transfer'])
                dispatch(addWalletTransfers(tmpTransfers))
                setOrangeM(false)
                setLoading(false)
                toast.success("Le transfert de fonds a été accompli avec succès et arrivera sous peu.")
            }).catch(error => {
                setLoading(false)
                let errorMessage = checkErrorMessage(error);
                toast(errorMessage.message)
            })
        }
    }

    const checkWalletOptions = () => {
        let walletCountry = countries?.filter(c => c.currencies.code === wallet.currency)[0]
        setWalletCurrency(walletCountry.currencies.symbol)
        let tmpCurrencyCode = walletCountry.currencies.code
        setWalletCurrencyCode(tmpCurrencyCode)

        if (tmpCurrencyCode in national_payout_minimum && tmpCurrencyCode in international_payout_minimum) {
            setWalletPayoutConfigs({
                ...walletPayoutConfigs,
                national_payout_min: national_payout_minimum[tmpCurrencyCode],
                international_payout_min: international_payout_minimum[tmpCurrencyCode]
            });
        } else {
            setWalletPayoutConfigs({
                ...walletPayoutConfigs,
                international_payout_min: international_payout_minimum[tmpCurrencyCode]
            });
        }

    }

    useEffect(() => {
        wallet?.currency && checkWalletOptions()

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [wallet?.currency])

    useImperativeHandle(ref, () => ({
        toPayOut() {
            setOut(true)
        },
        toOrangeMoney() {
            setOrangeM(true)
        }
    }));

    return (
        <div className="card border-0 bg-transparent overflow-hidden">

            <Modal size="md" show={out}>
                <PayOut
                    loading={loading}
                    setModal={setOut}
                    toPayOut={toPayOut}
                    setToPayOut={setToPayOut}
                    payOut={payOutToBankAccount}
                    walletCurrency={walletCurrency}
                    walletPayoutConfigs={walletPayoutConfigs}/>
            </Modal>

            <Modal size="md" show={orangeM}>
                <PayOut
                    orangeMoney
                    loading={loading}
                    toPayOut={toPayOut}
                    setModal={setOrangeM}
                    setToPayOut={setToPayOut}
                    payOut={payOutToOrangeMoney}
                    walletCurrency={walletCurrency}
                    walletPayoutConfigs={walletPayoutConfigs}/>
            </Modal>

            {loading
                ? <div className="card-content">
                    <div className="card-body cleartfix">
                        <SkeletonRectangular width="100%" height={61}/>
                    </div>
                </div>
                : <div className="pl-4 pr-4 pt-2 pb-2">
                    <h4 className="text-bold-300 mt-2">
                        <small>{walletCurrency}&nbsp;</small>
                        {width < 600 ? <small>{wallet?.balance}</small> : wallet?.balance}
                    </h4>
                </div>}
        </div>
    )
});
