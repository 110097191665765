import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useState} from "react"
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {CardFooter, CardHeader, Row} from "reactstrap";
import {switchContactFormProcess} from "../../reducer";
import {checkErrorMessage} from "../../tools/Tools";
import Timer from "../../tools/Countdown/timer";


function OrangeMoney({orangeResponse, setOrange, sendContactCard}) {

    const dispatch = useDispatch();
    const isDev = useSelector(state => state.global.isDev);
    const [load, setLoad] = useState(false);
    const {service, process} = useSelector(state => state.toContact);
    const auditor = useSelector(state => state.axios.auditor);

    const checkValidationOrangeMoney = async () => {
        setLoad(true);
        let data = {
            "amount": isDev ? 1 : service.contact_amount,
            "order_id": orangeResponse['order_id'],
            "pay_token": orangeResponse['pay_token']
        }
        auditor.post("payments/orange_money_status", data).then(async (resp) => {
            setLoad(false);
            let status = resp.data.status
            if (['INITIATED', 'PENDING'].includes(status)) {
                toast("Le paiement est en cours, je vous prie d'attendre jusqu’à la fin", {duration: 4000});
            } else if (['EXPIRED', 'FAILED'].includes(status)) {
                toast.error("Le paiement a échoué, veuillez recommencer.")
                await setOrange(false)
            } else {
                await dispatch(switchContactFormProcess(true))
                await setOrange(false)
                sendContactCard(orangeResponse['payment_url'], orangeResponse['order_id'])
            }
        }).catch((error) => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    return (<>
            <CardHeader>
                <Row className="justify-content-center mt-2">
                    <span className="mr-4" style={{marginTop: 1.2}}>Expire dans</span>
                    <Timer timerData={{days: 0, hours: 0, minutes: 10, seconds: 0}}/>
                </Row>
            </CardHeader>
            <iframe height={700} title="orange money payment" src={orangeResponse.payment_url}/>
            <CardFooter>
                <Row className="justify-content-center">
                    <small>
                        Veuillez appuyer sur le bouton continuer après la confirmation de votre paiement.
                    </small>
                </Row>
                <Row className="justify-content-center">
                    <Tooltip title="Annuler la transaction" placement="top">
                        <IconButton
                            size="small"
                            disabled={load || process}
                            aria-label="settings"
                            onClick={() => setOrange(false)}
                            sx={{marginRight: 1}}>
                            <CloseIcon sx={{color: "red!important"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Envoyer la prise contact après validation" placement="top">
                        <IconButton
                            size="small"
                            disabled={load}
                            aria-label="settings"
                            sx={{marginRight: 1}}
                            onClick={() => checkValidationOrangeMoney()}>
                            <DoneAllIcon sx={{color: "lightGreen!important"}}/>&nbsp;
                            Continuer
                        </IconButton>
                    </Tooltip>
                </Row>
            </CardFooter>
        </>
    )
}

export default OrangeMoney;
