import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormGroup, Col, Row} from "reactstrap";
import {
    addServiceFormCities,
    addServiceFormCountry,
    addServiceFormDesc, addServiceFormNbArtist,
    addServiceFormThematics,
    addServiceFormTitle
} from "../../reducer";
import {
    changeFields,
    IMultiSelectTools,
    ISelectSearch,
    ISelectTools,
    ITextField,
    searchCity
} from "../../tools/Tools";

function ServiceInfoForm({newService, upDate, newArtist}) {

    const dispatch = useDispatch();
    const [countryId, setCountryId] = useState(0);
    const [listOfCity, setListOfCity] = useState([]);
    const [thematicList, setThematicList] = useState([]);
    const [listOfCountry, setListOfCountry] = useState([]);
    const auditor = useSelector(state => state.axios.auditor);
    const serviceForm = useSelector(state => state.serviceForm);
    const countries = useSelector(state => state.global.countries);
    const countryLog = useSelector(state => state.global.countryLog);
    const artistTypes = useSelector(state => state.global.artistTypes);
    const user_type = useSelector(state => state.artistFromData.user_type);
    const number_of_artists = useSelector(state => state.serviceForm.number_of_artists);

    const searchCountry = (countryToSearch) => {
        if (countryToSearch) {
            let tmp = []
            /* eslint-disable-next-line array-callback-return */
            countries.filter(country => {
                if (country.name.toLowerCase().includes(countryToSearch.toLowerCase()))
                    tmp.push(country.name)
            })
            setListOfCountry(tmp.slice(0, 10))
        }
    }

    const onChangeCountry = (e, value) => {
        if (value) {
            dispatch(addServiceFormCountry(value))
            let countryChoice = countries.filter(country => country.name === value)[0]
            setCountryId(countryChoice['id'])
            dispatch(addServiceFormCities([]));
        }
    }

    useEffect(() => {
        if (!countryId && countryLog && !upDate) {
            setTimeout(() => {dispatch(addServiceFormCountry(countryLog))})
            let countryChoice = countries.filter(country => country.name === countryLog)[0]
            setCountryId(countryChoice['id'])
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [countryLog, serviceForm.country, upDate]);

    useEffect(() => {
        let t = user_type ? user_type : 'artist_musician';
        setThematicList(artistTypes[artistTypes.findIndex(tmp => tmp.name === t)]?.description);
    }, [artistTypes, user_type]);

    return (
        <div className="justify-content-center pr-2">

            <Row className="justify-content-center">
                <Col md={12}>
                    <FormGroup>
                        {ITextField(
                            'Titre',
                            null,
                            serviceForm.title,
                            (e) => changeFields(null, e, addServiceFormTitle, dispatch),
                            newArtist
                                ? '• Un titre distinctif pour se démarquer des autres.'
                                : null)}
                    </FormGroup>
                </Col>
                {/* Non disponible pour le moment - Pour l'offre premium */}
                <Col md={12} hidden>
                    <FormGroup>
                        {ISelectTools(
                                "Veuillez choisir le pays",
                                serviceForm.country,
                                searchCountry,
                                listOfCountry,
                                (e, value) => onChangeCountry(e, value), null)
                        }
                    </FormGroup>
                </Col>
                {/* Non disponible pour le moment - Pour l'offre premium */}
                <Col md={12} hidden>
                    <FormGroup>
                        {ITextField(
                            "Nombre d'artiste",
                            "number", number_of_artists,
                            (e) => changeFields(null, e, addServiceFormNbArtist, dispatch),
                            "• Nombre d'artiste pendant la prestation"
                        )}
                    </FormGroup>
                </Col>
            </Row>

            <Row className="justify-content-center">
                <Col md={12}>
                    <FormGroup>
                        {ISelectSearch(
                            "Genre",
                            serviceForm.thematics,
                            (e, obj) => changeFields(null, obj, addServiceFormThematics, dispatch),
                            thematicList,
                            true,
                            "• Un ou plusieurs genres susceptibles de correspondre à votre style.")}
                    </FormGroup>
                </Col>
                {/* Non disponible pour le moment - Pour l'offre premium */}
                {/* Que pendant la modification ou la création  */}
                <Col md={12} hidden>
                    <FormGroup>
                        {IMultiSelectTools(
                            "Villes",
                            serviceForm.cities,
                            searchCity,
                            listOfCity,
                            (e, value) => changeFields(null, value, addServiceFormCities, dispatch),
                            "• Par défaut, cette fiche sera visible partout dans le pays. Pour modifier, veuillez ajouter les villes ici.",
                            null,
                            auditor,
                            countryId,
                            setListOfCity)}
                    </FormGroup>
                </Col>
                {!newArtist
                    ? <Col md="12">
                        <FormGroup>
                            <TextField
                                rows={2}
                                multiline
                                variant="filled"
                                value={serviceForm.description}
                                placeholder="Quelques mot pour décrire votre fiche de prestation ?"
                                onChange={(e) => changeFields(null, e, addServiceFormDesc, dispatch)}
                            />
                        </FormGroup>
                    </Col>
                    : null}
            </Row>
        </div>);
}

export default ServiceInfoForm;
