import toast from "react-hot-toast";
import React, {useEffect, useState} from 'react';
import GoogleLogin from 'react-google-login';
import {useSelector} from "react-redux";
import Conf from "../../config/tsconfig";
import {checkErrorMessage} from "../../tools/Tools";

function LoginGoogle(props) {

    const {LogIn, callBack, _setLoad} = props
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const currency = useSelector(state => state.global.globalCurrencyCode);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const auth = useSelector(state => state.session.authenticated);
    const googleData = useSelector(state => state.artistFromData.googleData);

    const googleAuth = async (gProfile, data) => {
        let bodyFormData = new FormData();
        bodyFormData.append('currency', currency);
        bodyFormData.append('google_data', JSON.stringify(data));
        await auditorForm.post("users/gCallback", bodyFormData).then(async (response) => {
            await callBack(response.data)
            setLoading(false)
        }).catch((error) => {
            _setLoad(false)
            setLoading(false)
            if (checkErrorMessage(error).message === "You need Password") {
                toast.error("Un mot de passe est requis pour ce compte.")
            } else {
                toast.error(checkErrorMessage(error).message);
            }
        });
        gProfile.disconnect();
    }


    const onSuccess = async (gProfile) => {
        _setLoad && _setLoad(true)
        setLoading(true)
        if (LogIn)
            await googleAuth(gProfile, gProfile["profileObj"])
        else {
            await callBack(gProfile["profileObj"], )
            _setLoad && _setLoad(false)
            setLoading(false)
        }
    };

    const onFailure = (error) => {
        setDisabled(true)
    }

    useEffect(() => {
        auth && setDisabled(true)
    }, [auth]);


    return (
        <GoogleLogin clientId={Conf.configs.GoogleId}
                     isSignedIn={false}
                     disabled={disabled || loading || Object.keys(googleData)?.length !== 0}
                     buttonText={null}
                     cookiePolicy={'single_host_origin'}
                     className="border-0 danger-color loginBtn loginBtn--google width-full text-center pl-3"
                     onSuccess={onSuccess}
                     onFailure={onFailure}>
        </GoogleLogin>
    );
}

export default LoginGoogle;
