const initState = {
    name: '',
    email: '',
    rules: '',
    user_type: '',
    password: '',
    artist_name: '',
    googleData: {},
};

const ArtistFromData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_ARTIST_SIGN_IN_NAME":
            return {
                ...state,
                name: action.data
            };
        case "ADD_ARTIST_SIGN_IN_GOOGLE_DATA":
            return {
                ...state,
                googleData: action.data
            };
        case "ADD_ARTIST_SIGN_IN_RULES":
            return {
                ...state,
                rules: action.data
            };
        case "ADD_ARTIST_SIGN_IN_EMAIL":
            return {
                ...state,
                email: action.data
            };
        case "ADD_ARTIST_SIGN_IN_PASSWORD":
            return {
                ...state,
                password: action.data
            };
        case "ADD_ARTIST_SIGN_IN_ARTIST_NAME":
            return {
                ...state,
                artist_name: action.data
            };
        case "ADD_ARTIST_TYPE":
            return {
                ...state,
                user_type: action.data
            };
        default:
            return state;
    }
};

export default ArtistFromData;
