import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Col, Row} from "reactstrap";
import {insertProfileChecked} from "../../tools/Tools";
import ServiceList from "../services/ServiceList";
import Profile from "./Profile";

function ArtistProfile() {

    let {id} = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const userConnectedId = useSelector(state => state.profile.userId);
    const userId = useSelector(state => state.profileChecked.userId);
    const auth = useSelector(state => state.session.authenticated);

    const checkProfile = async () => {
        if (auth && userConnectedId === parseInt(id))
            navigate(-1)
        else {
            if (parseInt(userId) !== parseInt(id)) {
                await auditor.get("profiles/check_other_profile/" + id).then(async resp => {
                    await insertProfileChecked(dispatch, resp);
                }).catch((e) => navigate("/ArtistNotFound"))
            }
        }
    }

    useEffect(() => {
        checkProfile().then(r => null)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [id])

    return (
        <div className="content pt-3">
            <Row className="mt-4 ml-2 mr-2">
                <Col md="4">
                    <Profile checked/>
                </Col>
                <Col md="8">
                    <ServiceList checked/>
                </Col>
            </Row>
        </div>
    );
}

export default ArtistProfile;
