import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import {useGmailListItemStyles} from "@mui-treasury/styles/listItem/gmail";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useChatzInfoStyles} from "@mui-treasury/styles/info/chatz";
import {CardFooter, Col, Row, Spinner} from "reactstrap";
import {
    addProfileCheckedServices,
    addProfileConditions,
    addProfileServices,
    addServiceFormRefundPolicy,
    addServiceFormTravelExp
} from "../../reducer";
import {
    btnStyle,
    checkErrorMessage, cleanServiceFormData, deleteInObject,
    deleteNullInObj,
    dispatchService,
    fillInBodyForm, fillServiceFormData,
    generateBodyFormOfGallery,
    generateUnitData, validateExpenses
} from "../../tools/Tools";
import {AddService} from "./AddService";
import RefundPolicy from "./RefundPolicy";
import ServiceCardHeader from "./ServiceCardHeader";
import ServiceListHeader from "./ServiceListHeader";
import TravelExpense from "./TravelExpense";

function ServiceList({checked}) {

    const dispatch = useDispatch();
    const addUpServiceRef = useRef();
    const [up, setUp] = useState(false);
    const [add, setAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [activeLine, setActiveLine] = useState(1);
    const [policySet, setPolicySet] = useState(false);
    const [travelSet, setTravelSet] = useState(false);
    const serviceForm = useSelector(state => state.serviceForm);
    const {auditor, auditorForm} = useSelector(state => state.axios);
    const conditions = useSelector(state => state.profile.conditions);
    const services = useSelector(state => checked ? state.profileChecked.services : state.profile.services);
    const userId = useSelector(state => checked ? state.profileChecked.userId : state.profile.userId);
    const [serviceMoreActive, setServiceMoreActive] = useState(true);
    const selectedStyles = useGmailListItemStyles({
        color: '#da3125',
        padding: "0 !important",
        marginTop: "10px !important",
        collapsed: false
    });
    const styles = useGmailListItemStyles({
        padding: "0 !important",
        collapsed: false,
        marginTop: "10px !important",
    });

    const openEditService = async (_service) => {
        await fillServiceFormData(dispatch, _service)
        setUp(_service.id)
    }

    const closeEditService = async () => {
        await cleanServiceFormData(dispatch)
        setUp(false)
    }

    const createService = async () => {
        setLoading(true)
        let data = deleteNullInObj(dispatchService(serviceForm, false))
        let bodyFormData = fillInBodyForm(generateBodyFormOfGallery(new FormData(), serviceForm.galleries), data)

        await auditorForm.post("artist_services/newService", bodyFormData).then(async (resp) => {
            let tmp = [...services];
            let _data = resp.data
            _data['unit_of_the_preparation_time'] = generateUnitData(_data.unit_of_the_preparation_time);
            _data['unit_duration_of_the_service'] = generateUnitData(_data.unit_duration_of_the_service);
            tmp.push(resp.data);
            await cleanServiceFormData(dispatch)
            await dispatch(addProfileServices(tmp));
            setLoading(false)
            setAdd(false)
            toast.success("Un nouveau fiche a été ajouter")
        }).catch((error) => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    const updateService = async () => {
        setLoading(true)
        let data = deleteNullInObj(dispatchService(serviceForm, true))
        let bodyFormData = fillInBodyForm(generateBodyFormOfGallery(new FormData(), serviceForm.galleries), data)

        await auditorForm.put("artist_services/update/" + up, bodyFormData).then(async (resp) => {
            let tmp = [...services];
            tmp[tmp.findIndex(tmp => tmp.id === up)] = resp.data;
            await cleanServiceFormData(dispatch)
            await dispatch(addProfileServices(tmp));
            await closeEditService()
            setLoading(false)
            toast.success("Fiche mis à jour")
        }).catch((error) => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        });
    }

    const updateConditionsData = (data, tmp, message) => {
        auditor.put('artist_conditions/update', data).then(async () => {
            await dispatch(addProfileConditions({...tmp}));
            await dispatch(addServiceFormRefundPolicy('flexible'))
            await dispatch(addServiceFormTravelExp({'from': 0, 'to': 0}))
            toast.success(message)
            setPolicySet(false)
            setTravelSet(false)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    const saveTravel = () => {
        setLoading(true)
        let tmp = {...conditions}
        tmp['travel_expenses'] = serviceForm.travel_expenses
        let data = deleteInObject({...tmp});
        updateConditionsData(data, {...tmp}, "Votre frais de transport global a été bien ajuster.")
    }

    const setPolitic = () => {
        setLoading(true)
        let tmp = {...conditions}
        tmp['refund_policy'] = serviceForm.refund_policy
        let data = deleteInObject({...tmp});
        updateConditionsData(data, {...tmp}, "Votre politique de remboursement a été bien ajuster.")
    }

    const fetchMoreService = async () => {
        let _pageNumber = pageNumber + 1
        let link = checked
            ? '/artist_services/check/per_page/' + userId + '/' + _pageNumber
            : '/artist_services/check/per_page/' + _pageNumber
        await auditor.get(link).then(async (resp) => {
            resp.data['user_services']?.length !== 8
                ? await setServiceMoreActive(false)
                : !resp.data['user_services']?.length !== 8
                    ? await setServiceMoreActive(false)
                    : await setServiceMoreActive(true)
            let _f = checked ? addProfileCheckedServices : addProfileServices
            await dispatch(_f([...services, ...resp.data['user_services']]))
            setPageNumber(_pageNumber)
        }).catch(error => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    const fetchService = async () => {
        setLoading(true)
        await auditor.get('/artist_services/check/per_page/' + pageNumber).then(async (resp) => {
            resp.data['user_services']?.length !== 8
                ? await setServiceMoreActive(false)
                : !resp.data['user_services']?.length !== 8
                    ? await setServiceMoreActive(false)
                    : await setServiceMoreActive(true)
            await dispatch(addProfileServices(resp.data['user_services']))
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    useEffect(() => {
        !services.length !== 8 && setServiceMoreActive(false)
        !services.length && userId && !checked && fetchService().then(r => null)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userId, services, checked])

    useEffect(() => {
        //
    }, [])

    return (
        <Row>
            <Modal size="lg" show={add || up}>
                <div>
                    <h5 className="m-auto pt-3 text-center">
                        {add ? "Créer une nouvelle fiche kantobiz" : "Modifications de la fiche " + serviceForm?.title}
                    </h5>
                    <hr/>
                </div>

                <div className="mb-4">
                    <AddService ref={addUpServiceRef} upDate={!add}/>
                </div>

                <CardFooter>
                    <Row className="justify-content-end">
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                size="small"
                                disabled={loading}
                                aria-label="settings"
                                sx={btnStyle('red')}
                                onClick={() => add ? setAdd(false) : closeEditService()}>
                                <CloseIcon sx={{color: "red!important"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Enregister" placement="top">
                            <IconButton
                                size="small"
                                disabled={loading}
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() =>
                                     add && addUpServiceRef?.current?._isValid()
                                        ? createService()
                                        : !add && up && addUpServiceRef?.current?._isValid()
                                            ? updateService() : null}>
                                <small>Enregister&nbsp;</small>
                                {loading
                                    ? <Spinner type="grow" size="sm"/>
                                    : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            <Modal size="md" show={travelSet}>
                <div>
                    <h5 className="m-auto pt-3 text-center">
                        Frais de transport Généraux
                    </h5>
                    <hr/>
                </div>

                <div className="mb-4">
                    <TravelExpense/>
                </div>

                <CardFooter>
                    <Row className="justify-content-end">
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                size="small"
                                disabled={loading}
                                aria-label="settings"
                                sx={btnStyle('red')}
                                onClick={async () => {
                                    await dispatch(addServiceFormTravelExp({'from': 0, 'to': 0}))
                                    setTravelSet(false)
                                }}>
                                <CloseIcon sx={{color: "red!important"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Enregister" placement="top">
                            <IconButton
                                size="small"
                                disabled={loading}
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => validateExpenses(serviceForm.travel_expenses) && saveTravel()}>
                                <small>Enregister&nbsp;</small>
                                {loading
                                    ? <Spinner type="grow" size="sm"/>
                                    : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            <Modal size="md" show={policySet}>
                <div>
                    <h5 className="m-auto pt-3 text-center">
                        Politique de remboursement
                    </h5>
                    <hr/>
                </div>

                <div className="mb-4">
                    <RefundPolicy/>
                </div>

                <CardFooter>
                    <Row className="justify-content-end">
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                size="small"
                                disabled={loading}
                                aria-label="settings"
                                sx={btnStyle('red')}
                                onClick={() => setPolicySet(false)}>
                                <CloseIcon sx={{color: "red!important"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Enregister" placement="top">
                            <IconButton
                                size="small"
                                disabled={loading}
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => setPolitic()}>
                                <small>Enregister&nbsp;</small>
                                {loading
                                    ? <Spinner type="grow" size="sm"/>
                                    : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            {checked
                ? <div
                    className="row justify-content-center radius-1 width-full mt-3 ml-2 mr-2 mb-3 pb-3"
                    style={{borderBottom: "0.5px dashed grey"}}>
                    <TextInfoContent
                        useStyles={useChatzInfoStyles}
                        heading={'FICHE KANTOBIZ '}
                    />
                </div>
                : <ServiceListHeader
                    setAdd={setAdd}
                    checked={checked}
                    setTravelSet={setTravelSet}
                    setPolicySet={setPolicySet}/>
            }

            <Col sm="12" className="m-0 p-0 overflow-auto" style={{height: "65vh"}}>
                <Box className="radius-1" style={{height: '100%'}}>
                <List>
                    {services?.sort(
                        (a, b) => new Date(b.created_at) - new Date(a.created_at),
                    )?.map((service, index) =>
                        <ListItem
                            button
                            key={index}
                            className="border-bottom p-0"
                            onClick={() => setActiveLine(index + 1)}
                            classes={activeLine === index + 1 ? selectedStyles : styles}
                            selected={activeLine === index + 1}>

                            <ServiceCardHeader
                                checked={checked}
                                service={service}
                                openEditService={openEditService}/>

                        </ListItem>)}
                </List>
                <div className="row justify-content-center" hidden={!serviceMoreActive}>
                    <IconButton
                        color="primary"
                        aria-label="directions"
                        onClick={() => fetchMoreService()}>
                        <Tooltip title="Voir plus" placement="bottom">
                            <ArrowDropDownIcon fontSize="large"/>
                        </Tooltip>
                    </IconButton>
                </div>
            </Box>
            </Col>
        </Row>
    );
}

export default ServiceList;
