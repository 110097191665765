import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useWindowSize} from "../../tools/Tools";
import ServiceList from "../services/ServiceList";

function KantoBizCustomCards(props) {

    const navigate = useNavigate();
    const width = useWindowSize();
    const role = useSelector(state => state.profile.role);
    const isAuditor = role === '' ? true : role === "professional_auditor";


    useEffect(() => {

        isAuditor && navigate('/')

    }, [isAuditor, navigate])

    return (
        <div className={`content ${width <= 764 ? "pt-5 mx-2" : "pt-1 mx-5"}`}>
            <ServiceList />
        </div>
    )
}

export default KantoBizCustomCards;
