import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Input, InputGroup, InputGroupText} from "reactstrap";
import {addProfileData} from "../../../reducer";
import {
    changeFields,
    checkErrorMessage,
    fillInBodyForm,
    IMultiSelectTools,
    searchCity
} from "../../../tools/Tools";

function AddDefaultAddress(props) {

    const {closeModal, streetDefault, postalCodeDefault, cityDefault} = props;
    const dispatch = useDispatch();
    const [city, setCity] = useState(cityDefault);
    const [street, setStreet] = useState(streetDefault);
    const [listOfCity, setListOfCity] = useState([]);
    const [postalCode, setPostalCode] = useState(postalCodeDefault);
    const auditor = useSelector(state => state.axios.auditor);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const profileData = useSelector(state => state.profile.profileData);
    const countryLog = useSelector(state => state.global.countryLog);
    const countryLogID = useSelector(state => state.global.countryLogID);
    const [isValid, setIsValid] = useState({
        city: false,
        street: false,
        postalCode: false,
    });

    const addAddress = async () => {
        if (!Object.values(isValid).includes(false)) {
            let tData = {...profileData}
            tData['address'] = street + ", " + postalCode.toString() + " " + city + ", " + countryLog
            const bodyFormData = fillInBodyForm(new FormData(), tData);
            await auditorForm.put("profiles/updateProfile", bodyFormData).then(async resp => {
                await dispatch(addProfileData(resp.data));
                toast.success("Votre adresse a été mis à jour");
                closeModal()
            }).catch(error => {
                toast.error(checkErrorMessage(error).message);
            });
        } else {
            toast.error("Tout les champs sont nécessaire.");
        }
    }

    useEffect(() => {
        setIsValid((state) => ({
            ...state,
            city: city?.length > 0,
            street: street?.length > 0,
            postalCode: postalCode?.length > 0,
        }));
    }, [setIsValid, city, street, postalCode]);

    return (
        <div>
            <InputGroup className="no-border" style={{width: 250}}>
                <Input
                    type="text"
                    value={street}
                    onChange={(e) => changeFields(setStreet, e)}
                    placeholder="Numero et Nom de rue"/>
            </InputGroup>
            <InputGroup className="no-border" style={{width: 250}}>
                <Input
                    type="text"
                    value={postalCode}
                    onChange={(e) => changeFields(setPostalCode, e)}
                    placeholder="Code Postale"/>
            </InputGroup>
            <InputGroup className="no-border" style={{width: 250}}>
                <div style={{width: "100%"}}>
                    {IMultiSelectTools(
                        "Ville",
                        city,
                        searchCity,
                        listOfCity,
                        (e, value) => changeFields(setCity, value),
                        null,
                        null,
                        auditor,
                        countryLogID,
                        setListOfCity,
                        true)}
                </div>
            </InputGroup>
            <InputGroup className="no-border cursor-pointer">
                <InputGroupText className="justify-content-center width-full" onClick={() => addAddress()}>
                    <Tooltip title="Enregister" placement="top">
                        Enregister&nbsp;<i className="nc-icon nc-send text-success"/>
                    </Tooltip>
                </InputGroupText>
            </InputGroup>
        </div>
    )
}

export default AddDefaultAddress;
