import React from "react";
import {useNavigate} from "react-router-dom";
import {Container} from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

function Footer(props) {

    const navigate = useNavigate();

    return (
        <footer className={"footer" + (props.default ? " footer-default" : "")}>
            <Container fluid={props.fluid}>
                <nav className="credits col">
                    <div className="copyright">
                        <div className="row justify-content-center">
                            <div>
                                <Tooltip title="Conditions générales d'utilisations" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/privacy')}>
                                        Conditions générales d'utilisations
                                    </small>
                                </Tooltip>
                                <small>&nbsp;|&nbsp;</small>
                            </div>
                            <div>
                                <Tooltip title="A propos de nous" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/about')}>
                                        A propos de nous
                                    </small>
                                </Tooltip>
                                <small>&nbsp;|&nbsp;</small>
                            </div>
                            <div>
                                <Tooltip title="Foires aux questions" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/faq')}>
                                        Faq
                                    </small>
                                    <small>&nbsp;|&nbsp;</small>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Copyright" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/')}>
                                        KantoBiz &copy; {1900 + new Date().getYear()}
                                    </small>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </nav>
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
