import Tooltip from "@mui/material/Tooltip";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {InputGroupText, Row} from "reactstrap";
import Birthday from '@mui-treasury/components/textField/birthday';
import {addProfileData} from "../../../reducer";
import {changeFields, checkErrorMessage, fillInBodyForm} from "../../../tools/Tools";

function AddBirthDay(props) {

    const {closeModal} = props;
    const dispatch = useDispatch();
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const profileData = useSelector(state => state.profile.profileData);
    const [birth, setBirth] = useState(profileData.birth);

    const addBirth = async (_phone) => {
        let tData = {...profileData}
        tData['birth'] = birth
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm.put("profiles/updateProfile", bodyFormData).then(async resp => {
            await dispatch(addProfileData(resp.data));
            toast.success("Votre date de naissance a été mis à jour");
            closeModal()
        }).catch(error => {
            toast.error(checkErrorMessage(error).message);
        });
    }

    return (
        <div>
            <Birthday label={"Date d'anniversaire"} value={birth} onChange={(e) => changeFields(setBirth, e)}>
                <Birthday.Day
                    placeholder={'DD'}
                    name={'birthday.day'}
                    nextFocus={'birthday.month'}
                />
                <Birthday.Separator />
                <Birthday.Month
                    placeholder={'MM'}
                    name={'birthday.month'}
                    nextFocus={'birthday.year'}
                />
                <Birthday.Separator />
                <Birthday.Year placeholder={'YYYY'} name={'birthday.year'} />
            </Birthday>
            <Row className="justify-content-left ml-1 mt-1">
                <InputGroupText onClick={() => closeModal()} className="mr-1">
                    <Tooltip title="Annuler" placement="top">
                        <i className="nc-icon nc-simple-remove cursor-pointer text-danger"/>
                    </Tooltip>
                </InputGroupText>
                <InputGroupText onClick={() => addBirth()} className="ml-1">
                    <Tooltip title="Enregister" placement="top">
                        <i className="nc-icon nc-send cursor-pointer text-success"/>
                    </Tooltip>
                </InputGroupText>
            </Row>
        </div>
    );
}

export default AddBirthDay;
