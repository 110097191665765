import CakeIcon from "@mui/icons-material/Cake";
import CloseIcon from "@mui/icons-material/Close";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import React, {useState} from "react";
import {CardFooter, Row} from "reactstrap";
import {useWindowSize} from "../../tools/Tools";
import AddBirthDay from "./Edits/AddBirthDay";
import AddPhone from "./Edits/AddPhone";
import ArtistFollow from "./ArtistFollow";

function ProfileFooter({checked, userId, role, setToArtist, toCloseProfile, profileData}) {

    const width = useWindowSize();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [activeBirth, setActiveBirth] = useState(false);
    const id = open ? 'simple-popover' : undefined;
    const [activeContact, setActiveContact] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <CardFooter>
            <hr/>
            <div className="button-container">
                <Row className="justify-content-lg-end">
                    <div className="row justify-content-between m-1">
                        {checked && <ArtistFollow checked id={userId} artistName={profileData.artist_name}/>}
                        <Tooltip title={checked ? "" : "Plus"} placement="top">
                            {checked
                                ? null
                                : <IconButton aria-describedby={id}
                                              variant="contained"
                                              aria-label="search"
                                              sx={{marginRight: 1}}
                                              onClick={handleClick}>
                                    <DragIndicatorIcon fontSize="medium"/>
                                </IconButton>
                            }
                        </Tooltip>
                        {!checked && width < 500 && <InputAdornment position="start" sx={{mt: 2.5}}>
                            <Button
                                id="close_profile"
                                variant="outlined"
                                onClick={() => toCloseProfile()}
                                endIcon={<CloseIcon/>}>
                                ESC
                            </Button>
                        </InputAdornment>}
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="m-2">
                                <MenuItem>
                                    <ListItemIcon>
                                        <ContactPhoneIcon fontSize={activeContact ? 'large' : 'medium'} sx={{mr: 1}}/>
                                        {!activeContact
                                            ? <a href="#setActiveContact" onClick={() => setActiveContact(true)}>
                                                {profileData.phone || "Ajouter un numéro ..."}
                                            </a>
                                            : <AddPhone closeModal={() => setActiveContact(false)}/>}
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <CakeIcon fontSize={activeBirth ? 'large' : 'medium'} sx={{marginRight: 1}}/>
                                        {!activeBirth
                                            ? <a href="#setActiveBirth" onClick={() => setActiveBirth(true)}>
                                                {profileData.birth || "Date de naissance"}
                                            </a>
                                            : <AddBirthDay closeModal={() => setActiveBirth(false)}/>}
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem hidden={role !== 'professional_auditor'} onClick={() => {
                                    setAnchorEl(null);
                                    toCloseProfile()
                                    setToArtist(true);
                                }}>
                                    <ListItemIcon>
                                        <GroupAddIcon sx={{marginRight: 1}}/>
                                        Dévenir artiste KantoBiz
                                    </ListItemIcon>
                                </MenuItem>
                            </div>
                        </Popover>
                    </div>
                </Row>
            </div>
        </CardFooter>
    );
}

export default ProfileFooter;
