import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import React from "react"
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {CardBody, CardFooter, Row} from "reactstrap";
import {addProfileDataContacts} from "../../reducer";
import {checkErrorMessage} from "../../tools/Tools";
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';

function DeclineEvent(props) {

    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const contacts = useSelector(state => state.profile.contacts);
    const {
        setLoad,
        setLoading,
        warnDecline,
        setCloseDiscuss,
        initMessage,
        msgList,
        setWarnDecline,
        toScroll,
        setMsgList
    } = props

    const addContactData = (booked, key, data) => {
        let tmp = data?.sort(
            // @ts-ignore
            (a, b) => new Date(a.reservation.event_date) - new Date(b.reservation.event_date),
        )
        let contactsTmp = {...contacts}
        contactsTmp[booked][key] = tmp
        dispatch(addProfileDataContacts(contactsTmp))
    }

    const cancelEventBooked = async (reservationId) => {
        setLoad(true)
        setLoading(true)
        setWarnDecline(false)
        let _tmp = [...msgList, <ChatMsg side={'right'} messages={["J'annule mon événement."]}/>]
        await setMsgList(_tmp)
        toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        await auditor.put("reservation/auditor_decline/" + reservationId).then(async (resp) => {
            let a = {...contacts};
            let b = a['book']?.accepted?.filter((book) => book.reservation.id !== reservationId)
            b.push(resp.data);
            addContactData('book', 'accepted', b)
            initMessage('book').then(() => toast.success('Vous avez annuler la réservation.'))
            setLoad(false)
            setLoading(false)
            setCloseDiscuss(true)
        }).catch(error => {
            setLoad(false)
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        });
    };

    return (
        <div>
            <CardBody>
                <Box maxWidth={343}>
                    <CardContent>
                        <TextInfoContent
                            useStyles={useN04TextInfoContentStyles}
                            heading={'Êtes vous sûr ?'}
                            overline={"Annulation de l'événement"}
                            body={
                                'Ceci entraînera la fermeture de la discussion sur cette prise de contact.'
                            }
                        />
                    </CardContent>
                </Box>
            </CardBody>
            <CardFooter>
                <Row className="justify-content-center">
                    <IconButton
                        size="small"
                        aria-label="settings"
                        sx={{marginRight: 1}}
                        onClick={() => setWarnDecline(false)}>
                        <CloseIcon sx={{color: "red!important"}}/>
                    </IconButton>
                    <IconButton
                        size="small"
                        aria-label="settings"
                        sx={{marginLeft: 1}}
                        onClick={() => cancelEventBooked(warnDecline)}>
                        <DoneAllIcon sx={{color: "lightGreen!important"}}/>&nbsp;J'annule l'événement
                    </IconButton>
                </Row>
            </CardFooter>
        </div>
    )
}

export default DeclineEvent
