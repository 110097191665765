import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {useGmailListItemStyles} from '@mui-treasury/styles/listItem/gmail';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import {addProfileDataContacts} from "../../reducer";
import NoDataList from "../../tools/NoDataList";
import {checkErrorMessage, getContact, getLength} from "../../tools/Tools";
import SkeletonContactCardHeader from "../skeletonCustom/SkeletonContactCardHeader";
import SkeletonNavContactCardList from "../skeletonCustom/SkeletonNavContactCardList";
import ContactCard from "./ContactCard";
import DashboardHeader from "../reservations/DashboardHeader";
import ContactCardHeader from "./ContactCardHeader";


function ContactCardList({bookedList}) {

    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const tabIndex = useSelector(state => state.contactCards.tabIndex);
    const [booked, setBooked] = useState([]);
    const [toLoad, setToLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeLine, setActiveLine] = useState(1);
    const [toShow, setToShow] = useState(false);
    const [showBook, setShowBook] = useState({});
    const [pendingPage, setPendingPage] = useState(1);
    const [refusedPage, setRefusedPage] = useState(1);
    const [acceptedPage, setAcceptedPage] = useState(1);
    const [pendingMoreActive, setPendingMoreActive] = useState(false);
    const [refusedMoreActive, setRefusedMoreActive] = useState(false);
    const [acceptedMoreActive, setAcceptedMoreActive] = useState(false);
    const link = useSelector(state => bookedList ? 'book' : 'auditor');
    const {contacts, userId, contactsLength} = useSelector(state => state.profile);
    const statuses = ['pending', 'accepted', 'declined'];
    const selectedStyles = useGmailListItemStyles({
        color: '#da3125',
        margin: "5px !important",
        collapsed: false
    });
    const styles = useGmailListItemStyles({
        collapsed: false,
        margin: "5px !important",
    });


    const addReservationToShow = async (book) => {
        setShowBook(book)
        setToShow(true)
    }

    const iconMore = (_pageNumber, _setPageNumber) => {
      return (
          <div className="row justify-content-center mt-2">
              <IconButton
                  color="primary"
                  aria-label="directions"
                  onClick={() => getMore(_pageNumber + 1, _setPageNumber)}>
                  <Tooltip title="Voir plus" placement="bottom">
                      <ArrowDropDownIcon />
                  </Tooltip>
              </IconButton>
          </div>
      )
    }

    const checkMoreIfActive = (key, dataLength, setActive, bookedLengthTmp) => {
        const contactKeyLength = contactsLength[link]?.[key];
        const shouldBeActive = contactKeyLength > dataLength || bookedLengthTmp > dataLength;
        setActive(shouldBeActive);
    };

    const addContactData = (key, data, notBooked) => {

        let tmp = data?.sort((a, b) => new Date(a.reservation.event_date) - new Date(b.reservation.event_date))
        let contactsTmp = {...contacts}
        contactsTmp[link][key] = tmp
        dispatch(addProfileDataContacts(contactsTmp))
        if (!notBooked) {
            setBooked(tmp)
        }
    }

    const getMore = async (pageNumber, setPageNumber) => {

        const status = statuses[tabIndex];
        let lastData = contacts[link]?.[status];

        let tmp = await getContact(auditor, pageNumber, status, link);
        if (tmp?.data?.length) {
            setPageNumber(pageNumber);
            let newData = [...lastData, ...tmp.data];
            checkMoreIfActive(status, newData.length, setPendingMoreActive);
            addContactData(status, newData);
        }
    }

    const updateContactList = async (status, newDataValue) => {
        await getLength(dispatch, auditor, contactsLength, link)

        if (tabIndex === 0) {
            let lastData = contacts[link]?.pending
            let tmp = lastData?.filter((value) => value?.id !== newDataValue?.id)
            addContactData('pending', tmp)

            if (status === 'accepted') {
                let lastDataAccepted = contacts[link]?.accepted
                lastDataAccepted.push(newDataValue)
                addContactData('accepted', lastDataAccepted, true)
            } else if (status === 'declined') {
                let lastDataDeclined = contacts[link]?.declined
                lastDataDeclined.push(newDataValue)
                addContactData('declined', lastDataDeclined, true)
            }

        } else if (tabIndex === 1 && status === 'accepted') {
            let lastData = contacts[link]?.accepted
            let tmp = lastData?.filter((value) => value?.id !== newDataValue?.id)
            tmp.push(newDataValue)
            addContactData('accepted', tmp)
        }

        const toastMessages = {
            accepted: "La prise de contact a été accordée.",
            finish: "Montant ajouté à votre compte avec succès.",
            default: bookedList ? "La prise de contact a été annulée." : "La prise de contact a été déclinée.",
        };

        toast.success(toastMessages[status] || toastMessages.default);
    };

    const updateCardContact = async (bookId, status, setLoad, setWarnDecline) => {
        setLoad(true);
        setToLoad(true);
        setWarnDecline(false);
        auditor.put("contact_cards/update/" + bookId + "/" + status).then(async (resp) => {
            updateContactList(status, resp.data).then(() => {
                setLoad(false);
                setToLoad(false);
                setToShow(false)
            })
        }).catch(error => {
            setLoad(false)
            setToLoad(false)
            toast.error(checkErrorMessage(error).message)
        });
    };

    const checkData = async (tmpTabIndex) => {

        const pages = [pendingPage, acceptedPage, refusedPage];
        const moreActiveSetters = [setPendingMoreActive, setAcceptedMoreActive, setRefusedMoreActive];

        let tIndex = tmpTabIndex ?? tabIndex;
        const status = statuses[tIndex];
        const page = pages[tIndex];
        const setMoreActive = moreActiveSetters[tIndex];

        let dataLength = contacts[link]?.[status]?.length;

        if (dataLength) {
            checkMoreIfActive(status, dataLength, setMoreActive);
            setBooked(contacts[link]?.[status]);
        } else {
            let tmp = await getContact(auditor, page, status, link);
            checkMoreIfActive(status, tmp?.data?.length, setMoreActive, tmp['length']);
            addContactData(status, tmp?.data);
        }
    };

    useEffect(() => {
        if (!booked.length && userId) {
            getLength(dispatch, auditor, contactsLength, link).then(r => null)
            checkData().then((r) => userId && setLoading(false))
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userId, link]);

    return (
        <Row className="justify-content-center">

            <Modal show={toShow}>
                <ContactCard
                    setToShow={setToShow}
                    bookedList={bookedList}
                    ContactCardToChat={showBook}
                    updateContactList={updateContactList}
                    updateCardContact={updateCardContact}/>
            </Modal>

            {loading
                ? <SkeletonNavContactCardList/>
                : <DashboardHeader link={link} checkData={checkData} setToLoad={setToLoad} bookedList={bookedList}/>}

            <Col sm="12" className="m-0 p-0 overflow-auto" style={{height: "65vh"}}>
                <Box style={{height: '100%', width: '100%'}} className="radius-1">
                    <List>
                        {loading || toLoad
                            ? Array.from(Array(6).keys()).map((key, index) =>
                                <ListItem key={key} button>
                                    <SkeletonContactCardHeader/>
                                </ListItem>)
                            : booked?.length
                                ? (<div>
                                    {booked.map((book, index) =>
                                        <ListItem
                                            button
                                            key={index}
                                            className="p-0 border-bottom"
                                            onClick={() => setActiveLine(index + 1)}
                                            classes={activeLine === index + 1 ? selectedStyles : styles}
                                            selected={activeLine === index + 1}>

                                            <ContactCardHeader
                                                book={book}
                                                userId={userId}
                                                bookedList={bookedList}
                                                setShowBook={addReservationToShow}
                                            />

                                        </ListItem>)}
                                    {pendingMoreActive && tabIndex === 0 && iconMore(pendingPage, setPendingPage)}

                                    {acceptedMoreActive && tabIndex === 1 && iconMore(acceptedPage, setAcceptedPage)}

                                    {refusedMoreActive && tabIndex === 2 && iconMore(refusedPage, setRefusedPage)}
                                </div>)
                                : NoDataList("Vide")}
                    </List>
                </Box>
            </Col>
        </Row>
    );
}

export default ContactCardList;
