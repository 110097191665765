const initState = {
    service: {},
    send: false,
    process: false,
    tabIndex: 0,
    currency: '',
    currencySymbol: '',
};

const Data = (state = initState, action) => {
    switch (action.type) {
        case "SWITCH_CONTACT_FORM_PROCESS":
            return {
                ...state,
                process: action.data
            };
        case "ADD_CONTACT_FORM_TO_SEND":
            return {
                ...state,
                send: action.data
            };
        case "ADD_CONTACT_FORM_TABINDEX":
            return {
                ...state,
                tabIndex: action.data
            };
        case "ADD_CONTACT_SERVICE":
            return {
                ...state,
                service: action.data
            };
        case "ADD_CONTACT_CURRENCY":
            return {
                ...state,
                currency: action.data
            };
        case "ADD_CONTACT_CURRENCY_SYMBOL":
            return {
                ...state,
                currencySymbol: action.data
            };
        default:
            return state;
    }
};

export default Data;
