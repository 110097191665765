import InputBase from "@material-ui/core/InputBase";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import {IconButton} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import React, {useState} from "react";
import toast from "react-hot-toast";
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import {useChromeInputStyles} from '@mui-treasury/styles/input/chrome';
import {useSelector} from "react-redux";
import {changeFields, checkErrorMessage, ISelectSearch, switchAnswers, useWindowSize} from "../../tools/Tools";

function ChatFooter({closeDiscuss, currency, setMsgList, msgList, toScroll, setLoading}) {

    const styles = useChromeInputStyles();
    const [textMsg, setTextMsg] = useState('');
    const [quest, setQuest] = useState('');
    const width = useWindowSize();
    const auditor = useSelector(state => state.axios.auditor);
    const contactToChat = useSelector(state => state.global.contactToChat);

    const getBotResponse = async (QuestText) => {
        setLoading(true)
        let _textMsg = QuestText || textMsg
        setTextMsg('')
        let tmp = [...msgList, <ChatMsg side={'right'} avatar={<SmartToyIcon/>} messages={[_textMsg]}/>]
        await setMsgList(tmp)
        toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        auditor.post('/bot/predict/check_bot_response/' + contactToChat?.id, {'user_message': _textMsg}).then(async (resp) => {
            setLoading(false)
            let data = resp?.data
            setMsgList([...tmp, switchAnswers(contactToChat, data, currency)])
            toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        }).catch((error) => {
            setLoading(false)
            let errorMessage = checkErrorMessage(error);
            toast.error(errorMessage)
        })
    }

    const sendQuestionTips = async (obj) => {
        if (obj) {
            await setQuest(obj)
            await getBotResponse(obj)
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && textMsg.length) {
            event.preventDefault();
            getBotResponse().then(r => null)
        }
    }

    return (
        <div>
            <hr/>
            <div className={width < 500 ? "mb-2" : "mb-4"}>
                {ISelectSearch(
                    "Exemples de question",
                    quest,
                    (e, obj) => sendQuestionTips(obj),
                    ["A quelle heure l'artiste devrait arriver ?", "Combien d'artiste"],
                    false,
                    null,
                    'standard',
                    closeDiscuss)}
            </div>
            <InputBase
                onKeyPress={(e) => handleKeyPress(e)}
                value={textMsg}
                className={styles.root}
                style={{width: '100%'}}
                disabled={closeDiscuss}
                placeholder={'Poser votre question'}
                onChange={(e) => changeFields(setTextMsg, e)}
                startAdornment={
                    <Tooltip
                        title="demander moi ce que vous voulez savoir à propos de la réservation ou de l'événement."
                        placement="bottom">
                        <IconButton className={styles.icon}>
                            <InfoOutlined/>
                        </IconButton>
                    </Tooltip>
                }
                endAdornment={
                    <IconButton
                        disabled={closeDiscuss}
                        className={styles.icon}
                        onClick={() => textMsg.length && getBotResponse()}>
                        <small>Envoyez</small>&nbsp;<i className="nc-icon nc-send"/>
                    </IconButton>
                }
            />
        </div>
    );
}

export default ChatFooter;
