import React from "react";
import {Row} from "reactstrap";
import MicDropBro from "../assets/img/MicDropBro.png";
import BoomboxBro from "../assets/img/BoomboxBro.png";
import ChoreographerBro from "../assets/img/ChoreographerBro.png";

function AboutUs() {

    return (
        <div className="content pt-3">
            <Row className="justify-content-center border-0 ml-2 mr-2 mt-2 pl-1 pr-1 bg-transparent overflow-auto"
                 style={{height: "80vh"}}>
                <div className="row align-items-center p-3">
                    <div className="col-lg-6">
                        <h2 className="font-weight-light">A Propos de nous</h2>
                        {/*<p className="font-italic text-muted mt-3 mb-3">*/}
                        {/*   <strong>ISL Creative (Independance Sound Label Creative)</strong>? C'est une entreprise spécialisée dans*/}
                        {/*    les projets musicaux qui propose une large gamme de services dans le domaine de la musique.*/}
                        {/*</p>*/}
                        <p className="font-italic text-muted mb-3">
                            <strong>KantoBiz</strong> est une solution digitale spécialisée dans la mise en relation des
                            artistes
                            (Chanteurs, DJs, Comédiens,…) et du business (les entreprises de l’événementiel). Nous
                            leurs permettront de contacter les artiste et de réserver une ou plusieurs prestations à une
                            date et heure donnée pour un événement particulier (par exemple un mariage, une fête, un
                            événement d’entreprise, un événement associatif, etc…). L’idée est également pour les
                            artistes d’optimiser leurs relations avec leur fan base et de leur permettre de développer
                            leurs notoriétés.
                        </p>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                        <img src={MicDropBro} alt="" className="img-fluid"/>
                    </div>
                </div>
                <div className="row align-items-center p-3 mb-5">
                    <div className="col-lg-6 pl-5 ml-1">
                        <img src={ChoreographerBro} alt=""
                             className="img-fluid mb-4 mb-lg-0"/>
                    </div>
                    <div className="col-lg-5">
                        <i className="fa fa-leaf fa-2x mb-3 text-primary"/>
                        <h3 className="font-weight-light">Notre rôles</h3>
                        <p className="font-italic text-muted">
                            Notre rôle est d’assurer une relation pérenne entre le monde de l’entreprise et le monde du
                            spectacle en mettant à disposition des prestations d'artistes.
                        </p>
                    </div>
                </div>
                <div className="row align-items-center p-3">
                    <div className="col-lg-6 ml-1">
                        <i className="fa fa-bar-chart fa-2x mb-3 text-primary"/>
                        <h3 className="font-weight-light">Nos Valeurs</h3>
                        <p className="font-italic text-muted mb-4">Passion, Respect, Indépendance, Excellence ("PRIE")
                            . Chaque difficulté est pour nous une opportunité de rêver encore plus grand.</p>
                        <p className="font-italic text-muted">
                            <strong>• Passion: </strong>
                            Représente cet état affectif intense et irraisonné que nous avons pour la créativité.
                        </p>
                        <p className="font-italic text-muted">
                            <strong>• Respect: </strong>
                            Nous prenons en considération les actes, les paroles et les actions de nos partenaires pour
                            mieux les accompagner.
                        </p>
                        <p className="font-italic text-muted">
                            <strong>• Indépendance: </strong>
                            Nous accordons une grande importance à l'indépendance car elle est à l'origine de
                            la diversité et de notre originalité.
                        </p>
                        <p className="font-italic text-muted">
                            <strong>• Excellence: </strong>
                            Plusieurs séries de disciplines que nous avons mises en place dans nos équipes
                            pour garantir le succès sur le long terme.
                        </p>
                    </div>
                    <div className="col-lg-5">
                        <img src={BoomboxBro} alt="" className="img-fluid mb-4 mb-lg-0"/>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default AboutUs;
