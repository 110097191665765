import {Column} from "@mui-treasury/components/flex";
import {Info, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import {useNewsInfoStyles} from '@mui-treasury/styles/info/news';
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import logo from "../../assets/img/logo.png";
import {currencySymbolByAddress} from "../../tools/Tools";
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import Button from '@mui/material/Button';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import generatePDF, {Resolution, Margin} from 'react-to-pdf';
dayjs.extend(utc)

const options = {
    // default is `save`
    method: 'open',
    resolution: Resolution.HIGH,
    page: {
        margin: Margin.LARGE * 2,
    },
    canvas: {
        mimeType: 'image/png',
        qualityRatio: 1
    },
    overrides: {
        pdf: {
            compress: true
        },
        canvas: {
            useCORS: true
        }
    },
};

function ContactInvoice({address, ContactCardToChat, countries, bookedList}) {

    const [copied, setCopied] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [currency, setCurrency] = useState('');
    const Invoice = ContactCardToChat?.payment
    const eventReservation = ContactCardToChat?.reservation
    const reference = Invoice?.reference || eventReservation?.payment?.reference


    const copy = () => {
        navigator.clipboard.writeText(reference).then(() => {
            setCopied(true)
            toast.success("Vous avez copier la réference");
        })
    };

    const isMobilePaymentToRefund = () => {

        let contactInvoice = Invoice?.invoice?.split('.');
        let eventInvoice = eventReservation?.payment?.invoice?.split('.');

        if (contactInvoice && Invoice?.refund) {
            return ['https://mpayment'].includes(contactInvoice[0])
        } else if (eventInvoice && eventReservation?.payment?.refund) {
            return ['https://mpayment'].includes(eventInvoice[0])
        }

        return false
    }

    const getTargetElement = () => document.getElementById('contact-card-invoice-to-pdf');

    const downLoadFile = () => {
        setIsPrint(true)
        setTimeout(() => generatePDF(getTargetElement, options), 0)
    }

    const checkTotalAmount = () => {

        if (!eventReservation?.payment?.total_amount && Invoice?.total_amount) {
            return Invoice?.total_amount
        } else if (eventReservation?.payment?.total_amount && !Invoice?.total_amount) {
            return eventReservation?.payment?.total_amount
        } else if (eventReservation?.payment?.total_amount && Invoice?.total_amount) {
            let num = eventReservation?.payment?.total_amount + Invoice?.total_amount
            return Math.round(num * 100) / 100
        }

        return 0
    }

    useEffect(() => {
        !currency && setCurrency(currencySymbolByAddress(eventReservation?.address, countries))
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currency]);

    return (
        <div className="invoice-card">
            <div className="invoice-title">
                <Column gap={2}>
                    <Info useStyles={useNewsInfoStyles}>
                        <InfoTitle>Prise de contact</InfoTitle>
                        <InfoSubtitle>
                            {dayjs(Invoice?.created_at).utc().format("Le DD-MM-YYYY à HH:mm A")}
                        </InfoSubtitle>
                    </Info>
                </Column>
            </div>

            <div className="card" id="contact-card-invoice-to-pdf">
                <div className="card-body">
                    <div className="container">
                        <div className="row d-flex align-items-baseline">
                            <div className="col-8">
                                <p style={{color: "#7e8d9f", fontSize: 18}}>
                                    <span>
                                        ID: #
                                        {isPrint ? reference : reference ? reference?.substring(0, 5) + '...' : '...'}
                                    </span>&nbsp;
                                    {copied
                                        ? <Tooltip title="Vous avez déja copié cette référence" placement="top">
                                            <DoneAllIcon fontSize="small" sx={{color: 'lightGreen!important'}}/>
                                        </Tooltip>
                                        : <Tooltip title="Copier la référence" placement="top">
                                            <ContentCopyIcon
                                                fontSize="small"
                                                onClick={() => reference && copy()}
                                                style={{cursor: "context-menu"}}/>
                                        </Tooltip>}
                                </p>
                            </div>
                            <div className="col-4 text-right" hidden={!bookedList}>
                                {isPrint
                                    ? <div className="logo-img">
                                        <img height={30} width={30} src={logo} alt="react-logo"/>
                                    </div>
                                    : <Tooltip title="Télécharger la facture" placement="top">
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            disabled={!eventReservation?.payment && !Invoice}
                                            onClick={() => downLoadFile()}>
                                            <VerticalAlignBottomOutlinedIcon/>
                                        </Button>
                                    </Tooltip>}
                            </div>
                            <hr/>
                        </div>
                        <div className="container">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <p className="pt-0">KantoBiz Services</p>
                                </div>
                            </div>
                            <div className="row">
                                <ul className="list-unstyled">
                                    <li className="text-muted">
                                        <span style={{color: "#5d9fc5"}}>
                                            {ContactCardToChat?.lastname}&nbsp;{ContactCardToChat?.name}
                                        </span>
                                    </li>
                                    <li className="text-muted">{address.street}</li>
                                    <li className="text-muted">{address.city}&nbsp;{address.postalCode}</li>
                                    <li className="text-muted">
                                        <GpsFixedIcon fontSize="small" sx={{color: "gray !important"}}/>
                                        &nbsp;{address.country}
                                    </li>
                                </ul>
                            </div>
                            <div className="row justify-content-center">
                                <table className="table table-striped table-borderless">
                                    <thead style={{backgroundColor: "#84B0CA"}} className="text-white">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" className="text-right">Service</th>
                                        <th scope="col" className="text-right">Artiste(s)</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr>
                                        <td>
                                            Prise de contact
                                            {Invoice?.refund
                                                ? <RestoreIcon
                                                    fontSize="small"
                                                    sx={{marginLeft: 1, color: "gray !important"}}/>
                                                : null}
                                        </td>
                                        <td className="text-right">
                                            {Invoice?.isl_amount || 0}{currency}
                                        </td>
                                        <td className="text-right">
                                            {(Invoice?.refund ? Invoice?.refunded : Invoice?.artist_amount) || 0}{currency}
                                        </td>
                                    </tr>

                                    {eventReservation?.payment
                                        ? <tr>
                                            <td>
                                                Événement
                                                {eventReservation?.payment?.refund
                                                    ? <RestoreIcon
                                                        fontSize="small"
                                                        sx={{marginLeft: 1, color: "gray !important"}}/>
                                                    : null}
                                            </td>
                                            <td className="text-right">{eventReservation?.payment?.isl_amount}{currency}</td>
                                            <td className="text-right">
                                                {(eventReservation?.payment?.refund
                                                    ? eventReservation?.payment?.refunded
                                                    : eventReservation?.payment?.artist_amount)}
                                                {currency}
                                            </td>
                                        </tr> : null}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row justify-content-end pr-2">
                                {isMobilePaymentToRefund()
                                    ? <small className="text-muted">
                                        Remboursement du paiement mobile sous 7 jours ouvrés.
                                    </small>
                                    : eventReservation?.payment?.refund || Invoice?.refund
                                        ? <small className="text-muted">
                                            Premier remboursement effectué sous 7 jours ouvrés.
                                        </small>
                                        : null}
                                <p className="text-black float-start">
                                    <span className="text-muted mr-3">Total :</span>
                                    <span className="font-weight-bold" style={{fontSize: 18}}>
                                        {checkTotalAmount()}{currency}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactInvoice;
