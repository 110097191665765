import "assets/css/index.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunkMiddleware from "redux-thunk";
import App from "./App";
import config from "./config/tsconfig.json";
import ArtistFromData from "./reducer/ArtistFromData";
import AxiosUSerConfig from "./reducer/axiosConfig/AxiosConf";
import Global from "./reducer/Global";
import {sessionReducer, sessionService} from 'redux-react-session';
import PaymentsData from "./reducer/payments/PaymentsData";
import ProfileChecked from "./reducer/profile/profileChecked";
import ProfileData from "./reducer/profile/profileData";
import RegisterCredentials from "./reducer/RegisterCredentials";
import ContactForm from "./reducer/contact/contactForm";
import Data from "./reducer/contact/data";
import ContactCardsData from "./reducer/contactCards/contactCardsData";
import searchBarData from "./reducer/SearchBarData";
import serviceData from "./reducer/services/ServiceData";
import Services from "./reducer/services/Services";
import "@egjs/react-flicking/dist/flicking.css";
import WalletData from "./reducer/Wallets/WalletData";

axios.defaults.baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? config.configs.ServerApiDev
    : config.configs.ServerApi

const Reducers = combineReducers({
    session: sessionReducer,
    "global": Global,
    "toContact": Data,
    "wallet": WalletData,
    "payment": PaymentsData,
    "profile": ProfileData,
    "serviceResults": Services,
    "axios": AxiosUSerConfig,
    "profileChecked": ProfileChecked,
    "serviceForm": serviceData,
    "searchBarData": searchBarData,
    "contactForm": ContactForm,
    "contactCards": ContactCardsData,
    "artistFromData": ArtistFromData,
    "registerCredentials": RegisterCredentials,
});

const store = createStore(Reducers, undefined, compose(applyMiddleware(thunkMiddleware)));
sessionService.initSessionService(store, {driver: 'COOKIES'});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter >
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
