import NorthIcon from "@mui/icons-material/North";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import {IconButton} from "@mui/material";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner} from "reactstrap";
import Conf from "../../../config/tsconfig.json"
import {addProfileData} from "../../../reducer";
import {changeFields, checkErrorMessage, fillInBodyForm} from "../../../tools/Tools";

function AddPhone({closeModal, handleBack, isValid, validation, payOut}) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const profileData = useSelector(state => state.profile.profileData);
    const [phone, setPhone] = useState(profileData.phone);

    const addPhone = async (_phone) => {
        let tData = {...profileData}
        tData['phone'] = _phone?.toString()
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm.put("profiles/updateProfile", bodyFormData).then(async resp => {
            await dispatch(addProfileData(resp.data));
            toast.success("Votre contact a été mis à jour");
            closeModal ? closeModal() : validation()
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message);
        });
    }

    const phoneVerify = async () => {
        setLoading(true)
        let _phone = phone.substring(phone.length - 9, phone.length)

        await axios.get(Conf.configs.PhoneValidation + '+261' + _phone).then((resp) => {
            let data = resp.data
            if (data.valid) {
                addPhone(data.format?.international)
            } else {
                setLoading(false)
                toast.error("Le numéro que vous avez indiqué est invalide.")
            }
        }).catch((error) => console.log(error))
    }

    const validationNameAndLastName = () => {
      if (payOut || (isValid?.name && isValid?.lastname)) {
          phoneVerify().then(r => null)
      } else {
          toast.error("Le nom et prenom de l'auditeur qui souhaite contacter l'artiste est necessaire.")
      }
    }

    return (
        /* eslint-disable no-useless-concat */
        <Row className={!closeModal && !payOut ? "justify-content-start ml-3" : 'justify-content-center ' + `${payOut ? '' : 'ml-3'}`}>
            {!closeModal ? <small><pre>• Veuillez enregister un numéro Orange.</pre></small> : null}
            <Row className="justify-content-between">
                <InputGroup className="no-border" style={{width: !closeModal ? 250 : 180}}>
                    <Input
                        type="text"
                        value={phone}
                        onChange={(e) => changeFields(setPhone, e)}
                        placeholder={!closeModal ? "Ajouter un numéro orange" : "Numéro de télephone"}/>
                    <InputGroupAddon addonType="append">
                        {closeModal
                            ? <InputGroupText className="cursor-pointer" onClick={() => closeModal()}>
                                <Tooltip title="Annuler" placement="top">
                                    <i className="nc-icon nc-simple-remove cursor-pointer text-danger ml-2"/>
                                </Tooltip>
                            </InputGroupText>
                            : null}
                        <InputGroupText
                            disabled={loading}
                            className="cursor-pointer"
                            onClick={() => !closeModal ? validationNameAndLastName()  : phoneVerify()}>
                            <Tooltip title={!closeModal ? "Enregister et faire le contact" : "Enregister"} placement="top">
                                {loading
                                    ? <Spinner type="grow" size="sm" className="ml-2"/>
                                    : <i className="nc-icon nc-send cursor-pointer text-success ml-2"/>}
                            </Tooltip>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
                {!closeModal && handleBack?
                    <IconButton size="small" className="ml-1 border" sx={{marginBottom: 1}} onClick={() => handleBack()}>
                        <NorthIcon fontSize="small" sx={{color: "black!important"}}/>&nbsp;Retour&nbsp;
                    </IconButton> : null}
            </Row>
        </Row>
    );
}

export default AddPhone;
