import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import {IconButton} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {useDispatch, useSelector} from "react-redux";
import TuneIcon from '@mui/icons-material/Tune';
import {useNavigate} from "react-router-dom";
import {sessionService} from "redux-react-session";
import Conf from "../../../config/tsconfig.json";
import Login from "../../../views/auth/Login";
import Register from "../../../views/auth/Register";
import Box from '@mui/material/Box';
import Notify from "../../FixedPlugin/Notify";
import Account from "../Account";
import {
    addArtistMusicianServicesData,
    addDjServicesData,
    addOthersServicesData,
    addSearchResults,
    changeLoadingResult, configAxios
} from "../../../reducer";
import {cleanAuthData, getPathFromUrl, useWindowSize} from "../../../tools/Tools";
import {useMaterialListItemStyles} from '@mui-treasury/styles/listItem/material';
import ArtistSearch from "./ArtistSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import ServiceSearch from "./ServiceSearch";


function SearchBar() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const width = useWindowSize();
    /* eslint-disable-next-line no-unused-vars */
    const classes = useMaterialListItemStyles();
    const [opened, setOpened] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [loading, setLoading] = useState(false);
    const [thematicsList, setThematicsList] = useState([]);
    const auditor = useSelector(state => state.axios.auditor);
    const searchBarData = useSelector(state => state.searchBarData);
    const serviceResults = useSelector(state => state.serviceResults);
    const countryLog = useSelector(state => state.global.countryLog);
    const artistTypes = useSelector(state => state.global.artistTypes);
    const countryLogID = useSelector(state => state.global.countryLogID);
    const auth = useSelector(state => state.session.authenticated);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const loadingResult = useSelector(state => state.serviceResults.loadingResult);
    const ipAddress = useSelector(state => state.global.ipAddress);


    const dispatchResult = async (results) => {

        let _dj = []
        let _others = []
        let _artistMusician = []

        for (let row in results) {
            if (results[row]['artist_type'] === 'artist_musician') {
                _artistMusician.push(results[row])
            } else if (results[row]['artist_type'] === 'dj') {
                _dj.push(results[row])
            } else {
                _others.push(results[row])
            }
        }

        await dispatch(addDjServicesData(_dj))
        await dispatch(addOthersServicesData(_others))
        await dispatch(addArtistMusicianServicesData(_artistMusician))
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const closeFilter = () => {
        setAnchorEl(null);
    };

    const removeSession = async () => {
        await setLoading(true)
        await sessionService.deleteSession()
        await sessionService.deleteUser()
        await cleanAuthData(dispatch)
        await dispatch(configAxios({ipAddress: ipAddress, token: Conf.configs.TokenVisitor}))
        await setLoading(false)
        toast.success('Vous êtes déconnecté', {
            duration: 6000,
            style: {
                border: '1px solid #6bd098',
                padding: '16px',
                color: '#6bd098',
            },
            iconTheme: {
                primary: '#6bd098',
                secondary: '#FFFAEE',
            },
        });

    }

    const logout = async () => {
        await auditor.delete("users/logout").then(() => {
            removeSession()
        }).catch(() => {
            removeSession()
        });
    };

    const searchServices = async (isRefresh) => {

        let _thematicList = searchBarData.thematics
        if (_thematicList?.length) {
            let tmp = []
            /* eslint-disable-next-line array-callback-return */
            _thematicList.map((val) => {
                /* eslint-disable-next-line array-callback-return */
                artistTypes.map((data) => {
                    if (data.label === val) {
                        tmp.push(data.value)
                    }
                })
            })
            _thematicList = tmp
        }

        await setLoading(true);
        !loadingResult && await dispatch(changeLoadingResult(true))

        let query = {
            "country": countryLog,
            "city": searchBarData.city,
            "event_date": searchBarData.event_date.toISOString(),
            "event": searchBarData.event,
            "thematics": _thematicList
        }

        await auditor.post("service_search/moment", query).then(async resp => {
            const isOpen = open
            !isRefresh && closeFilter();
            let data = resp.data.filter((_service) => !_service.test)
            if (resp.data.length) {
                await dispatchResult(data);
                await dispatch(addSearchResults(data))
            } else {
                toast.error("Aucun fiche KantoBiz trouvé")
            }
            await dispatch(changeLoadingResult(false))
            setLoading(false);

            const pathname = getPathFromUrl(window.location.href);
            if (pathname !== "/" && isOpen) {
                navigate("/")
            }

        }).catch(async err => {
            await dispatch(changeLoadingResult(!serviceResults.loadingResult))
            await dispatch(changeLoadingResult(false))
            setLoading(false);
            toast.error("Erreur, veuillez recommencer.")
        })
    }

    useEffect(() => {
        if (countryLogID) {
            let t = [];
            /* eslint-disable-next-line array-callback-return */
            artistTypes.map((data) => {t.push(data.label)})
            setThematicsList(t)
            searchServices().then(r => null)
        }
        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [artistTypes, countryLogID]);

    useEffect(() => {

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div>
            <div className="ml-auto mr-auto">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                        // border: (theme) => `0.5px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                        color: 'text.secondary',
                    }}
                >
                    <Tooltip title="Trouver une fiche kantobiz" placement="bottom">
                        <IconButton
                            className="border-right border-bottom"
                            sx={{backgroundColor: 'background.paper'}}
                            onClick={(e) => !loading && handleClick(e)}>
                            <TuneIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Chercher un artiste" placement="bottom">
                        <IconButton
                            className="border-right border-bottom"
                            sx={{
                                ml: 0.5,
                                mr: !auth ? 0 : 0.5,
                                backgroundColor: 'background.paper',
                                fontSize: width < 500 ? 13 : 15
                            }}
                            onClick={() => setOpened(true)}>
                            <SearchIcon/>
                            {auth ? <p className="text-secondary">Recherche ...&nbsp;</p> : null}
                            {auth ? <RecentActorsIcon sx={{ml: 0.5}}/> : null}
                        </IconButton>
                    </Tooltip>
                    {!auth ? <>
                        <Tooltip title="Inscription" placement="bottom">
                            <IconButton
                                className="border-right border-bottom text-uppercase"
                                sx={{ml: 0.5, backgroundColor: 'background.paper', fontSize: width < 500 ? 12 : 15}}
                                onClick={() => setIsRegister(true)}>
                                Inscription&nbsp;<PersonAddIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Se connecter" placement="bottom">
                            <IconButton
                                id="login_button"
                                className="border-right border-bottom text-uppercase"
                                sx={{ml: 0.5, backgroundColor: 'background.paper', fontSize: width < 500 ? 12 : 15}}
                                onClick={() => setIsLogin(true)}>
                                Connexion&nbsp;<AccountBoxIcon/>
                            </IconButton>
                        </Tooltip>
                    </> : <Account/>}
                    {auth ? <Notify/> : null}
                    {auth
                        ? <Tooltip title="Déconnexion" placement="bottom">
                            <IconButton sx={{ml: 0.5}} onClick={(e) => !loading && logout(e)}>
                                <LogoutIcon/>
                            </IconButton>
                        </Tooltip>
                        : null}
                </Box>
            </div>

            <Modal size="md" show={isLogin}>
                <Login closeModal={() => setIsLogin(false)}/>
            </Modal>

            <Modal size="md" show={isRegister}>
                <Register closeModal={() => setIsRegister(false)}/>
            </Modal>

            <Modal size="md" show={opened}>
                <ArtistSearch setOpened={setOpened} setLoading={setLoading}/>
            </Modal>

            <Modal size="md" show={open}>
                <ServiceSearch
                    loading={loading}
                    searchServices={searchServices}
                    thematicsList={thematicsList}
                    closeFilter={closeFilter}/>
            </Modal>

        </div>
    );
}

export default SearchBar;
