import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {addProfileData} from "../../../reducer";
import {useDispatch, useSelector} from "react-redux";
import {Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {changeFields, checkErrorMessage, fillInBodyForm} from "../../../tools/Tools";

function AddGender(props) {

    const {closeModal} = props;
    const dispatch = useDispatch();
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const profileData = useSelector(state => state.profile.profileData);
    const [gender, setGender] = useState(profileData.gender);

    const upDateProfileGender = async () => {
        let tData = {...profileData}
        tData['gender'] = gender
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm.put("profiles/updateProfile", bodyFormData).then(async resp => {
            await dispatch(addProfileData(resp.data));
            toast.success("Votre genre à été mis a jour sur le profil.");
            closeModal()
        }).catch(error => {
            toast.error(checkErrorMessage(error).message);
        });
    }

    const validate = () => {
        if (!gender) {
            toast.error("Veuillez nous renseigner votre genre.")
        } else upDateProfileGender().then(r => null)
    }
    return (
        <Row className="justify-content-center">
            <InputGroup className="no-border" style={{width: 250}}>
                <Tooltip title="Votre sexe" placement="top">
                    <InfoIcon sx={{margin: 1, color: "gray!important", cursor: "pointer"}}/>
                </Tooltip>
                <Input
                    type="select"
                    name="select"
                    value={gender}
                    id="gender"
                    onChange={(e) => changeFields(setGender, e)}>
                    {!gender && <option value="">Non défini</option>}
                    <option value="male">Homme</option>
                    <option value="female">Femme</option>
                </Input>
                <InputGroupAddon addonType="append">
                    <InputGroupText onClick={() => closeModal()}>
                        <Tooltip title="Annuler" placement="top">
                            <i className="nc-icon nc-simple-remove cursor-pointer text-danger ml-2"/>
                        </Tooltip>
                    </InputGroupText>
                    <InputGroupText onClick={() => validate()}>
                        <Tooltip title="Enregister" placement="top">
                            <i className="nc-icon nc-send cursor-pointer text-success ml-2"/>
                        </Tooltip>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </Row>
    );
}

export default AddGender;
