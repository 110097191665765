import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import {loadStripe} from '@stripe/stripe-js';
import InfoIcon from "@mui/icons-material/Info";
import Tab from "@material-ui/core/Tab";
import utc from 'dayjs/plugin/utc'
import {Autocomplete, Chip, InputAdornment, styled, TextField} from "@mui/material";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import axios from "axios";
import dayjs from "dayjs";
import {Theme} from "@mui/material/styles";
import React, {useLayoutEffect, useState} from "react";
import toast from "react-hot-toast";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import {sessionService} from "redux-react-session";
import OrangeMoneyLogo from "../assets/img/OrangeMoneyLogo.JPG";
import {
    addArtistSignInArtistName,
    addArtistSignInEmail,
    addArtistSignInName,
    addArtistSignInPass,
    addArtistType,
    addProfileBanking,
    addProfileBankingCurrencies,
    addProfileBankingCurrenciesCode,
    addProfileBankingCurrenciesOriginal,
    addProfileCheckedData,
    addProfileCheckedFollowers,
    addProfileCheckedFollowings,
    addProfileCheckedNotes,
    addProfileCheckedRole,
    addProfileCheckedServices,
    addProfileCheckedUserId,
    addProfileCheckedUsersRated,
    addProfileConditions,
    addProfileData, addProfileDataContacts, addProfileDataContactsLength,
    addProfileFollowers,
    addProfileFollowings,
    addProfileNotes,
    addProfileNotificationLength,
    addProfileNotificationRead,
    addProfileNotificationUnread,
    addProfileRole,
    addProfileServices,
    addProfileUserId,
    addWallet,
    addReservationAddress,
    addReservationCity,
    addReservationEvent, addReservationEventDate, addReservationLastName,
    addReservationName, addReservationPostalCode,
    addReservationPrice,
    addReservationsLength, addReservationTravel,
    addServiceFormCities,
    addServiceFormCountry,
    addServiceFormDesc,
    addServiceFormDurService,
    addServiceFormEvents,
    addServiceFormGalleries,
    addServiceFormHidden,
    addServiceFormMatId,
    addServiceFormNbArtist,
    addServiceFormPrepTime,
    addServiceFormPrice,
    addServiceFormRefundPolicy,
    addServiceFormSpecDate,
    addServiceFormTest,
    addServiceFormThematics,
    addServiceFormTitle,
    addServiceFormTravelExp,
    addServiceFormUnitDurService,
    addServiceFormUnitPrepTime,
    addServiceFormUserId,
    setContactCardToEvents,
    setContactToChat,
    addWalletTransfers, configAxios,
} from "../reducer";
import ErrorMsg from "./ErrorMsg";
import settings from "../config/tsconfig.json"
import NotificationMsg from "./NotificationMsg";
import PassWordHints from "./PassWordHints";
dayjs.extend(utc)


export const btnStyle = (color) => {
    return {
        borderRadius: '0 3px 3px 0',
        background: '#f3f0f0',
        borderLeft: `3px solid ${color}`,
        padding: '2px 4px',
        margin: 0.5,
    }
}

export const changeFields = (setState, e, up_props, dispatch, key, props) => {
    let value
    try {
        value = e.target.value;
    } catch {
        value = e
    }

    setState && setState(value);
    if (up_props && dispatch) {
        if (key && props) {
            props[key] = value;
            dispatch(up_props(props))
        } else dispatch(up_props(value))
    }
};

export const currencySymbolByAddress = (addressOfEvent, countryAllowed) => {
    let tmp = addressOfEvent?.split(', ')
    let countryTmp = tmp[tmp?.length - 1]
    let cur;
    /* eslint-disable-next-line array-callback-return */
    countryAllowed.map((tmpCountry) => {
        if (countryTmp.toLowerCase() === tmpCountry['name'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            || countryTmp.toLowerCase() === tmpCountry['nativeName'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
            cur = tmpCountry?.currencies?.symbol
        }
    });
    return cur
}

export const registerValidation = (name, email, password, rules, pass) => {
    if (!name)
        return {"error": true, message: "Veuillez nous rensigner votre nom"};
    // let phoneRegex = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'
    const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegExp.test(email))
        return {"error": true, message: "Adresse email non valide"};

    if (!rules)
        return {"error": true, message: "Veuillez lire et accetper les termes et conditions"}

    /* eslint-disable no-useless-escape */
    const passwordRe = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.%^(;'\"\|`~,)?/!@#$&*]).{8,}$");
    if (!passwordRe.test(password) && pass)
        return {"error": true, message: "Mot de passe non conforme"};

    return {"error": false}
};

export const passWordValidate = (password, confirmPassword) => {
    const passwordRe = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.%^(;'\"\|`~,)?/!@#$&*]).{8,}$");
    if (!passwordRe.test(password))
        return {"error": true, message: "Mot de passe non conforme"};
    if (password !== confirmPassword)
        return {"error": true, message: "Mot de passe different"};
    return {"error": false}
}

export const calculateValue = (value: number) => {
    switch (true) {
        case (value < 60):
            return value;
        case (value <= 120):
            return 60;
        case (value <= 180):
            return 120;
        case (value <= 240):
            return 180;
        case (value < 300):
            return 240;
        default:
            return 300;
    }
}

export const iTim = (unit, val: number) => {
    if (unit.min) {
        return val;
    }
    if (unit.hours) {
        return Math.floor(val * 60);
    }
    return val;
}

export const reservationChatInfo = (reservation, currency) => {
    return (
        <p className="mb-0">
            Voici quelques détails sur la réservation
            <br/>
            <br/>
            Date : <em><strong>{dayjs(reservation?.event_date).format("DD-MM-YYYY")}</strong></em>
            <br/>
            Heure : <em><strong>{dayjs(reservation?.event_date).format("HH:mm A")}</strong></em>
            <br/>
            Évenement : <em><strong>{reservation?.event}</strong></em>
            <br/>
            Addresse : <em><strong>{reservation?.address}</strong></em>
            <br/>
            Politique : <em><strong>{reservation?.refund_policy}</strong></em>
            <br/>
            Montant proposé : <em><strong>{reservation?.total_amount}{currency}</strong></em>
            <br/>
            {reservation?.travel_expenses
                ? <>
                    Frais de déplacement : <em><strong>{reservation?.travel_expenses}{currency}</strong></em>
                    <br/>
                </> : null}
        </p>)
}

export const reservationChatUpdate = (reservation, currency, userId) => {
    let last_user_updated = reservation?.last_user_updated
    let isArtistConnected = parseInt(reservation?.artist_owner_id) === parseInt(userId)
    let userLastUpdate = parseInt(last_user_updated?.user_id) === parseInt(userId)

    switch (true) {
        case last_user_updated['object'] === 'declined' && isArtistConnected:
            return <p className="mb-0">Cette reservation a été annulée par l'auditeur.</p>
        case last_user_updated['object'] === 'declined' && !isArtistConnected:
            return <p className="mb-0">Vous avez annulé cette proposition.</p>
        case last_user_updated['object'] === 'accepted' && isArtistConnected:
            return <p className="mb-0">
                Vous avez donné votre accord pour cet événement.<br/>
                Il sera dans votre agenda d'ici peu, après validation du processus de paiement par l'auditeur.
            </p>
        case last_user_updated['object'] === 'accepted' && !isArtistConnected:
            return <p className="mb-0">
                L'artiste à donner son accord a votre proposition d'événement.<br/>
                Il ne vous reste plus qu'à entamer le processus de paiement.
            </p>
        case last_user_updated['object'] === 'paid':
            return <p className="mb-0">
                L'événement a été ajouter a votre agenda.<br/>
                {isArtistConnected ? "Le processus de paiement a été validé" : "Le paiement a été validé"}.
            </p>
        case last_user_updated['object'] === 'proposition':
            return <p className="mb-0">
                {isArtistConnected
                    ? userLastUpdate ? 'Votre dernière proposition' : "Une nouvelle proposition a été faite par l'auditeur"
                    : userLastUpdate ? 'Votre dernière proposition' : "Une nouvelle proposition a été faite par l'artiste"}.
                <br/><br/>
                Proposée le : <em>
                <strong>{dayjs(last_user_updated?.modified_at).utc().format("DD-MM-YYYY HH:mm A")}</strong>
            </em><br/>
                Montant proposée : <em><strong>{reservation?.total_amount}{currency}</strong></em>
                <br/><br/>
                Pour répondre aux propositions, Utiliser les boutons en haut.
            </p>
        default:
            return null
    }
}

export const switchAnswers = (contactToChat, data, currency) => {

    const userInfoTags = ['auditor_info', 'artiste_info'];
    const dateTags = [
        'event_date',
        'event_time_to_finish',
        'artiste_arrived_hours',
        'first_contact_update_date',
        'first_contact_creation_date',
        'opening_discuss_time_question',
        'event_creation_date_updated'
    ];

    switch (true) {

        case data?.tag === 'reservation_info':
            return (
                <ChatMsg
                    avatar={<SmartToyIcon/>}
                    messages={[reservationChatInfo(contactToChat?.reservation, currency)]}/>
            )
        case data?.tag === 'service_info':
            return (
                <ChatMsg avatar={<SmartToyIcon/>} messages={[
                    <p className="mb-0">
                        {data?.result}&nbsp;:
                        <br/>
                        <br/>
                        Titre de la fiche : <em><strong>{data?.end?.title}</strong></em>
                        <br/>
                        Nb d'artiste(s) : <em><strong>{data?.end?.number_of_artists}</strong></em>
                        <br/>
                        Êvénement(s) : <em><strong>{data?.end?.events.join(", ")}</strong></em>
                        <br/>
                        Ville(s) : <em><strong>
                        {data?.end?.cities && data?.end?.cities[0] === 'all'
                            ? "Disponible partout"
                            : data?.end?.cities?.join(", ")}
                    </strong></em>
                        <br/>
                        Style(s) : <em><strong>{data?.end?.thematics.join(", ")}</strong></em>
                        <br/>
                        Remboursement : <em><strong>{data?.end?.refund_policy}</strong></em>
                        <br/>
                        Transport : <em><strong>
                        {(data?.end?.travel_expenses?.from && data?.end?.travel_expenses?.to)
                            ? "Entre "
                            + data?.end?.travel_expenses?.from.toString() + currency
                            + " et "
                            + data?.end?.travel_expenses?.to.toString() + currency
                            : data?.end?.travel_expenses?.from.toString() + currency}
                    </strong></em>
                        <br/>
                        Montant(À partir de) : <em><strong>{data?.end?.price}{currency}</strong></em>
                        <br/>
                        Dernière modification : <em><strong>
                        {dayjs(data?.end?.modified_at).utc().format("DD-MM-YYYY à HH:mm A")}
                    </strong></em>
                    </p>
                ]}/>
            )
        case userInfoTags.includes(data?.tag):
            return (
                <ChatMsg
                    avatar={<SmartToyIcon/>}
                    messages={[
                        <p className="mb-0">
                            ----{data?.result}----
                            <br/>
                            <br/>
                            {data?.tag === 'artiste_info' ? "Nom d'artiste" : 'Nom et Prénom'}&nbsp;:&nbsp;
                            <em>
                                <strong>
                                    {data?.tag === 'artiste_info'
                                        ? data?.end?.artist_name
                                        : contactToChat?.name + " " + contactToChat?.lastname
                                    }
                                </strong>
                            </em>
                            <br/>
                            Note(s) : <em><strong>{data?.end?.notes || 0}</strong></em>
                            {data?.tag !== 'artiste_info' && <>
                                <br/>
                                Artiste(s) suivi : <em><strong>{data?.end?.followings || 0}</strong></em>
                            </>}
                            {data?.tag === 'artiste_info' && <>
                                <br/>
                                Nbr fiches : <em><strong>{data?.end?.servicesNbr || 0}</strong></em>
                            </>}
                        </p>
                    ]}/>
            )
        default:
            return (
                <ChatMsg
                    avatar={<SmartToyIcon/>}
                    messages={[
                        <p className="mb-0">
                            <strong hidden={!data?.start}>
                                {data?.start}&nbsp;
                            </strong>
                            {data?.result}
                            <strong hidden={!data?.end} className="text-lowercase">
                                &nbsp;{dateTags.includes(data?.tag)
                                ? dayjs(data?.end).utc().format("DD-MM-YYYY à HH:mm A")
                                : data?.tag === 'invoice'
                                    ? <a href={data.end} target="_blank" rel="noreferrer">
                                        Voir le lien
                                    </a>
                                    : data?.tag === '_total_amount'
                                        ? data?.end?.toString() + currency
                                        : data?.end}.
                            </strong>
                        </p>
                    ]}/>)
    }
}

export const notificationCard = (notify, index, loading, width, openNotify) => {

    const claSSes = makeStyles(({palette}) => ({
        avatarDanger: {
            backgroundColor: '#ED1C24'
        },
        avatarAccept: {
            backgroundColor: palette.success.main,
        },
        action: {
            marginLeft: 8,
        },
    }));

    return (
        <CardHeader
            key={index}
            className="width-full"
            onClick={() => openNotify(notify)}
            classes={{action: claSSes.action}}
            avatar={
                <Avatar aria-label="recipe" className={
                    notify.subject === "accepted"
                        ? claSSes.avatarAccept
                        : claSSes.avatarDanger}>
                    {notify.type === 'contact_card_notify' ? "C" : "R"}
                </Avatar>
            }
            title={
                <strong>{notify.type === 'contact_card_notify' ? "Prise de contact" : "Réservation mis à jour"}</strong>}
            subheader={
                <strong
                    style={{fontSize: '0.78rem'}}
                    className="font-weight-lighter">
                    {NotificationMsg(notify)?.title}
                </strong>}
            action={
                <strong style={{fontSize: '0.78rem'}} className="font-weight-lighter">
                    {dayjs(notify?.created_at).utc().format("DD-MM-YYYY")}
                </strong>}
        />
    )
}

export const addContactData = (booked, key, data, contacts, dispatch) => {
    let tmp = data?.sort(
        // @ts-ignore
        (a, b) => new Date(a.reservation.event_date) - new Date(b.reservation.event_date),
    )
    let contactsTmp = {...contacts}
    contactsTmp[booked][key] = tmp
    dispatch(addProfileDataContacts(contactsTmp))
}

export const upCardEvents = async (contactCard, contacts, contactCardToEvents, dispatch, _type) => {
    let contactsData = contacts[_type]?.accepted
    let tmp = contactsData?.filter((value) => value?.id !== contactCard?.id)
    tmp.push(contactCard)
    await addContactData(_type, 'accepted', tmp, contacts, dispatch)
    let _tmp = [...contactCardToEvents]
    _tmp.push(contactCard)
    dispatch(setContactCardToEvents(_tmp))
    dispatch(setContactToChat(null))
}

export const checkErrorMessage = (error) => {
    try {
        if (error.response.data.email) {
            return {"error": true, message: error.response.data.email[0]}
        } else {
            let message = JSON.stringify(error.response.data).replace(/"/g, '')
            return ErrorMsg(message)
        }
    } catch (e) {
        return {"error": true, message: "Erreur interne"}
    }
};

export const outputHtPrice = (amount) => {
    let serviceFee = (15 * amount) / 100;
    let artistFee = amount - serviceFee;
    return {serviceFee, artistFee};
}

export const orangeMoneyBtn = (tabItem2Styles) => {
    return (
        <Tab
            classes={tabItem2Styles}
            disableTouchRipple
            label={'Orange Money'}
            icon={<img width={22} height={22} src={OrangeMoneyLogo} alt="orange-money-logo"/>}
        />
    );
}

export const getPathFromUrl = (url) => {
    const path = new URL(url).pathname;
    return path === '/' ? '/' : path.slice(1);
}

export const ITextField = (label, type, value, onChangeFunc, helperText, disabled, size, placeholder, hiddenLabel) => {
    return (
        <TextField
            fullWidth
            hiddenLabel={!hiddenLabel}
            size={size || "small"}
            value={value}
            variant="filled"
            placeholder={placeholder || label}
            onChange={onChangeFunc}
            type={type ? type : "text"}
            disabled={disabled}
            autoComplete={type === "password" ? "new-password" : "off"}
            InputProps={
                helperText?.length
                    ? {
                        inputProps: {min: 0},
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={helperText} placement="top">
                                    <InfoIcon sx={{color: "gray!important"}}/>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }
                    : type === "password"
                        ? {
                            inputProps: {min: 0},
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={PassWordHints()} placement="top">
                                        <InfoIcon sx={{color: "gray!important"}}/>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        } : {
                            inputProps: {min: 0},
                        }
            }
        />
    )
}

export const setTo = async (e, dispatch, travel_expenses) => {
    let value
    try {
        value = e.target.value;
    } catch {
        value = e
    }

    let data = {'from': travel_expenses.from, 'to': parseInt(value)}
    await dispatch(addServiceFormTravelExp(data))
}

export const setFrom = async (e, dispatch) => {
    let value
    try {
        value = e.target.value;
    } catch {
        value = e
    }

    let data = {'from': parseInt(value), 'to': 0}
    await dispatch(addServiceFormTravelExp(data))
}

export const deleteNullInObj = (obj) => {
    obj = Object.entries(obj).reduce(
        (acc, [key, value]) => {
            if (value)
                acc[key] = value;
            return acc;
        }, {})
    return obj
}

export const checkUnit = (object) => {

    if (typeof object !== 'object')
        return object

    return Object.keys(object).filter(function (key) {
        return object[key]
    })[0]
};

export const deleteInObject = (object, special_key) => {

    try {
        delete object['id'];
    } catch (e) {
        //
    }

    try {
        delete object['created_at'];
    } catch (e) {
        //
    }

    try {
        delete object['modified_at'];
    } catch (e) {
        //
    }

    special_key && delete object[special_key];

    return object
};

export const generateBodyFormOfGallery = (bodyFormData, PropsFiles) => {
    let galleries = [];
    for (let row in PropsFiles) {
        if (PropsFiles[row]['file'])
            bodyFormData.append('gallery_' + row, PropsFiles[row]['file']);
        if (typeof PropsFiles[row] === "string")
            galleries.push(PropsFiles[row])
    }
    if (galleries?.length !== 0)
        bodyFormData.append('galleries', JSON.stringify(galleries));

    return bodyFormData
};

export const dispatchService = (serviceForm, update) => {
    let tmpService = {};

    if (update) {
        tmpService['user_id'] = serviceForm.user_id;
        tmpService['materials_id'] = serviceForm.materials_id;
    }

    tmpService['special_dates'] = serviceForm.special_dates;
    tmpService['hidden'] = serviceForm.hidden;
    tmpService['title'] = serviceForm.title;
    tmpService['country'] = serviceForm.country;
    tmpService['cities'] = serviceForm.cities?.length ? serviceForm.cities : ['all'];
    tmpService['description'] = serviceForm.description;
    tmpService['refund_policy'] = serviceForm.refund_policy;
    tmpService['events'] = serviceForm.events;
    tmpService['price'] = parseFloat(serviceForm.price).toFixed(2);
    tmpService['preparation_time'] = serviceForm.preparation_time;
    tmpService['number_of_artists'] = serviceForm.number_of_artists;
    tmpService['duration_of_the_service'] = serviceForm.duration_of_the_service;
    tmpService['thematics'] = serviceForm.thematics;
    tmpService['travel_expenses'] = serviceForm.travel_expenses;
    tmpService['unit_of_the_preparation_time'] = checkUnit(serviceForm.unit_of_the_preparation_time);
    tmpService['unit_duration_of_the_service'] = checkUnit(serviceForm.unit_duration_of_the_service);

    return tmpService
}

export const fillInBodyForm = (bodyForm, object, originObj, propsFiles) => {
    if (originObj)
        object = {...originObj, ...deleteNullInObj(object)}

    let newObject = deleteInObject(object)

    /* eslint-disable-next-line array-callback-return */
    Object.keys(newObject).map(key => {
        if (newObject[key] !== 'null' && (newObject[key] || newObject[key] === false)) {
            if (newObject[key] instanceof File) {
                bodyForm.append(key, newObject[key])
            } else if (typeof newObject[key] === 'object') {
                bodyForm.append(key, JSON.stringify(newObject[key]))
            } else bodyForm.append(key, newObject[key])
        }
    })
    if (propsFiles) {
        return generateBodyFormOfGallery(bodyForm, propsFiles)
    }

    return bodyForm
}

export const ISelectSearch = (label, value, onChangeFunc, list, multiple, helperText, variant, disabled) => {
    return (
        <Autocomplete
            getOptionLabel={(option) => option}
            onChange={onChangeFunc}
            filterSelectedOptions
            size="small"
            options={list}
            multiple={multiple}
            value={value}
            disabled={disabled}
            renderInput={(params) => (
                <TextField {...params}
                           variant={variant ? variant : "filled"}
                           label={label}
                           helperText={helperText}/>
            )}
        />
    )
}

export const ISelectTools = (label, value, search, optionList, onChange, helperText, disabled) => {

    return (
        <Autocomplete
            size="small"
            options={optionList}
            value={value}
            disabled={disabled}
            onChange={onChange}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" key={index} label={option} size="small"{...getTagProps({index})}/>
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText={helperText}
                    label={label}
                    variant="filled"
                    onChange={e => search(e.target.value)}
                />
            )}
        />
    )
}

export const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

export const dateVerify = (eventDate, service) => {
    let artistAvailable = true
    if (service?.special_dates)
        Object.keys(service?.special_dates).forEach((value) => {
            if (service?.special_dates[value]['hidden']) {
                let sep = value.split('/');
                let tmp = new Date(parseInt(sep[2]), parseInt(sep[0]) - 1, parseInt(sep[1]) + 1, 0, 0, 0, 0)
                let dToS = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate(), 0, 0, 0, 0)
                if (dToS.getTime() === tmp.getTime()) {
                    artistAvailable = false
                }
            }
        });
    return artistAvailable
}

export const checkStripePromise = (isDev, setStripPromise, auditor, amount, currency, setClientSecret, setLoading) => {
    if (isDev) {
        setStripPromise(loadStripe(settings.configs.StripeKeyDev));
    } else {
        setStripPromise(loadStripe(settings.configs.StripeKeyProd));
    }

    auditor.get("wallet/createPaymentIntent/" + amount.toFixed(2) + "/" + currency.toUpperCase()).then(async (resp) => {
        setClientSecret(resp.data['clientSecret']);
        setLoading(false);
    })
};

export const checkServiceId = async (results, serviceChecked, setLoading, navigate, auditor, id) => {
    let tmp = results?.filter(service => parseInt(service.id) === parseInt(id))
    if (tmp.length) {
        let _s = tmp[0]
        await serviceChecked(_s)
    } else {
        await auditor.get("artist_services/" + id).then(async (resp) => {
            let _s = resp.data.service
            await serviceChecked(_s)
        }).catch(error => {
            navigate('/notFound')
            setLoading(false)
        })
    }
}
export const IPriceField = (label, value, currency, onChange, helperText, placeholder) => {
    return (
        <TextField
            fullWidth
            size="small"
            label={label}
            variant="filled"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            helperText={helperText}
            InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
            }}
        />
    )
}

export function useWindowSize() {
    const [width, setWidth] = useState(0);

    useLayoutEffect(() => {

        function updateSize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateSize);

        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return width;
}

export const checkContactForm = (contactForm, tDate, service, reservationPrice, email) => {
    return {
        "amount": contactForm.price,
        "name": contactForm.name,
        "lastname": contactForm.lastname,
        "reservation": {
            "phone": "0",
            "email": email,
            "city": contactForm.city,
            "postal_code": contactForm.postalCode,
            "event": contactForm.event,
            "event_date": tDate,
            "address": contactForm.address,
            "travel_expenses": contactForm.travelExpenses,
            "services_id": service.id,
            "total_amount": reservationPrice,
            "options_id_list": [],
            "artist_owner_id": service.user_id
        }
    }
}

export const fillContactFormData = async (dispatch, contactForm) => {
    await Promise.all([
        dispatch(addReservationPrice(contactForm.amount)),
        dispatch(addReservationName(contactForm.name)),
        dispatch(addReservationLastName(contactForm.lastname)),
        dispatch(addReservationCity(contactForm.reservation.city)),
        dispatch(addReservationEvent(contactForm.reservation.event)),
        dispatch(addReservationAddress(contactForm.reservation.address)),
        dispatch(addReservationPostalCode(contactForm.reservation.postal_code)),
        dispatch(addReservationTravel(contactForm.reservation.travel_expenses)),
        dispatch(addReservationEventDate(new Date(contactForm.reservation.event_date))),
    ]).then(() => null)
};

export const IMultiSelectTools = (
    label,
    value,
    search,
    optionList,
    onChange,
    helperText,
    variant,
    auditor,
    countryId,
    setListOfCity,
    noMultiple
) => {

    return (
        <Autocomplete
            multiple={!noMultiple}
            size="small"
            options={optionList}
            onChange={onChange}
            value={value}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        size="small"
                        {...getTagProps({index})}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText={helperText}
                    label={label}
                    variant={variant ? variant : "filled"}
                    onChange={e => search(auditor, e.target.value, countryId, setListOfCity)}
                />
            )}
        />
    )
}

export const cleanAuthData = async (dispatch) => {
    await Promise.all([
        dispatch(addWallet({})),
        dispatch(addWalletTransfers([])),
        dispatch(addArtistType('')),
        dispatch(addArtistSignInName('')),
        dispatch(addArtistSignInEmail('')),
        dispatch(addArtistSignInPass('')),
        dispatch(addProfileNotificationLength(0)),
        dispatch(addProfileNotificationRead([])),
        dispatch(addProfileNotificationUnread([])),
        dispatch(addArtistSignInArtistName('')),
        dispatch(addProfileBankingCurrencies([])),
        dispatch(addProfileServices([])),
        dispatch(addProfileNotes(0)),
        dispatch(addProfileUserId(0)),
        dispatch(addProfileData({})),
        dispatch(addProfileRole('')),
        dispatch(addReservationsLength([])),
        dispatch(addProfileFollowers(0)),
        dispatch(addProfileFollowings(0)),
        dispatch(addProfileConditions({})),
        dispatch(addProfileBanking({})),
        cleanServiceFormData(dispatch),
    ]).then(() => null)
};

export const cleanReservationFormData = async (dispatch) => {
    await Promise.all([
        dispatch(addReservationPrice('')),
        dispatch(addReservationName('')),
        dispatch(addReservationCity('')),
        dispatch(addReservationEvent('')),
        dispatch(addReservationAddress('')),
        dispatch(addReservationLastName('')),
        dispatch(addReservationPostalCode('')),
        dispatch(addReservationEventDate('')),
    ]).then(() => null)
};

export const cleanServiceFormData = async (dispatch) => {
    await Promise.all([
        dispatch(addServiceFormMatId(0)),
        dispatch(addServiceFormTest(false)),
        dispatch(addServiceFormCountry('')),
        dispatch(addServiceFormDesc('')),
        dispatch(addServiceFormHidden(false)),
        dispatch(addServiceFormCities(["all"])),
        dispatch(addServiceFormThematics([])),
        dispatch(addServiceFormPrice(0)),
        dispatch(addServiceFormUserId(0)),
        dispatch(addServiceFormEvents([])),
        dispatch(addServiceFormTitle('')),
        dispatch(addServiceFormNbArtist(1)),
        dispatch(addServiceFormPrepTime(0)),
        dispatch(addServiceFormDurService(0)),
        dispatch(addServiceFormGalleries([])),
        dispatch(addServiceFormSpecDate({})),
        dispatch(addServiceFormTravelExp({'from': 0, 'to': 0})),
        dispatch(addServiceFormRefundPolicy('flexible')),
        dispatch(addServiceFormUnitPrepTime({"hours": false, "min": true, "song": false, "song(s)": false})),
        dispatch(addServiceFormUnitDurService({"hours": false, "min": true, "song": false, "song(s)": false})),
    ]).then(() => null)
};

export const fillServiceFormData = async (dispatch, serviceForm) => {

    await Promise.all([
        dispatch(addServiceFormTest(serviceForm.test)),
        dispatch(addServiceFormHidden(serviceForm.hidden)),
        dispatch(addServiceFormCountry(serviceForm.country)),
        dispatch(addServiceFormUserId(serviceForm.user_id)),
        dispatch(addServiceFormDesc(serviceForm.description)),
        dispatch(addServiceFormThematics(serviceForm.thematics)),
        dispatch(addServiceFormPrice(serviceForm.price)),
        dispatch(addServiceFormTravelExp(serviceForm.travel_expenses)),
        dispatch(addServiceFormEvents(serviceForm.events)),
        dispatch(addServiceFormTitle(serviceForm.title)),
        dispatch(addServiceFormMatId(serviceForm.materials_id)),
        dispatch(addServiceFormNbArtist(serviceForm.number_of_artists)),
        dispatch(addServiceFormPrepTime(serviceForm.preparation_time)),
        dispatch(addServiceFormDurService(serviceForm.duration_of_the_service)),
        dispatch(addServiceFormSpecDate(serviceForm.special_dates)),
        dispatch(addServiceFormRefundPolicy(serviceForm.refund_policy)),
        dispatch(addServiceFormGalleries(serviceForm.galleries)),
        dispatch(addServiceFormCities(serviceForm.cities[0] === 'all' ? [] : serviceForm.cities)),
        dispatch(addServiceFormUnitPrepTime(generateUnitData(serviceForm.unit_of_the_preparation_time))),
        dispatch(addServiceFormUnitDurService(generateUnitData(serviceForm.unit_duration_of_the_service))),
    ]).then(() => null)
};

export const searchCity = (auditor, cityToSearch, countryId, setListOfCity) => {
    cityToSearch && auditor.get("countries_and_cities/" + countryId.toString() + '/' + cityToSearch).then((resp) => {
        setListOfCity(resp.data)
    }).catch(() => {
        //
    })
}

export const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const validateExpenses = (travel_expenses) => {
    if (travel_expenses.to < 0 || travel_expenses.from < 0) {
        toast.error('Veuillez mettre un prix supérieur a 0')
        return false
    } else if (!travel_expenses.to) {
        //
    } else {
        if (travel_expenses.from <= 0 && travel_expenses.to <= 0) {
            toast.error('Veuillez mettre un prix supérieur a 0')
            return false
        } else if (travel_expenses.from >= travel_expenses.to) {
            toast.error('le prix maximum ne devrait pas être inferieur au prix minimum')
            return false
        }
    }
    return true
}

export const insertUserData = async (data, dispatch) => {
    await new Promise(resolve => {
        resolve(dispatch(addProfileRole(data['role'])))
        resolve(dispatch(addProfileNotes(data['notes'])))
        resolve(dispatch(addWallet(data['wallet'])))
        resolve(dispatch(addProfileUserId(data['user_id'])))
        resolve(dispatch(addProfileBanking(data['banking'])))
        resolve(dispatch(addProfileData(data['my_profile'])))
        resolve(dispatch(addProfileConditions(data['conditions'])))
        resolve(dispatch(addProfileFollowers(data['my_followers'])))
        resolve(dispatch(addProfileFollowings(data['my_followings'])))
    }).then(err => {
        //
    });
}

export const checkUnReadLen = async (auditor, dispatch) => {
    await auditor.get("notifications/news_length").then(async (resp) => {
        dispatch(addProfileNotificationLength(resp.data))
    })
}

// export const fetchUserData = async (token, dispatch, socket) => {
export const fetchUserData = async (token, dispatch) => {
    let headers = {
        'Content-Type': 'application/json',
        'Language': 'fr',
        'Access-Control-Allow-Origin': "*",
        'Isl-Token': token
    }

    Promise.all([
        axios.get("profiles/my_profile", {headers: headers}).then(async (resp) => {
            // socket.emit('room', resp.data['user_id'])
            let data = resp.data
            const auditor = axios.create({headers: headers})
            let contacts = {
                'book': {
                    'accepted': [],
                    'declined': [],
                    'pending': []
                },
                'auditor': {
                    'accepted': [],
                    'declined': [],
                    'pending': []
                }
            }
            if (data['role'] !== 'professional_auditor') {
                await getContact(auditor, 1, 'accepted', 'book').then(async (book_resp) => {
                    contacts['book']['accepted'] = book_resp.data
                    await getContact(auditor, 1, 'accepted', 'auditor').then((auditor_resp) => {
                        contacts['auditor']['accepted'] = auditor_resp.data
                        dispatch(addProfileDataContacts(contacts))
                    })
                })
            } else {
                await getContact(auditor, 1, 'accepted', 'book').then((resp) => {
                    contacts['book']['accepted'] = resp.data
                    dispatch(addProfileDataContacts(contacts))
                })
            }
            insertUserData(data, dispatch).then(r => null);
        }).catch(err => null),

        axios.get("flags/bank_transfer_countries").then(async (resp) => {
            await dispatch(addProfileBankingCurrencies(resp.data['currency_country']))
            await dispatch(addProfileBankingCurrenciesCode(resp.data['currency_keys']))
            await dispatch(addProfileBankingCurrenciesOriginal(resp.data['currency_original']));
        }).catch(err => null),

        axios.get("notifications/news_length", {headers: headers}).then(async (resp) => {
            await dispatch(addProfileNotificationLength(resp.data))
        }),

    ]).then(() => {
        //
    }).catch(err => {
        //
    })
}

export const generateUnitData = (val) => {
    if (val === "hours")
        return {"hours": true, "min": false, "song": false, "song(s)": false}
    else if (val === "min")
        return {"hours": false, "min": true, "song": false, "song(s)": false}
    else if (val === "song")
        return {"hours": false, "min": false, "song": true, "song(s)": false}
    return {"hours": false, "min": false, "song": false, "song(s)": true}
};

export const validatorBanking = (props, countryCode, country) => {
    if (!props.lastname || !props.name)
        return {"error": true, message: "Veuillez renseigner le Nom et le Prénom du Titulaire."};
    if (!props.iban)
        return {"error": true, message: "Veuillez renseigner l'IBAN"};
    else {
        let re = new RegExp(allRegex[countryCode]);
        if (!re.test(props.iban))
            return {"error": true, message: "Le format de votre iban n'est pas valide pour " + country};
    }
    if (props.swift) {
        let re = new RegExp('([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)');
        if (!re.test(props.swift))
            return {"error": true, message: "Le format de votre BIC n'est pas valide"};
    }
    return {"error": false}
};

export const getContact = async (auditor: any, per_page: number, status: string, type: 'book' | 'auditor') => {
    let data = []

    await auditor
        .get('contact_cards/' + type + '/per_page/' + per_page.toString() + '/' + status)
        .then(async (resp) => {
            data = resp.data;
        }).catch((error) => {
            let msg = checkErrorMessage(error).message
            if (msg !== "404 Not Found: The requested URL was not found on the server. If you entered the URL manually please check your spelling and try again.") {
                toast.error(checkErrorMessage(error).message);
            }
        });

    return data;
}

export const insertProfileChecked = async (dispatch, resp) => {
    await dispatch(addProfileCheckedServices(resp.data['user_data']['user_services']));
    await dispatch(addProfileCheckedUserId(resp.data['user_data']['user_id']))
    await dispatch(addProfileCheckedRole(resp.data['user_data']['role']))
    await dispatch(addProfileCheckedUsersRated({...resp.data['user_data']['users_who_rated']}))
    await dispatch(addProfileCheckedFollowings(resp.data['user_data']['followings']))
    await dispatch(addProfileCheckedFollowers(resp.data['user_data']['followers']))
    await dispatch(addProfileCheckedNotes(resp.data['user_data']['notes']))
    await dispatch(addProfileCheckedData(resp.data['profile_checked']))
}

export const getLength = async (dispatch: any, auditor: any, data: any, type: 'book' | 'auditor') => {
    await auditor
        .get('contact_cards/' + type + '/length')
        .then(async (rsp) => {
            let nt = rsp.data;
            let tmp = {...data}
            tmp[type] = nt
            await dispatch(addProfileDataContactsLength(tmp));
        });
}

export const removePaymentSearchParams = (searchParams, setSearchParams) => {
    searchParams.delete("redirect_status");
    searchParams.delete("payment_intent");
    searchParams.delete("payment_intent_client_secret");
    setSearchParams(searchParams)
}

export const validationNameAndLastName = (isValid, setLoading, validation) => {
    if (isValid.name && isValid.lastname) {
        setLoading(true);
        validation()
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    } else {
        toast.error("Le nom et prenom de l'auditeur qui souhaite contacter l'artiste est necessaire.")
    }
}

export const cardValidation = async (stripe, elements, return_url) => {

    if (!stripe || !elements) {
        return false;
    }

    const confirmPayment = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
            return_url: return_url ? return_url : window.location.href,
        },
    });

    if (confirmPayment.error) {
        toast.error(confirmPayment.error.message);
        return false
    } else {
        return confirmPayment.paymentIntent
    }
}

export const createSession = async (data, dispatch, ipAddress, setLoading, closeModal, isLogin) => {
    const {token} = data;
    await dispatch(configAxios({ipAddress: ipAddress, token: token}))
    await sessionService.saveSession({token});
    await sessionService.saveUser(data);
    isLogin && await fetchUserData(token, dispatch);
    closeModal()
    setLoading(false)
    toast('Bienvenue, vous êtes connecté', {
        icon: '👏',
    });
}

export const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#17dcb8' : '#18ffdc',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const getInfoApexStyles = (arg: Theme | { theme: Theme }) => {
    const {palette} = "theme" in arg ? arg.theme : arg;
    return {
        eyebrow: {},
        title: {
            color: palette.mode === "dark" ? "#b5b1d8" : "#4d4b5f",
            fontFamily: 'Ubuntu, "Helvetica Neue", Arial, sans-serif',
            fontSize: "0.875rem",
            lineHeight: 1.43,
            fontWeight: 700,
            letterSpacing: "0.5px",
            marginBottom: "2px",
        },
        subtitle: {
            color: palette.mode === "dark" ? palette.grey[500] : palette.grey[600],
            fontFamily: 'Ubuntu, "Helvetica Neue", Arial, sans-serif',
            fontSize: "0.75rem",
            lineHeight: 1.4,
            letterSpacing: 0,
        },
    };
};


/* eslint-disable no-useless-escape */
export const allRegex = {
    'AL': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([a-zA-Z0-9]{4}\s?){4}\s?',
    'AD': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([a-zA-Z0-9]{4}\s?){3}\s?',
    'AT': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}\s?',
    'AZ': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([0-9]{4}\s?){5}\s?',
    'BH': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?',
    'BY': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([0-9]{4}\s?){5}\s?',
    'BE': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){3}\s?',
    'BA': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}\s?',
    'BR': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}([0-9]{3})([a-zA-Z]{1}\s?)([a-zA-Z0-9]{1})\s?',
    'BG': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){1}([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){1}([a-zA-Z0-9]{2})\s?',
    'CR': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?',
    'HR': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{1})\s?',
    'CY': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([a-zA-Z0-9]{4}\s?){4}\s?',
    'CZ': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}\s?',
    'DK': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){3}([0-9]{2})\s?',
    'DO': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){5}\s?',
    'TL': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{3})\s?',
    'EE': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}\s?',
    'FO': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){3}([0-9]{2})\s?',
    'FI': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){3}([0-9]{2})\s?',
    'FR': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})([0-9]{2})\s?',
    'GE': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{2})([0-9]{2}\s?)([0-9]{4}\s?){3}([0-9]{2})\s?',
    'DE': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?',
    'GI': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{3})\s?',
    'GR': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{3})\s?',
    'GL': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){3}([0-9]{2})\s?',
    'GT': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([a-zA-Z0-9]{4}\s?){5}\s?',
    'HU': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){6}\s?',
    'IS': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}([0-9]{2})\s?',
    'IE': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([0-9]{4}\s?){3}([0-9]{2})\s?',
    'IL': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{3})\s?',
    'IT': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{1})([0-9]{3}\s?)([0-9]{4}\s?){1}([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{3})\s?',
    'JO': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){5}([0-9]{2})\s?',
    'KZ': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){3}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{2})\s?',
    'XK': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{4}\s?){2}([0-9]{2})([0-9]{2}\s?)\s?',
    'KW': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([a-zA-Z0-9]{4}\s?){5}([a-zA-Z0-9]{2})\s?',
    'LV': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{1})\s?',
    'LB': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([a-zA-Z0-9]{4}\s?){5}\s?',
    'LI': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})\s?',
    'LT': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}\s?',
    'LU': '[a-zA-Z0-9]{2}\s?([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){3}\s?',
    'MK': '[a-zA-Z0-9]{2}\s?([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})([0-9]{2})\s?',
    'MT': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{3})\s?',
    'MR': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}([0-9]{3})\s?',
    'MU': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){4}([0-9]{3})([a-zA-Z]{1}\s?)([a-zA-Z]{2})\s?',
    'MC': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})([0-9]{2})\s?',
    'MD': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){4}\s?',
    'ME': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?',
    'NL': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){2}([0-9]{2})\s?',
    'NO': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([0-9]{3})\s?',
    'PK': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([0-9]{4}\s?){4}\s?',
    'PS': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([0-9]{4}\s?){5}([0-9]{1})\s?',
    'PL': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){6}\s?',
    'PT': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}([0-9]{1})\s?',
    'QA': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([a-zA-Z0-9]{4}\s?){5}([a-zA-Z0-9]{1})\s?',
    'RO': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([a-zA-Z0-9]{4}\s?){4}\s?',
    'SM': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{1})([0-9]{3}\s?)([0-9]{4}\s?){1}([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{3})\s?',
    'SA': '[a-zA-Z0-9]{2}\s?([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){4}\s?',
    'RS': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?',
    'SK': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}\s?',
    'SI': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){3}([0-9]{3})\s?',
    'ES': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}\s?',
    'SE': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}\s?',
    'CH': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})\s?',
    'TN': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}\s?',
    'TR': '[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?',
    'AE': '[a-zA-Z0-9]{2}\s?([0-9]{3})([0-9]{1}\s?)([0-9]{4}\s?){3}([0-9]{3})\s?',
    'GB': '[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){3}([0-9]{2})\s?',
    'VA': '[a-zA-Z0-9]{2}\s?([0-9]{3})([0-9]{1}\s?)([0-9]{4}\s?){3}([0-9]{2})\s?',
    'VG': '[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([0-9]{4}\s?){4}\s?',
};

export const questions = [
    {
        "title": "Prise de contact",
        "subTitle": "Comment organiser un événement avec un artiste.",
        "description": "Sur la page d'accueil, vous trouverez les fiches kantoBiz des artistes.\n" +
            "Lorsque vous cliquez sur une fiche, vous êtes redirigé vers la fiche prestation (Fiche KantoBiz) en\n" +
            "question. Sur cette page, avant tout, ne jamais oublier de renseigner la date et\n" +
            "l'heure de votre événement, vous cliquez sur \"Pendre contact\". En faisant la prise de contact " +
            "vous serrez amener à remplir tout les informations sur la réservation, cette reservation sera " +
            "présenter a l'artiste avec la prise de contact.",
    },
    {
        "title": "Après la prise de contact",
        "subTitle": "Le rôle de chaque partie",
        "description": "La réponse de l'artiste est sous 48h. Si l'artiste décline, le remboursement de" +
            " l'auditeur est immédiat. Dans le cas contraire, une discussion sur l'événement sera ouverte." +
            " Cette discussion servira d'outils pour s'échanger des deux côtés jusqu'au jour de l'événement." +
            " Dépasser la date de l'événement, la discussion sera fermer. Toutes action important est envoyée par" +
            " email dès deux côtés ainsi que les notifications sur le site et l'application."
    },
    {
        "title": "Discussion",
        "subTitle": "Explication sur le Chat",
        "description": "Le Chat servira d'intermédiaire sur toutes les échanges entre un artiste et un" +
            " auditeur. Le Bot derrière Chat s'appelle PaP Izuka, Il vous répondra à tout les questions" +
            " en rapport avec l'événement en discussion. Des boutons sont mis en place pour tout autres action.",
    },
    {
        "title": "Remboursement",
        "subTitle": "Politique de remboursement",
        "description": "Il y a deux types de remboursement, FLEXIBLE et STRICT." +
            " Les politique s'applique qu'après accord de l'artiste sur une réservation." +
            "Si la Fiche Préstation (Fiche KantoBiz) de l'artiste \n" +
            "est sur FLEXIBLE les schemas sont les suivant: Si l’auditeur Pro annule jusqu à 7 jours\n" +
            "avant le début de la représentation, il est remboursé à 100% Si l’utilisateur annule\n" +
            "moins de 7 jours avant la représentation, il est remboursé à 50%. Si la Fiche Préstation (Fiche KantoBiz) est\n" +
            "sur STRICT les schemas sont les suivant: Si l’utilisateur annule jusqu’à 7 jours avant\n" +
            "le début de la représentation, il est remboursé à 100%. Si l’utilisateur\n" +
            "annule moins de 7 jours avant la représentation, il n’y a aucun de remboursement.",
    },
    {
        "title": "Création",
        "subTitle": "Comment créer une prestation ?",
        "description": "Si vous êtes un auditeur, vous pouvez commencer par\n" +
            "devenir un artiste. Si vous êtes déjà un artiste, vous devez vous rendre sur\n" +
            "votre profil. Dans la rubriques \"Mes Fiches Prestations\", vous pouvez créer une\n" +
            "nouvelle fiche prestation (Fiche KantoBiz) en cliquant sur le bouton \"Créer une prestation\".",
    },
    {
        "title": "Devenir artiste sur KantoBiz",
        "subTitle": "",
        "description": "Si vous n'êtes pas encore inscrit ou connecter, vous pouvez cliquer sur \"S'inscrire\"" +
            " en haut de la page. Ensuite cliquer sur le bouton a droite du texte \"Créer mon compte KantoBiz\"." +
            " Si vous êtes déjà un inscrit ou connecter en tant qu'auditeur Pro, Allez dans votre profil et cliquer" +
            " sur paramètres, ensuite appuyez sur \"Devenir artiste KantoBiz\".",
    }
]
