import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addReservationAddress, addReservationCity, addReservationPostalCode} from "../../../reducer";
import {changeFields, IMultiSelectTools, ITextField, searchCity} from "../../../tools/Tools";
import {Col, FormGroup, Row} from "reactstrap";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

function AddLocation() {

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const [listOfCity, setListOfCity] = useState([]);
    const auditor = useSelector(state => state.axios.auditor);
    const [countryId, setCountryId] = useState(null);
    const {address, postalCode, city} = useSelector(state => state.contactForm);
    const countries = useSelector(state => state.global.countries);
    const {service} = useSelector(state => state.toContact);
    const {country, cities} = service

    const checkCountryId = () => {
        countries?.forEach((c) => {
            if (c.name.toLowerCase() === country.toLowerCase())
                setCountryId(c.id)
        })
    }

    useEffect(() => {
        !isMounted.current && checkCountryId()

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Row>
            <Col md="7">
                <FormGroup>
                    {ITextField(
                        "Adresse",
                        null,
                        address,
                        (e) => changeFields(null, e, addReservationAddress, dispatch)
                    )}
                </FormGroup>
            </Col>
            <Col md="5">
                <FormGroup>
                    {ITextField(
                        'Code Postale',
                        null,
                        postalCode,
                        (e) => changeFields(null, e, addReservationPostalCode, dispatch),
                        null, false
                    )}
                </FormGroup>
            </Col>
            <Col md="12">
                <FormGroup>
                    {cities[0] === 'all' ?
                        IMultiSelectTools(
                            "Chercher une ville",
                            city,
                            searchCity,
                            listOfCity,
                            (e, value) => changeFields(null, value, addReservationCity, dispatch),
                            null,
                            null,
                            auditor,
                            countryId,
                            setListOfCity,
                            true)
                        :
                        <FormControl size="small" variant="filled" fullWidth>
                            <InputLabel id="demo-simple-select-filled-label">Choisir une ville</InputLabel>
                            <Select id="demo-simple-select-filled"
                                    value={city}
                                    onChange={(e) => changeFields(null, e, addReservationCity, dispatch)}
                            >
                                {cities.map((val, index) =>
                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                )}
                            </Select>
                        </FormControl>}
                </FormGroup>
            </Col>
        </Row>
    )
}

export default AddLocation;
