const initState = {
    city: "",
    event_date: new Date(new Date().getTime() + 86400000),
    thematics: [],
    results: [],
    event: "",
};

const SearchBarData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_DATE_TO_SEARCH":
            return {
                ...state,
                event_date: action.data
            };
        case "ADD_CITY_TO_SEARCH":
            return {
                ...state,
                city: action.data
            };
        case "ADD_THEMATICS_TO_SEARCH":
            return {
                ...state,
                thematics: action.data
            };
        case "ADD_EVENTS_TO_SEARCH":
            return {
                ...state,
                event: action.data
            };
        case "ADD_SEARCH_RESULTS":
            return {
                ...state,
                results: action.data
            };
        default:
            return state;
    }
};

export default SearchBarData;
