import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import {IconButton, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {addServiceFormRefundPolicy} from "../../reducer";
import {changeFields, HtmlTooltip} from "../../tools/Tools";

function RefundPolicy() {

    const dispatch = useDispatch();
    const {refund_policy} = useSelector(state => state.serviceForm);
    const conditions = useSelector(state => state.profile.conditions);

    return (
        <div className="row justify-content-center m-2">

            <div className="col-10 justify-content-center row">
                <em>
                    • C'est quoi un politique de remboursement ?&nbsp;
                    <IconButton sx={{p: '2px'}} aria-label="menu" small>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <React.Fragment>
                                    <em>{"• Il s’agit d’une politique pour gérer les remboursements, il existe deux types, Flexible et Stricte."}</em>
                                </React.Fragment>
                            }
                        >
                            <StickyNote2Icon sx={{marginBottom: 1}}/>
                        </HtmlTooltip>
                    </IconButton>
                </em>
                <em>
                    • Savoir comment ça marche ?&nbsp;
                    <IconButton sx={{p: '2px'}} aria-label="menu" small>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <React.Fragment>
                                    <em>
                                        <strong>• Flexible:</strong>
                                        {" Si l’utilisateur annule jusqu à 7 jours avant le début de la représentation, il est remboursé à 100%." +
                                            " Si l’utilisateur annule moins de 7 jours avant la représentation, il est remboursé à 50%"}
                                    </em>
                                    <br/>
                                    <br/>
                                    <em>
                                        <strong>• Stricte:</strong>
                                        {" Si l’utilisateur annule jusqu’à 7 jours avant le début de la représentation, il est remboursé à 50%." +
                                            " Si l’utilisateur annule moins de 7 jours avant la représentation, il n’y a aucun de remboursement"}
                                    </em>
                                </React.Fragment>
                            }
                        >
                            <StickyNote2Icon sx={{marginBottom: 1}}/>
                        </HtmlTooltip>
                    </IconButton>
                </em>
            </div>

            <div className="col-10 justify-content-center row m-2 pt-2">
                <TextField
                    select
                    id="filled-select-currency"
                    label="Politique de remboursement"
                    value={refund_policy}
                    onChange={(e) => changeFields(null, e, addServiceFormRefundPolicy, dispatch)}
                    variant="filled"
                >
                    {[{'label': 'Flexible', 'value': 'flexible'}, {
                        'label': 'Stricte',
                        'value': 'strict'
                    }].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <div className="col-10 justify-content-center row mt-4">
                <small>
                    • Votre politique actuel est <strong>{conditions.refund_policy}</strong>
                </small>
            </div>

        </div>
    );
}

export default RefundPolicy;
