import Skeleton from "@mui/material/Skeleton";
import React from "react";
import {Row} from "reactstrap";
import {useWindowSize} from "../../tools/Tools";

function SkeletonNavContactCardList() {

    const width = useWindowSize();

    return (
        <div className="row justify-content-center width-full mb-2 pb-1">
            <Row className="justify-content-between m-2">
                <Skeleton
                    sx={{borderRadius: '5px'}}
                    animation="wave"
                    variant="rectangular"
                    width={width < 600 ? 50 : 150}
                    height={57}
                    className="mr-1"
                />
                <Skeleton
                    sx={{borderRadius: '5px'}}
                    animation="wave"
                    variant="rectangular"
                    width={width < 600 ? 50 : 150}
                    height={57}
                    className="mr-1"
                />
                <Skeleton
                    sx={{borderRadius: '5px'}}
                    animation="wave"
                    variant="rectangular"
                    width={width < 600 ? 50 : 150}
                    height={57}
                    className="mr-1"
                />
            </Row>
        </div>
    );
}

export default SkeletonNavContactCardList;
