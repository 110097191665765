import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import UndoIcon from '@mui/icons-material/Undo';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import React, {useState} from "react";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import toast from "react-hot-toast";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {useDispatch, useSelector} from "react-redux";
import {ImpulseSpinner} from "react-spinners-kit";
import {CardFooter, Row} from "reactstrap";
import {setContactToChat} from "../../reducer";
import {checkErrorMessage, upCardEvents} from "../../tools/Tools";
import PaymentsIcon from '@mui/icons-material/Payments';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import {useGithubBtnStyles} from '@mui-treasury/styles/button/github';

function AllowEvent({setLoading, setToPay, initMessage, setAllowEvent}) {

    const dispatch = useDispatch();
    const styles = useGithubBtnStyles();
    const [process, setProcess] = useState(false);
    const userId = useSelector(state => state.profile.userId);
    const auditor = useSelector(state => state.axios.auditor);
    const contacts = useSelector(state => state.profile.contacts);
    const contactToChat = useSelector(state => state.global.contactToChat);
    const contactCardToEvents = useSelector(state => state.global.contactCardToEvents);
    let isArtistConnected = parseInt(contactToChat?.reservation?.artist_owner_id) === parseInt(userId)

    const artistAccept = async () => {
        setProcess(true)
        setLoading(true)
        await auditor.put("reservation/artist_accept/" + contactToChat?.reservation.id).then(async (resp) => {
            await upCardEvents(resp.data, contacts, contactCardToEvents, dispatch, 'auditor')
            await initMessage('auditor')
            dispatch(setContactToChat(null))
            setLoading(false)
            setProcess(false)
            setAllowEvent(false)
            toast("Vous avez accepter la proposition." +
                "\nL'événement sera ajouter a votre agenda dès que l'auditeur à valider l'étape paiement.", {
                icon: <ThumbUpIcon sx={{color: "lightGreen!important"}}/>,
            });
        }).catch((error) => {
            setProcess(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    const dispatchFunction = () => {
        if (isArtistConnected) {
            artistAccept().then(r => null)
        } else {
            setAllowEvent(false)
            setToPay(true)
        }
    }

    const cardFooter = () => {
        return (
            <CardFooter className="mt-4">
                <Row className="justify-content-end">
                    <Button
                        size="small"
                        classes={styles}
                        hidden={process}
                        variant="outlined"
                        color={'primary'}
                        aria-label="delete"
                        onClick={() => setAllowEvent(false)}>
                        <UndoIcon fontSize="inherit"/>
                    </Button>
                    <Button
                        size="small"
                        classes={styles}
                        hidden={process}
                        variant="outlined"
                        color={'primary'}
                        className="ml-3"
                        endIcon={
                            !isArtistConnected
                                ? <PaymentsIcon sx={{color: "lightGreen!important"}}/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                        }
                        onClick={() => dispatchFunction()}>
                        {isArtistConnected ? "Accepter l'événement" : "Valider l'acompte"}
                    </Button>
                    <div style={{margin: 12, paddingTop: 3}} hidden={!process}>
                        <ImpulseSpinner size={50} frontColor="#f5f5f5"/>
                    </div>
                </Row>
            </CardFooter>
        )
    }

    return (
        <CardContent>
            <TextInfoContent
                useStyles={useBlogTextInfoContentStyles}
                overline={isArtistConnected ? "J'accepte la proposition" : "Fixer cet événement"}
                heading={isArtistConnected ? 'Pour la suite ?' : 'Dernières étapes'}
                body={
                    isArtistConnected
                        ? <div>
                                        <span className="text-muted">
                                            • L'événement sera ajouté à votre agenda dès que l'acompte sera validé par l'auditeur.
                                        </span>
                            {cardFooter()}
                        </div>
                        :
                        <div>
                                        <span className="text-muted">
                                            • Vous serez rédirigez vers l'étape de payment.
                                        </span>
                            <br/>
                            <span className="text-muted">
                                            • Cela fixera l'événement dans l'agenda de l'artiste.
                                        </span>
                            <br/>
                            <span className="text-muted">
                                            • L'artiste ne sera rémunéré qu'à la fin de sa prestation.
                                        </span>
                            <br/>
                            <span className="text-muted">
                                            • Le chat restera actif jusqu'à la conclusion de l'événement.
                                        </span>
                            <br/>
                            <span className="text-muted">
                                            • Utilisez le chat pour plus d'infos.
                                        </span>
                            {cardFooter()}
                        </div>
                }
            />
        </CardContent>
    )

}

export default AllowEvent;
