import NorthIcon from "@mui/icons-material/North";
import SendIcon from "@mui/icons-material/Send";
import SouthIcon from "@mui/icons-material/South";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "reactstrap";
import {switchContactFormProcess} from "../../../reducer";
import {validationNameAndLastName} from "../../../tools/Tools";
import AddEvent from "./AddEvent";
import AddLocation from "./AddLocation";
import AddSuggestEventAmount from "./AddSuggestEventAmount";
import CustomContactStepper from "./CustomContactStepper";

function StepWithOutPayments({isValid, sendContactCard}) {

    const dispatch = useDispatch();
    const contactForm = useSelector(state => state.contactForm);
    const auth = useSelector(state => state.session.authenticated);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const {service, currencySymbol, process} = useSelector(state => state.toContact);

    const validation = () => {
        if (!auth) {
            document.getElementById("login_button").click()
        } else {
            let tmp = parseFloat(contactForm.amountProposed) >= parseFloat(service.price)
            if (!tmp)
                toast.error(
                    "Le montant que vous proposée est inferieur à " + service.price.toString() + currencySymbol + '.'
                )
            else {
                dispatch(switchContactFormProcess(true))
                sendContactCard(null, null);
            }
        }
    }

    const sendBtn = () => {
        return (
            <button
                onClick={() => validationNameAndLastName(isValid, setLoading, validation)}
                disabled={process || loading}
                className="mr-1 mb-1 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                {process || loading
                    ? 'Veuillez patienter ...'
                    : 'Contacter ' + service.artist_name}&nbsp;
                {process || loading
                    ? <Spinner type="grow" size="sm"/>
                    : <SendIcon
                        fontSize="small"
                        sx={{color: "white!important", paddingBottom: 0.3}}/>}
            </button>
        )
    }

    const handleBack = async () => {
        await setLoading(true)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        await setLoading(false)
    };

    const backBtn = () => {
        return (
            <button
                onClick={() => handleBack()}
                disabled={process || loading}
                className="pl-2 pr-2 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                <NorthIcon
                    fontSize="small"
                    sx={{color: "white!important", paddingBottom: 0.3}}/>
            </button>
        )
    }

    const handleNext = async () => {
        await setLoading(true)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        await setLoading(false)
    };

    const nextBtn = (toNext, disabled) => {
        return (
            <button onClick={() => toNext()}
                    disabled={disabled}
                    className="mt-1 mr-1 mb-1 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                Continuer&nbsp;
                {loading
                    ? <Spinner type="grow" size="sm"/>
                    : <SouthIcon fontSize="small" sx={{color: "white!important", paddingBottom: 0.3}}/>}
            </button>
        )
    }

    const steps = [
        {
            label: "Type d'événement",
            description: "Quel événement préparez-vous ?",
            input: <AddEvent />,
            button: nextBtn(handleNext, !isValid.event),
        },
        {
            label: "Emplacement",
            description: "Où aura lieu cet événement ?",
            input: <AddLocation />,
            button: nextBtn(handleNext, !(isValid.city && isValid.address && isValid.postalCode)),
        },
        {
            label: "Estimation",
            description: "Proposition à partir de " + service.price.toString() + ' ' + currencySymbol,
            input: <AddSuggestEventAmount />,
        }
    ];

    return (
        <CustomContactStepper
            steps={steps}
            isValid={isValid}
            backBtn={backBtn}
            sendBtn={sendBtn}
            validation={validation}
            handleBack={handleBack}
            activeStep={activeStep}/>
    )
}

export default StepWithOutPayments;
