import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import React, {useRef, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import settings from "../../config/tsconfig.json";
import {
    addContactFormEventProposedAmount,
    addContactFormToSend,
    addProfileDataContacts,
    switchContactFormProcess
} from "../../reducer";
import {
    checkContactForm,
    checkErrorMessage,
    checkStripePromise,
    cleanReservationFormData,
    fillContactFormData
} from "../../tools/Tools";
import {payment_appearance} from "../../tools/Variables";
import SkeletonContact from "../skeletonCustom/SkeletonContact";
import ContactReview from "./ContactReview";
import StepWithOutPayments from "./Steps/stepWithOutPayments";
import StepWithPayments from "./Steps/StepWithPayments";

function ContactStepper({isValid, lastContactForm, paymentIntent, redirectStatus, paymentIntentSecret}) {

    const dispatch = useDispatch();
    const isPaid = lastContactForm && redirectStatus === 'succeeded'
    const isFail = lastContactForm && redirectStatus === 'failed'
    const isMounted = useRef(false);
    const {service, send, currencySymbol, currency} = useSelector(state => state.toContact);
    const auditor = useSelector(state => state.axios.auditor);
    const isDev = useSelector(state => state.global.isDev);
    const {email} = useSelector(state => state.profile.profileData)
    const contactForm = useSelector(state => state.contactForm);
    const contacts = useSelector(state => state.profile.contacts);
    const [clientSecret, setClientSecret] = useState('');
    const [review, setReview] = useState(null);
    const [stripePromise, setStripPromise] = useState();
    const [loading, setLoading] = useState(true);

    const reFilStripeInfo = () => {
        setClientSecret(paymentIntentSecret)
        if (isDev) {
            setStripPromise(loadStripe(settings.configs.StripeKeyDev));
        } else {
            setStripPromise(loadStripe(settings.configs.StripeKeyProd));
        }
    }

    const toSend = async (contactCard) => {
        await auditor.post("contact_cards/new", contactCard).then(async (resp) => {
            dispatch(addContactFormToSend(true))
            dispatch(switchContactFormProcess(false))
            setReview(ContactReview(contactCard, service?.country, currencySymbol))
            await cleanReservationFormData(dispatch);
            let contactsTmp = {...contacts}
            contactsTmp['book']['pending'].push(resp.data)
            dispatch(addProfileDataContacts(contactsTmp))
            localStorage.removeItem('contactForm');
            toast.success("Votre prise de contact a été envoyée. Accéder a votre tableau de bord pour la gestion, voir votre émail pour plus de détails.");
        }).catch((error) => {
            dispatch(switchContactFormProcess(false))
            let errorMessage = checkErrorMessage(error);
            paymentIntentSecret && reFilStripeInfo()
            toast.error(errorMessage.message)
        });
    }

    const sendContactCard = (token, reference) => {
        let tDate = new Date(
            contactForm.eventDate.getTime() - contactForm.eventDate.getTimezoneOffset() * 60000,
        )
            .toISOString()
            .slice(0, -1);
        let contactCard = checkContactForm(contactForm, tDate, service, contactForm.amountProposed, email);
        if (service.contact_amount) {
            if (reference) {
                contactCard["stripe_token"] = {"payment_url": token, "reference": reference}
            } else {
                contactCard["stripe_token"] = token
            }
        }
        toSend(contactCard).then(r => null);
    }

    const checkPaymentPassed = () => {
        let _contactForm = {...lastContactForm};

        fillContactFormData(dispatch, _contactForm).then(r => {
            dispatch(addContactFormEventProposedAmount(_contactForm.reservation.total_amount))

            if (isPaid) {
                _contactForm['stripe_token'] = {'id': paymentIntent}
                setReview(ContactReview(_contactForm, service?.country, currencySymbol))
                toSend(_contactForm, true).then(async r => {
                    setLoading(false);
                })
            } else {
                paymentIntentSecret && reFilStripeInfo()
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        let amount = service?.contact_amount;
        !isMounted.current &&  dispatch(addContactFormEventProposedAmount(service.price))
        if (amount && currency && !isMounted.current) {

            if (isPaid || isFail) {
                checkPaymentPassed()
            } else {
                checkStripePromise(isDev, setStripPromise, auditor, amount, currency, setClientSecret, setLoading)
            }

        } else {
            setLoading(false)
        }

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auditor, currency, service.price, service?.contact_amount, isPaid]);

    return (
        <div>
            {loading
                ? <SkeletonContact/>
                : send
                    ? review
                    : service?.contact_amount
                        ? <Elements stripe={stripePromise} options={{clientSecret, payment_appearance}}>
                            <StepWithPayments isValid={isValid} sendContactCard={sendContactCard}/>
                        </Elements>
                        : <StepWithOutPayments isValid={isValid} sendContactCard={sendContactCard}/>
            }

        </div>
    );
}

export default ContactStepper;
