import axios from "axios";
import Conf from "../../config/tsconfig.json";

const serverApi = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? Conf.configs.ServerApiDev
    : Conf.configs.ServerApi

const initState = {
    auditor: axios.create({
        baseURL: serverApi,
        headers: {
            'Language': "fr",
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Isl-Token': Conf.configs.TokenVisitor
        }
    }),
    auditorForm: axios.create({
        baseURL: serverApi,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': "*",
            'Language': "fr",
            'Isl-Token': ""
        }
    })
};

const AxiosUSerConfig = (state = initState, action) => {
    switch (action.type) {
        case "ADD_TOKEN_IP_AXIOS_AUDITOR":

            let {ipAddress, token} = action.data

            return {
                ...state,
                auditor: axios.create({
                    baseURL: serverApi,
                    headers: {
                        'ipAddress': ipAddress,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': "*",
                        'Language': "fr",
                        'Isl-Token': token
                    }
                }),
                auditorForm: axios.create({
                    baseURL: serverApi,
                    headers: {
                        'ipAddress': ipAddress,
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': "*",
                        'Language': "fr",
                        'Isl-Token': token
                    }
                }),
            };

        default:
            return state;
    }
};

export default AxiosUSerConfig;
