import Divider from "@material-ui/core/Divider";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {addReservationsTabIndex} from "../../reducer";
import {useWindowSize} from "../../tools/Tools";
import {NavItem, NavMenu} from '@mui-treasury/components/menu/navigation';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


function DashboardHeader(props) {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const {checkData, bookedList, setToLoad} = props
    const link = useSelector(state => bookedList ? 'book' : 'auditor');
    const {contactsLength} = useSelector(state => state.profile);
    const UnRead = useSelector(state => state.profile.notificationUnRead);
    const tabIndex = useSelector(state => state.contactCards.tabIndex);

    return (
        <div className="row justify-content-center width-full mb-2 pb-1">
            <NavMenu className="m-2 border radius-1">
                <NavItem
                    active={tabIndex === 0}
                    onClick={async () => {
                        await setToLoad(true)
                        await dispatch(addReservationsTabIndex(0))
                        checkData(0).then(r => setToLoad(false))
                    }}>
                    <QueryBuilderIcon sx={{color: 'silver!important', marginRight: 1}} fontSize="small"/>
                    <span hidden={width < 600}>En attente</span>
                    <span className="m-2 text-muted">
                    {contactsLength[link]?.pending ? contactsLength[link]?.pending : 0}
                </span>
                </NavItem>
                <NavItem
                    active={tabIndex === 1}
                    onClick={async () => {
                        await setToLoad(true)
                        await dispatch(addReservationsTabIndex(1))
                        checkData(1).then(r => setToLoad(false))
                    }}>
                    <TaskAltIcon sx={{color: 'lightGreen!important', marginRight: 1}} fontSize="small"/>
                    <span hidden={width < 600}>Acceptée</span>
                    {UnRead?.filter((value) => !value.read && value.subject === "accepted" && value.type === 'contact_card_notify').length
                        ? <span className={'MuiTabItem-tag bg-news pl-1 pr-1 rounded ml-1'}>
                        {UnRead?.filter((value) => !value.read && value.subject === "accepted" && value.type === 'contact_card_notify').length}
                    </span>
                        : <span className="m-2 text-muted">
                        {contactsLength[link]?.accepted ? contactsLength[link]?.accepted : 0}
                    </span>}
                </NavItem>
                <NavItem
                    active={tabIndex === 2}
                    onClick={async () => {
                        await setToLoad(true)
                        await dispatch(addReservationsTabIndex(2))
                        checkData(2).then(r => setToLoad(false))
                    }}>
                    <HighlightOffIcon sx={{marginRight: 1}} fontSize="small"/>
                    <span hidden={width < 600}>Declinée</span>
                    {UnRead?.filter((value) => !value.read && value.subject === "declined" && value.type === 'contact_card_notify').length
                        ? <span className={'MuiTabItem-tag bg-news pl-1 pr-1 rounded ml-1'}>
                        {UnRead?.filter((value) => !value.read && value.subject === "declined" && value.type === 'contact_card_notify').length}
                    </span>
                        : <span className="m-2 text-muted">
                        {contactsLength[link]?.declined ? contactsLength[link]?.declined : 0}
                    </span>}
                </NavItem>
                <Divider sx={{width: 80}} orientation="horizontal"/>
            </NavMenu>
        </div>
    )
}

export default DashboardHeader;
