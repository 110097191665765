import React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import AddPhone from "../../profile/Edits/AddPhone";

function CustomContactStepper({
                                  steps,
                                  handleBack,
                                  activeStep,
                                  sendBtn,
                                  backBtn,
                                  validation,
                                  isValid
                              }) {

    const {phone} = useSelector(state => state.profile.profileData)
    const {send, tabIndex} = useSelector(state => state.toContact);

    return (
        <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel
                        optional={index === steps.length - 1 ? (
                            <Typography variant="caption">Dernière étape</Typography>) : null}
                    >
                        {step.label}
                    </StepLabel>
                    <StepContent>
                        <Typography>{step.description}</Typography>
                        <br/>
                        <Box sx={{mb: 2}}>
                            <div>
                                {step.input && step.input}
                                {step.button && step.button}
                                {index === steps.length - 1 && tabIndex !== 0 && phone ? sendBtn() : null}
                                {index === steps.length - 1 && tabIndex === 0
                                    ? sendBtn()
                                    : index === steps.length - 1 && tabIndex !== 0 && !phone
                                        ? <div className="mb-2 justify-content-center">
                                            <AddPhone
                                                isValid={isValid}
                                                validation={validation}
                                                handleBack={handleBack}/>
                                        </div>
                                        : index > 0 ? backBtn() : null}
                                {index === steps.length - 1 && tabIndex === 0 && !send ? backBtn() : null}
                            </div>
                        </Box>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    )
}

export default CustomContactStepper;
