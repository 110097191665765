import React, {useEffect, useRef, useState} from "react";
import {Analytics} from '@vercel/analytics/react';
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Footer from "components/Footer/Footer.js";
import NavbarCustom from "../components/Navbars/NavbarCustom.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import PerfectScrollbar from "perfect-scrollbar";
import {Toaster} from "react-hot-toast";
import {useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";
import {routes} from "routes";
import {useWindowSize} from "../tools/Tools";

let ps;

function Dashboard(props) {

    const width = useWindowSize();
    const [routesSwitch, setRoutesSwitch] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState("light");
    const [activeColor, setActiveColor] = useState("primary")
    const auth = useSelector(state => state.session.authenticated);
    const role = useSelector(state => state.profile.role);
    const mainPanel = useRef();

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }

        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    });

    const handleActiveClick = (color) => {
        setActiveColor(color);
    };

    const handleBgClick = (color) => {
        setBackgroundColor(color);
    };

    const dispatchRoutes = (data) => {
        return data.map((prop, index) => {
            if (prop.component?.length) {
                return dispatchRoutes(prop.component)
            }
            return (<Route path={prop.path} element={prop.component} key={'route_' + index}/>);
        })
    }

    const chargeRouteSwitch = () => {
        let t = dispatchRoutes(routes(role))
        setRoutesSwitch(t)
    }

    useEffect(() => {
        chargeRouteSwitch()
        setActiveColor("primary");
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div className="wrapper">
            <Analytics/>

            <Toaster
                reverseOrder={false}
                containerStyle={{maxWidth: width < 600 ? 200 : 240}}
                position={width < 600 ? "top-center" : "bottom-left"}
                toastOptions={{
                    duration: width < 600 ? 4000 : 8000,
                    style: {
                        border: '1px solid #713200',
                        padding: '16px',
                        color: '#713200',
                    },
                    iconTheme: {
                        primary: '#714600',
                        secondary: '#FFFAEE',
                    }}}
            />

            <Sidebar
                {...props}
                routes={routes(role)}
                bgColor={backgroundColor}
                activeColor={activeColor}
            />

            <div className="main-panel" ref={mainPanel}>
                <NavbarCustom {...props} />

                <Routes>
                    {routesSwitch}
                </Routes>

                <Footer fluid/>
            </div>

            {auth &&
                <FixedPlugin
                    bgColor={backgroundColor}
                    activeColor={activeColor}
                    handleActiveClick={handleActiveClick}
                    handleBgClick={handleBgClick}
                />}
        </div>
    );
}

export default Dashboard;
