const initState = {
    paid: [],
    refunded: [],
    pending: [],
};

const PaymentsData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_PAYMENT_PAID":
            return {
                ...state,
                paid: action.data
            };
        case "ADD_PAYMENT_REFUNDED":
            return {
                ...state,
                refunded: action.data
            };
        case "ADD_PAYMENT_PENDING":
            return {
                ...state,
                pending: action.data
            };
        default:
            return state;
    }
};

export default PaymentsData;
