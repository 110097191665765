import {Row} from "@mui-treasury/components/flex";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CloseIcon from "@mui/icons-material/Close";
import LocalPlayIcon from "@mui/icons-material/LocalPlay";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import {IconButton} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from "@mui/material/Tooltip";
import React, {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardBody, CardFooter, CardHeader} from "reactstrap";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useN03TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n03';
import {addCityToSearch, addEventToSearch, addThematicsToSearch} from "../../../reducer";
import {btnStyle, changeFields, IMultiSelectTools, ISelectSearch, searchCity} from "../../../tools/Tools";

function ServiceSearch({thematicsList, closeFilter, searchServices, loading}) {

    const dispatch = useDispatch();
    const [listOfCity, setListOfCity] = useState([]);
    const auditor = useSelector(state => state.axios.auditor);
    const {eventTypes, countryLogID} = useSelector(state => state.global);
    const searchBarData = useSelector(state => state.searchBarData);

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;
        if (keyCode === 27 && key === 'Escape'){
            closeFilter();
        } else if (keyCode === 13 && key === 'Enter'){
            searchServices()
        }
    }, [closeFilter, searchServices]);

    const resetFilter = async () => {
        await dispatch(addEventToSearch(""))
        await dispatch(addThematicsToSearch([]))
        await dispatch(addCityToSearch(""))
        searchServices(true)
    }

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div>
            <CardHeader>
                <div className="row mt-1 justify-content-center">
                    <TextInfoContent
                        useStyles={useN03TextInfoContentStyles}
                        overline={'FICHE KANTOBIZ'}
                    />
                </div>
            </CardHeader>
            <CardBody>
                <MenuList>
                    <MenuItem>
                        <ListItemIcon className="pt-1">
                            <CelebrationIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            <form className="pb-2 ml-1 mr-1">
                                {ISelectSearch(
                                    "Genre d'événement",
                                    searchBarData.event,
                                    (e, obj) => changeFields(null, obj, addEventToSearch, dispatch),
                                    eventTypes,
                                    false,
                                    null,
                                    'standard')}
                            </form>
                        </ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon className="pt-1">
                            <LocalPlayIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            <form className="pb-2 ml-1 mr-1">
                                {ISelectSearch(
                                    "Type(s) d'artiste",
                                    searchBarData.thematics,
                                    (e, obj) => changeFields(null, obj, addThematicsToSearch, dispatch),
                                    thematicsList,
                                    true,
                                    null,
                                    'standard')}
                            </form>
                        </ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon className="pt-1">
                            <LocationOnIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            <form className="pb-2 ml-1 mr-1">
                                {IMultiSelectTools(
                                    "Localisation (Une ville)",
                                    searchBarData.city,
                                    searchCity,
                                    listOfCity,
                                    (e, value) => changeFields(null, value, addCityToSearch, dispatch),
                                    null,
                                    "standard",
                                    auditor,
                                    countryLogID,
                                    setListOfCity,
                                    true)}
                            </form>
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </CardBody>
            <CardFooter>
                <Row className="justify-content-end">
                    <Tooltip title="Fermer" placement="top">
                        <IconButton
                            size="small"
                            sx={btnStyle('gray')}
                            disabled={loading}
                            aria-label="settings"
                            onClick={() => closeFilter()}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Réinitialiser les filtres" placement="top">
                        <IconButton
                            size="small"
                            sx={btnStyle('orange')}
                            disabled={loading}
                            aria-label="settings"
                            onClick={() => resetFilter()}>
                            <RefreshIcon sx={{color: "orange!important"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Recherche" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            disabled={loading}
                            sx={btnStyle('lightGreen')}
                            onClick={() => searchServices()}>
                            Trouver&nbsp;<SearchIcon sx={{color: 'lightGreen!important'}}/>
                        </IconButton>
                    </Tooltip>
                </Row>
            </CardFooter>
        </div>
    )
}

export default ServiceSearch;
