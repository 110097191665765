import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useChatzInfoStyles} from "@mui-treasury/styles/info/chatz";
import React, {useEffect, useState} from "react";
import '../../assets/css/calendar.scss'
import {Column} from "@mui-treasury/components/flex";
import moment from 'moment';
import Tooltip from "@mui/material/Tooltip";
import 'moment/locale/fr'
import LensIcon from '@mui/icons-material/Lens';
import {useDispatch, useSelector} from "react-redux";
import {setContactToChat} from "../../reducer";
import Timer from "../../tools/Countdown/timer";
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import {useLabelIconStyles} from '@mui-treasury/styles/icon/label';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {IconButton} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

moment.locale('fr')

function Calendar() {

    const dispatch = useDispatch();
    const [showEvents, setShowEvents] = useState(false);
    const role = useSelector(state => state.profile.role);
    const [selectedMonth, setSelectedMonth] = useState(moment());
    const [selectedDay, setSelectedDay] = useState(moment().startOf("day"));
    const [selectedMonthEvents, setSelectedMonthEvents] = useState([]);
    const contactCardToEvents = useSelector(state => state.global.contactCardToEvents);
    const [time, setTime] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
    const iconLabelStyles = useLabelIconStyles({linked: true});

    const openToChat = async (contactId) => {
        let contact = contactCardToEvents?.filter((value) => value.id === contactId)[0]
        contact?.id && await dispatch(setContactToChat(contact))
        document.getElementById("open_discussion").click()
    }

    const Events = (props) => {
        const currentSelectedDay = props.selectedDay;
        const monthEvents = props.selectedMonthEvents;

        const monthEventsRendered = monthEvents.map((event, i) => {
            let [street, city, country] = event.address?.split(', ')
            return (
                <Tooltip title="Cliquer pour afficher la discussion" placement="left">
                    <IconButton
                        className="width-full border-bottom"
                        key={i + "_event_btn"}
                        onClick={() => openToChat(event.contactId)}
                        aria-label="settings">

                        <CardHeader
                            key={i + '_event'}
                            style={{width: '100%'}}
                            avatar={
                                <div>
                                    {event.date.format("HH:mm")}
                                    {event.payment_id
                                        ? <Tooltip title="Événement programmé" placement="top">
                                            <CheckCircleIcon
                                                fontSize="small"
                                                sx={{mb: 0.5, ml: 1, color: "lightGreen!important"}}/>
                                        </Tooltip>
                                        : <Tooltip
                                            title="En attente de réponse, veuillez ouvrir la discussion en bleu"
                                            placement="top">
                                            <QueryBuilderIcon
                                                fontSize="small"
                                                sx={{mb: 0.5, ml: 1, color: "gray!important"}}/>
                                        </Tooltip>}
                                </div>}
                            title={
                                <Column gap={1} className="text-right">
                                    <Info useStyles={useChatzInfoStyles}>
                                        <InfoTitle>{event.title}</InfoTitle>
                                        <InfoSubtitle>{street} - {city}, {country}</InfoSubtitle>
                                        <InfoCaption>
                                            <strong>
                                                {role === "professional_auditor" ? event.artist_name : event.auditor_name}
                                            </strong>
                                            &nbsp;-&nbsp;{event.duration}
                                        </InfoCaption>
                                    </Info>
                                </Column>
                            }/>
                    </IconButton>
                </Tooltip>)
        });

        const dayEventsRendered = []

        for (let i = 0; i < monthEventsRendered.length; i++) {
            if (monthEvents[i].date.isSame(currentSelectedDay, "day")) {
                dayEventsRendered.push(monthEventsRendered[i]);
            }
        }

        return (<div className="day-events">
            {dayEventsRendered}
        </div>);
    }

    const Week = (props) => {
        let days = [];
        let date = props.previousCurrentNextView;
        let currentMonthView = props.currentMonthView;
        let selected = props.selected;
        let select = props.select;
        let monthEvents = props.monthEvents;

        for (let i = 0; i < 7; i++) {
            let dayHasEvents = [];

            for (let j = 0; j < monthEvents.length; j++) {
                if (monthEvents[j].date.isSame(date, "day")) {
                    dayHasEvents.push(Boolean(monthEvents[j].payment_id));
                }
            }

            let day = {
                name: date.format("dd").substring(0, 1),
                number: date.date(),
                isCurrentMonth: date.month() === currentMonthView.month(),
                isToday: date.isSame(new Date(), "day"),
                date: date,
                hasEvents: !dayHasEvents.length ? null : dayHasEvents.includes(true)
            };

            days.push(<Day day={day} selected={selected} select={select}/>);
            date = date.clone();
            date.add(1, "d");
        }
        return (<div className="row week mr-1 ml-1">
            {days}
        </div>);
    }

    const Day = (props) => {
        let day = props.day;
        let selected = props.selected;
        let select = props.select;

        return (<div
            className={"day" + (day.isToday ? " today" : "") + (day.isCurrentMonth ? "" : " different-month") + (day.date.isSame(selected) ? " selected" : "") + (day.hasEvents === null ? "" : day.hasEvents ? " has-events" : " has-pending-events")}
            onClick={() => select(day)}
        >
            <div className="day-number">{day.number}</div>
        </div>);
    }

    const previous = () => {
        setSelectedMonth(moment(selectedMonth).subtract(1, 'months'))
    }

    const next = () => {
        setSelectedMonth(moment(selectedMonth).add(1, 'months'))
    }

    const select = (day) => {
        setShowEvents(true)
        setSelectedDay(day.date.clone())
        setSelectedMonth(day.date)
    }

    const goToCurrentMonthView = () => {
        setSelectedMonth(moment())
    }

    const showCalendar = () => {
        setShowEvents(false)
        setSelectedDay(selectedDay)
        setSelectedMonth(selectedMonth)
    }

    const renderDayLabel = () => {
        return (<span className="box month-label">
                {selectedDay.format("DD MMMM YYYY")}
            </span>);
    }

    const renderWeeks = () => {
        let weeks = [];
        let done = false;
        let previousCurrentNextView = selectedMonth
            .clone()
            .startOf("month")
            .subtract(1, "d")
            .day("Monday");
        let count = 0;
        let monthIndex = previousCurrentNextView.month();

        while (!done) {
            weeks.push(<Week
                previousCurrentNextView={previousCurrentNextView.clone()}
                currentMonthView={selectedMonth}
                monthEvents={selectedMonthEvents}
                selected={selectedDay}
                select={day => select(day)}
            />);
            previousCurrentNextView.add(1, "w");
            done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
            monthIndex = previousCurrentNextView.month();
        }
        return weeks;
    }

    const checkTimer = (cardEvents) => {
        const startDate = moment();
        const timeEnd = moment(cardEvents.reservation.event_date);
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);
        setTime({
            days: diffDuration.days(),
            hours: diffDuration.hours(),
            minutes: diffDuration.minutes(),
            seconds: diffDuration.seconds()
        })
    }

    useEffect(() => {
        contactCardToEvents?.length && checkTimer(contactCardToEvents[contactCardToEvents?.length - 1])
    }, [contactCardToEvents]);

    useEffect(() => {
        let _tmp = []
        for (let i = 0; i < contactCardToEvents?.length; i++) {
            _tmp.push({
                contactId: contactCardToEvents[i]['id'],
                title: contactCardToEvents[i]['reservation']['event'],
                address: contactCardToEvents[i]['reservation']['address'],
                duration: contactCardToEvents[i]['reservation']['duration'],
                artist_name: contactCardToEvents[i]['reservation']['artist_name'],
                payment_id: contactCardToEvents[i]?.reservation?.payment_history_id,
                auditor_name: contactCardToEvents[i]['reservation']['auditor_name'],
                date: moment(contactCardToEvents[i]['reservation']["event_date"])
            })
        }
        setSelectedMonthEvents(_tmp?.sort((a, b) => new Date(a.date) - new Date(b.date)))
    }, [contactCardToEvents]);

    return (
        <>
            <div className="col-md-4 mt-3">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <Column gap={1} className="text-left">
                            <Info useStyles={useChatzInfoStyles}>
                                <InfoSubtitle className="ml-2 mb-2">
                                    {contactCardToEvents?.length
                                        ? "Prochain événement dans"
                                        : "Aucun événement en cours"}
                                </InfoSubtitle>

                                <InfoTitle className={contactCardToEvents?.length ? "mb-2" : ""}>
                                    {contactCardToEvents?.length ?
                                        <Timer timerData={time} left/> : ""}
                                </InfoTitle>

                                {contactCardToEvents?.length
                                    ? <Tooltip title="Cliquer pour afficher la discussion" placement="top">
                                        <IconButton
                                            className="width-full border-bottom"
                                            key={"first_event_btn"}
                                            sx={{backgroundColor: 'background.paper'}}
                                            onClick={() => openToChat(contactCardToEvents[0]?.id)}
                                            aria-label="settings">

                                            <CardHeader
                                                key={'first_event'}
                                                style={{width: '100%'}}
                                                title={
                                                    <Column gap={1} className="text-left">
                                                        <Info useStyles={useChatzInfoStyles}>
                                                            <InfoTitle>{contactCardToEvents[0]?.reservation?.event}</InfoTitle>
                                                            <InfoSubtitle>
                                                                {contactCardToEvents[0]?.reservation?.address}
                                                            </InfoSubtitle>
                                                            <InfoCaption>
                                                                <strong>
                                                                    Le&nbsp;
                                                                    {moment(contactCardToEvents[0]?.reservation?.event_date)?.format("DD-MM-YYYY à HH:mm A")}
                                                                </strong>
                                                            </InfoCaption>
                                                        </Info>
                                                    </Column>
                                                }/>
                                        </IconButton>
                                    </Tooltip>
                                    : <hr className="ml-1"/>}

                                <InfoCaption className={"row " + contactCardToEvents?.length ? 'mt-3' : ''}>
                                    <div className="col-12 row justify-content-start cursor-pointer">
                                                <span className={iconLabelStyles.root}>
                                                    <PanoramaFishEyeIcon fontSize="small"
                                                                         className={iconLabelStyles.icon}/>
                                                    &nbsp;Aujourd'hui
                                                </span>
                                    </div>
                                    <div className="col-12 row justify-content-start cursor-pointer">
                                                <span className={iconLabelStyles.root}>
                                                    <LensIcon fontSize="small" className={iconLabelStyles.icon}
                                                              sx={{color: '#a4ce55!important'}}/>
                                                    &nbsp;Événement(s) programmé
                                                </span>
                                    </div>
                                    <div className="col-12 row justify-content-start cursor-pointer">
                                                <span className={iconLabelStyles.root}>
                                                    <LensIcon fontSize="small" className={iconLabelStyles.icon}
                                                              sx={{color: '#e6979a!important'}}/>
                                                    &nbsp;Événement(s) en attente
                                                </span>
                                    </div>
                                </InfoCaption>

                                <hr className="ml-1"/>
                            </Info>
                        </Column>
                    </div>
                </div>
            </div>
            {showEvents
                ? <section className="main-calendar width-full col-md-8">
                    <header className="calendar-header">
                        <div className="row title-header">
                            {renderDayLabel()}
                        </div>
                        <div className="row button-container">
                            <div className="box arrow fa fa-arrow-left" onClick={showCalendar}>
                                &nbsp;Revenir
                            </div>
                        </div>
                    </header>
                    <Events
                        selectedMonth={selectedMonth}
                        selectedDay={selectedDay}
                        selectedMonthEvents={selectedMonthEvents}
                    />
                </section>
                : <section className="main-calendar col-md-8">
                    <header className="calendar-header">
                        <div className="row title-header">
                            <div className="box arrow fa fa-angle-left" onClick={previous}/>
                            <div className="box header-text">
                        <span className="box p-2 today-label" onClick={goToCurrentMonthView}>
                            Aujourd'hui
                        </span>
                                <span className="box month-label">
                            {selectedMonth.format("MMMM YYYY")}
                        </span>
                            </div>
                            <div className="box arrow fa fa-angle-right" onClick={next}/>
                        </div>
                        <div className="row days-header">
                            <span className="box day-name">Dim</span>
                            <Divider sx={{height: 50, m: 0.5, marginRight: 1}} orientation="vertical"/>
                            <span className="box day-name">Lun</span>
                            <Divider sx={{height: 50, m: 0.5, marginRight: 1}} orientation="vertical"/>
                            <span className="box day-name">Mar</span>
                            <Divider sx={{height: 50, m: 0.5, marginRight: 1}} orientation="vertical"/>
                            <span className="box day-name">Mer</span>
                            <Divider sx={{height: 50, m: 0.5, marginRight: 1}} orientation="vertical"/>
                            <span className="box day-name">Jeu</span>
                            <Divider sx={{height: 50, m: 0.5, marginRight: 1}} orientation="vertical"/>
                            <span className="box day-name">Ven</span>
                            <Divider sx={{height: 50, m: 0.5, marginRight: 1}} orientation="vertical"/>
                            <span className="box day-name">Sam</span>
                        </div>
                    </header>
                    <div className="days-container">
                        {renderWeeks()}
                    </div>
                </section>}
        </>);
}

export default Calendar;
