import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {addReservationEvent} from "../../../reducer";
import {changeFields, ISelectSearch} from "../../../tools/Tools";
import {Col, FormGroup, Row} from "reactstrap";

function AddEvent() {

    const dispatch = useDispatch();
    const event = useSelector(state => state.contactForm.event);
    const {service} = useSelector(state => state.toContact);
    const {events} = service

    return (
        <Row>
            <Col md="12">
                <FormGroup>
                    {ISelectSearch(
                        "Événement proposer par l'artiste",
                        event,
                        (e, obj) => changeFields(null, obj, addReservationEvent, dispatch),
                        events,
                        false,
                        null,
                        'standard')}
                </FormGroup>
            </Col>
        </Row>
    )

}

export default AddEvent;
