import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {IconButton, InputAdornment, TextField} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import apis from "../../config/apis";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useDispatch, useSelector} from "react-redux";
import {CardFooter, CardHeader, CardTitle, Col, FormGroup, Row, Spinner} from "reactstrap";
import "../../assets/css/keyValidation.css";
import PassWordHints from "../../tools/PassWordHints";
import {btnStyle, changeFields, checkErrorMessage, createSession, ITextField} from "../../tools/Tools";
import ChangePassword from "./ChangePassword";
import LoginGoogle from "./Google";
import Validation from "./Validation";

function Login(props) {

    const {closeModal} = props
    const dispatch = useDispatch()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [forgotPass, setForgotPass] = useState(false);
    const [passVisibility, setPassVisibility] = useState(false);
    const auditor = useSelector(state => state.axios.auditor);
    const [verificationCode, setVerificationCode] = useState(false);
    // const socket = useSelector(state => state.global.socket);
    const ipAddress = useSelector(state => state.global.ipAddress);

    const login = useCallback(() => {
        setLoading(true)
        auditor.post(apis.login, {email, password}).then(async response => {
            await createSession(response.data, dispatch, ipAddress, setLoading, closeModal, true)
        }).catch(error => {
            setLoading(false);
            let response = checkErrorMessage(error).message;
            if (response === "Veuillez activer votre compte") {
                setVerificationCode(true)
            }
            toast.error(response);
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [closeModal, ipAddress, dispatch, auditor, email, password])

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;

        if ((forgotPass || verificationCode) && keyCode === 13 && key === 'Enter') {
            event.preventDefault()
        } else if (keyCode === 27 && key === 'Escape' && !forgotPass && !verificationCode) {
            closeModal()
        } else if (keyCode === 13 && key === 'Enter' && !forgotPass && !verificationCode) {
            login()
        }
    }, [closeModal, login, verificationCode, forgotPass]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className="justify-content-center">
            <Modal size="md" show={forgotPass}>
                <ChangePassword closeModal={() => setForgotPass(false)}/>
            </Modal>

            <CardHeader className="m-auto text-center pt-3">
                <CardTitle tag="h5">Se connecter</CardTitle>
            </CardHeader>

            <div className="p-2 mb-3">
                {!verificationCode ?
                    <div className="mt-4">
                        <Row className="justify-content-center">
                            <Col md="10">
                                <FormGroup>
                                    {ITextField('Email', null, email, (e) => changeFields(setEmail, e))}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={10}>
                                <FormGroup>

                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        size="small"
                                        value={password}
                                        variant="filled"
                                        placeholder='Mot de passe'
                                        onChange={(e) => changeFields(setPassword, e)}
                                        type={passVisibility ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip title={PassWordHints()} placement="top">
                                                        <IconButton size="small">
                                                            <InfoIcon fontSize="small" sx={{color: "gray!important"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={passVisibility ? "Masquer le mot de passe" : "Afficher le mot de passe"}
                                                        placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setPassVisibility(!passVisibility)}>
                                                            {passVisibility
                                                                ? <VisibilityIcon fontSize="small"/>
                                                                : <VisibilityOffIcon fontSize="small" sx={{color: "gray!important"}}/>}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <div className="material-switch text-center mt-2">
                                <small
                                    onClick={() => setForgotPass(true)}
                                    className="text-monospace border-bottom cursor-pointer text-muted">
                                    &nbsp;Mot de passe oublié ?
                                </small>
                            </div>
                        </Row>
                    </div> :
                    <Validation email={email} closeModal={() => setVerificationCode(false)} forgotValidate/>}
            </div>

            <CardFooter>
                <Row className="justify-content-end">
                    <Tooltip title="Fermer cette onglet" placement="top">
                        <IconButton
                            size="small"
                            disabled={loading || verificationCode}
                            aria-label="settings"
                            sx={btnStyle('gray')}
                            onClick={() => closeModal()}>
                            <CloseIcon sx={{color: "gray!important"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Se connecter" placement="top">
                        <IconButton
                            disabled={loading || verificationCode}
                            aria-label="settings"
                            size="small"
                            sx={btnStyle('lightGreen')}
                            onClick={() => login()}>
                            Connexion&nbsp;
                            {loading
                                ? <Spinner type="grow" size="sm"/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Se connecter avec Google" placement="top">
                        <IconButton disabled={loading || verificationCode} aria-label="settings">
                            <LoginGoogle LogIn callBack={(data) => createSession(data, dispatch, ipAddress, setLoading, closeModal, true)} _setLoad={setLoading}/>
                        </IconButton>
                    </Tooltip>
                </Row>
            </CardFooter>
        </div>
    );
}

export default Login;
