import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {useGmailTabItemStyles, useGmailTabsStyles} from "@mui-treasury/styles/tabs";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ForumIcon from "@mui/icons-material/Forum";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {CardFooter, CardHeader, CardTitle, Row, Spinner} from "reactstrap";
import CelebrationIcon from '@mui/icons-material/Celebration';
import InfoIcon from '@mui/icons-material/Info';
import {setContactToChat, setMsgTabIndex} from "../../reducer";
import {btnStyle, checkErrorMessage, useWindowSize} from "../../tools/Tools";
import EventToReserve from "../reservations/EventToReserve";
import ContactCardInfos from "./ContactCardInfos";
import ContactInvoice from "./ContactInvoice";
import ReceiptIcon from '@mui/icons-material/Receipt';

const indicatorColors = ['#ed1c24', '#ed1c24', '#ed1c24', '#ed1c24'];

function ContactCard({ContactCardToChat, bookedList, setToShow, updateCardContact, updateContactList}) {

    let now = moment()
    const dispatch = useDispatch();
    const width = useWindowSize();
    const auditor = useSelector(state => state.axios.auditor);
    const [warnDecline, setWarnDecline] = useState(false);
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const tabItemStyles = useGmailTabItemStyles({color: '#ed1c24'});
    const [modalTabIndex, setModalTabIndex] = useState(0);
    const [address, setAddress] = useState({});
    const countries = useSelector(state => state.global.countries);
    const [load, setLoad] = useState(false);
    const {userId} = useSelector(state => state.profile);
    const tabIndex = useSelector(state => state.contactCards.tabIndex);


    const splitAddress = () => {
        let [street, pcCity, country] = ContactCardToChat?.reservation?.address?.split(', ')
        let [postalCode, city] = pcCity.split(' ')
        setAddress({street, postalCode, country, city})
    }

    const getRoyalties = async (reservationId) => {
        setLoad(true)
        await auditor.put("reservation/finish/" + reservationId).then(async (resp) => {
            updateContactList('finish', resp.data).then(() => setLoad(false))
        }).catch(error => {
            setLoad(false)
            toast.error(checkErrorMessage(error).message)
        });
    }

    const newBtn = () => {

        let book = ContactCardToChat

        const btnDecline = () => {
            return (
                <IconButton
                    aria-label="settings"
                    size="small"
                    disabled={load}
                    sx={btnStyle('red')}
                    onClick={() => setWarnDecline(book.id)}>
                    <small>
                        {userId === book?.reservation?.auditor_who_reserve_id
                            ? "Annuler l'événement"
                            : "Décliner l'événement"}&nbsp;
                    </small>
                    {load
                        ? <div className="mb-1 mr-1">
                            <Spinner type="grow" size="sm"/>
                        </div>
                        : <RemoveCircleOutlineOutlinedIcon
                            fontSize="medium"
                            sx={{color: "red!important"}}
                        />}

                </IconButton>
            )
        }

        const btnAccept = () => {
            return (
                <Tooltip title="Accepter la prise de contact" placement="top">
                    <IconButton
                        size="small"
                        aria-label="settings"
                        disabled={load}
                        sx={btnStyle('lightGreen')}
                        onClick={() => updateCardContact(book.id, 'accepted', setLoad, setWarnDecline)}>
                        <small>Accepter&nbsp;</small>
                        {load
                            ? <div className="mb-1 mr-1">
                                <Spinner type="grow" size="sm"/>
                            </div>
                            : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                    </IconButton>
                </Tooltip>
            )
        }

        const btnGetPayment = () => {
            return (
                <Tooltip title={"Recevez le paiement après avoir effectuer votre prestation"} placement="left">
                    <IconButton
                        size="small"
                        aria-label="settings"
                        sx={btnStyle('lightGreen')}
                        onClick={() => getRoyalties(book?.reservation.id)}
                        disabled={load}>
                        <small>Encaisser</small>&nbsp;
                        {load
                            ? <div className="mb-1 mr-1">
                                <Spinner type="grow" size="sm"/>
                            </div>
                            : <AddTaskIcon fontSize="medium" sx={{color: "lightGreen!important"}}/>}
                    </IconButton>
                </Tooltip>
            )
        }

        return (
            tabIndex === 0
                ? bookedList ? btnDecline() : <div>{btnAccept()}{btnDecline()}</div>
                : tabIndex === 1
                    ? bookedList ? null
                        : book?.reservation?.status === 'paid' && book?.reservation?.payment && now.isAfter(new Date(book?.reservation?.event_date))
                            ? btnGetPayment()
                            : ""
                    : ""
        )
    }

    const checkDiscuss = async () => {
        if (ContactCardToChat?.status === 'accepted' && !ContactCardToChat?.aborted && ContactCardToChat?.reservation?.status !== 'declined') {
            if ((new Date(ContactCardToChat?.reservation?.event_date) >= new Date()) && !ContactCardToChat?.reservation.aborted) {
                setToShow(false)
                await dispatch(setContactToChat(ContactCardToChat))
                await dispatch(setMsgTabIndex(bookedList ? 'book' : 'auditor'))
                document.getElementById("open_discussion").click()
            } else {
                toast("La discussion a été clôturée en raison de l'état de l'événement, soit qu'il a été annulé, soit qu'il est déjà passé.", {
                    icon: <NearbyErrorIcon sx={{color: 'orange!important'}}/>,
                });
            }
        } else if (ContactCardToChat?.status === 'pending') {
            toast("La discussion sera initiée une fois que l'artiste aura donné son accord suite à la prise de contact.", {
                icon: <NearbyErrorIcon/>,
            });
        } else if (ContactCardToChat?.status === 'declined') {
            toast("La discussion a été close suite au désaccord de l'artiste lors de la prise de contact.", {
                icon: <NearbyErrorIcon/>,
            });
        } else if (ContactCardToChat?.aborted) {
            toast("La discussion a été close suite à l'abandon de l'événement.", {
                icon: <NearbyErrorIcon/>,
            });
        }
    }

    useEffect(() => {
        splitAddress()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div>

            <Modal size="sm" show={warnDecline} style={{marginTop: 4}}>
                <CardHeader style={{width: '100%'}} className="m-auto text-center pt-3">
                    <CardTitle tag="h6">
                        <DoNotDisturbIcon sx={{color: 'silver!important'}}/>
                    </CardTitle>
                </CardHeader>
                <p className="p-2 m-auto text-center">
                    {bookedList
                        ? "Êtes-vous certain de vouloir annuler cet événement ?"
                        : "Êtes-vous certain de vouloir décliner cet événement ?"}
                </p>
                <CardFooter>
                    <Row className="justify-content-end">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('red')}
                            onClick={() => setWarnDecline(false)}>
                            <CloseIcon sx={{color: "red!important"}}/>
                        </IconButton>
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('lightGreen')}
                            onClick={() => updateCardContact(parseInt(warnDecline), 'declined', setLoad, setWarnDecline)}>
                            <small>Oui&nbsp;</small>
                            <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                        </IconButton>
                    </Row>
                </CardFooter>
            </Modal>

            <CardFooter>
                <Tabs
                    value={modalTabIndex}
                    classes={tabsStyles}
                    onChange={(e, index) => setModalTabIndex(index)}
                    className="radius-top-1 border-bottom-0"
                    TabIndicatorProps={{
                        children: <div className={`MuiIndicator-${modalTabIndex}`}/>,
                    }}
                >
                    <Tab
                        wrapped
                        label="Événement"
                        classes={tabItemStyles}
                        icon={<CelebrationIcon/>}
                    />
                    <Tab
                        wrapped
                        label="Facture"
                        classes={tabItemStyles}
                        icon={<ReceiptIcon/>}
                    />
                    <IconButton onClick={() => setModalTabIndex(2)}>
                        <InfoIcon/>
                    </IconButton>
                </Tabs>
            </CardFooter>
            <Box className="row justify-content-center">
                {!modalTabIndex
                    ? <EventToReserve
                        address={address}
                        ContactCardToChat={ContactCardToChat}
                        countries={countries}/>
                    : modalTabIndex === 1
                        ? <ContactInvoice
                            address={address}
                            bookedList={bookedList}
                            ContactCardToChat={ContactCardToChat}
                            countries={countries}/>
                        : <ContactCardInfos
                            bookedList={bookedList}
                            ContactCardToChat={ContactCardToChat}/>}
            </Box>
            <CardFooter>
                <Row className="justify-content-end">
                    <Tooltip title="Fermer cette onglet" placement="top">
                        <IconButton
                            aria-label="settings"
                            size="small"
                            disabled={load}
                            sx={btnStyle('gray')}
                            onClick={() => setToShow(false)}>
                            <CloseIcon sx={{color: "gray!important"}}/>
                        </IconButton>
                    </Tooltip>
                    <div className={width < 500 ? "col justify-content-end" : ""}>
                        {newBtn()}
                    </div>
                    <Tooltip title="Ouvrir la discussion" placement="top">
                        <IconButton
                            size="small"
                            disabled={load}
                            aria-label="settings"
                            sx={btnStyle(
                                ContactCardToChat?.status === 'accepted' && !ContactCardToChat?.aborted && ContactCardToChat?.reservation?.status !== 'declined'
                                    ? 'green'
                                    : ContactCardToChat?.status === 'declined' || ContactCardToChat?.aborted || ContactCardToChat?.reservation?.status === 'declined'
                                        ? 'red'
                                        : ContactCardToChat?.status === 'pending'
                                            ? 'gray'
                                            : ContactCardToChat?.status === 'paid'
                                                ? 'blue'
                                                : 'gray'
                            )}
                            onClick={() => checkDiscuss()}>
                            <ForumIcon sx={
                                ContactCardToChat?.status === 'accepted' && !ContactCardToChat?.aborted && ContactCardToChat?.reservation?.status !== 'declined'
                                    ? {color: 'lightGreen!important'}
                                    : ContactCardToChat?.status === 'declined' || ContactCardToChat?.aborted || ContactCardToChat?.reservation?.status === 'declined'
                                        ? {color: 'red!important'}
                                        : ContactCardToChat?.status === 'pending'
                                            ? {color: 'gray!important'}
                                            : ContactCardToChat?.status === 'paid'
                                                ? {color: 'blue!important'}
                                                : {color: 'gray!important'}
                            }/>
                        </IconButton>
                    </Tooltip>
                </Row>
            </CardFooter>
        </div>
    )
}

export default ContactCard;
