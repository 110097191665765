import AddTaskIcon from '@mui/icons-material/AddTask';
import Tooltip from '@mui/material/Tooltip';
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Spinner} from "reactstrap";
import {IconButton} from "@mui/material";
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Add from '@material-ui/icons/Add';
import {Row, Item} from '@mui-treasury/components/flex';
import {Info, InfoTitle, InfoSubtitle} from '@mui-treasury/components/info';
import {useTutorInfoStyles} from '@mui-treasury/styles/info/tutor';
import {useSizedIconButtonStyles} from '@mui-treasury/styles/iconButton/sized';
import {useDynamicAvatarStyles} from '@mui-treasury/styles/avatar/dynamic';
import {insertProfileChecked} from "../../tools/Tools";

const useStyles = makeStyles(() => ({
    action: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
        },
    },
}));

function ArtistToFollow(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const styles = useStyles();
    const {id, service, artistName, checked, tag, disabled} = props;
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);
    const userConnectedId = useSelector(state => state.profile.userId);
    const [userDataToRead, setUserDataToRead] = useState([]);
    const [followed, setFollowed] = useState(false);
    const [loading, setLoading] = useState(true);
    const iconBtnStyles = useSizedIconButtonStyles({padding: 6});
    const avatarStyles = useDynamicAvatarStyles({radius: 12, size: 48});

    const checkProfile = () => {
        let _id = id || service?.user_id
        auditor.get("profiles/check_other_profile/" + _id).then(async resp => {
            setUserDataToRead(resp.data['user_data']['followers'])
            await insertProfileChecked(dispatch, resp);
            /* eslint-disable-next-line array-callback-return */
            resp.data['user_data']['followers'].map((value, index) => {
                if (value.user_id === userConnectedId) {
                    setFollowed(true)
                }
            })
        }).catch((e) => null)
    }

    const FollowArtist = async () => {
        await setLoading(true)
        let _id = id || service?.user_id
        await auditor.post("admiration/admire_user/" + _id).then(() => {
            setFollowed(true)
            setLoading(false)
            let artist_name = service?.artist_name || artistName
            toast.success('Vous avez suivi ' + artist_name)
            try {
                setUserDataToRead({...userDataToRead?.push({id: userConnectedId})})
            } catch (e) {
                //
            }
        }).catch((e) => {
            setLoading(false)
            toast.error("Non autorisé")
        })
    };

    const UnfollowArtist = async () => {
        await setLoading(true)
        let _id = id || service?.user_id
        await auditor.delete("admiration/delete_admire_user/" + _id).then(async () => {
            setFollowed(false)
            setLoading(false)
            let artist_name = service?.artist_name || artistName
            toast.success('Vous ne suivez plus ' + artist_name)
            try {
                setUserDataToRead({...userDataToRead?.filter((value, index) => value.user_id !== userConnectedId)})
            } catch (e) {
                //
            }
        })
    };

    const FollowArtistBtn = () => {
        return (
            !loading
                ? <Item ml={1} position={'middle'}>
                    {!auth
                        ? <Tooltip title="Suivre l'artiste">
                            <IconButton
                                className={styles.action}
                                classes={iconBtnStyles}
                                onClick={() => document.getElementById("login_button").click()}>
                                <Add/>
                            </IconButton>
                        </Tooltip>
                        : followed
                            ? <Tooltip title="Ne plus suivre l'artiste">
                                <IconButton
                                    disabled={disabled}
                                    className={styles.action}
                                    classes={iconBtnStyles}
                                    onClick={() => UnfollowArtist()}>
                                    <AddTaskIcon sx={{color: 'lightGreen!important'}}/>
                                </IconButton>
                            </Tooltip>
                            : <Tooltip title="Suivre l'artiste">
                                <IconButton
                                    disabled={disabled}
                                    className={styles.action}
                                    classes={iconBtnStyles}
                                    onClick={() => FollowArtist()}>
                                    <Add />
                                </IconButton>
                            </Tooltip>}
                </Item> :
                <Item ml={1} position={'middle'}>
                    <Spinner type="grow" size="sm" className="m-auto p-3"/>
                </Item>
        )
    }

    useEffect(() => {
        if (userConnectedId) {
            setLoading(false)
            checkProfile()
        }
        if (!auth) {
            setLoading(false)
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userConnectedId]);


    return (
        checked
            ? <FollowArtistBtn/>
            : <Row
                p={0}
                gap={4}
                bgcolor={'#f5f5f5'}
                borderRadius='5px'
                className="MuiFormControl-root justify-content-between cursor-pointer">
                <Tooltip title="Voir le profil de l'artiste" placement="top">
                    <div className="row">
                        <Item
                            className="ml-2"
                            onClick={() => navigate("/artists/pages/" + service.user_id)}>
                            {service?.artist_photo
                                ? <Avatar classes={avatarStyles} src={service?.artist_photo}/>
                                : <Avatar className={styles.avatar}>
                                    {service?.artist_name?.substring(0, 1).toUpperCase()}
                                </Avatar>}
                        </Item>
                        <Info
                            position={'start'}
                            useStyles={useTutorInfoStyles}
                            onClick={() => navigate("/artists/pages/" + service.user_id)}>
                            <InfoTitle>
                                {service?.artist_name?.substring(0, 22)}
                                {service?.artist_name.length > 22 && "..."}
                            </InfoTitle>
                            <InfoSubtitle>{tag}</InfoSubtitle>
                        </Info>
                    </div>
                </Tooltip>
                <FollowArtistBtn/>
            </Row>
    );
}

export default ArtistToFollow;
