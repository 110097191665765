const initState = {
    wallets: [],
    selected: 0,
    transfers: [],
};

const WalletData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_WALLET":
            return {
                ...state,
                wallets: action.data
            };
        case "ADD_SELECTED_WALLET":
            return {
                ...state,
                selected: action.data
            };
        case "ADD_WALLET_TRANSFERS":
            return {
                ...state,
                transfers: action.data
            };
        default:
            return state;
    }
};

export default WalletData;
