import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {Column, Item, Row} from '@mui-treasury/components/flex';
import {Info, InfoCaption, InfoSubtitle, InfoTitle,} from '@mui-treasury/components/info';
import {useOnlineAvatarStyles} from '@mui-treasury/styles/avatar/online';
import {useGrowIconButtonStyles} from '@mui-treasury/styles/iconButton/grow';
import {useThemeSmInfoStyles} from '@mui-treasury/styles/info/themeSm';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import CloseIcon from "@mui/icons-material/Close";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import MessageIcon from '@mui/icons-material/Message';
import RateReviewIcon from '@mui/icons-material/RateReview';
import TextsmsIcon from '@mui/icons-material/Textsms';
import {Divider} from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from '@mui/material/Tooltip';
import dayjs from "dayjs";
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setContactCardToEvents, setContactToChat, setMsgTabIndex} from "../../reducer";
import NoDataList from "../../tools/NoDataList";
import {useWindowSize} from "../../tools/Tools";
import SkeletonRectangular from "../../views/skeletonCustom/SkeletonRectangular";
import Chat from "./Chat";


function Messages({toggleDrawer}) {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const [booked, setBooked] = useState([]);
    const [loading, setLoading] = useState(true);
    const role = useSelector(state => state.profile.role);
    const defaultGrowStyles = useGrowIconButtonStyles();
    const {contactToChat, msgTabIndex} = useSelector(state => state.global);
    const userId = useSelector(state => state.profile.userId);
    const styles = useOnlineAvatarStyles({color: '#f05a99'});
    const contacts = useSelector(state => state.profile.contacts);


    const handleChange = async (event, newValue) => {
        await setLoading(true)
        await dispatch(setMsgTabIndex(newValue))
        await setBooked([])
        await initMessage(newValue);
    };

    const initMessage = useCallback(async (_type) => {


        let a = {...contacts};
        let b = a[_type]?.accepted
        let _tmp = b.filter((book) => (new Date(book?.reservation?.event_date) >= new Date()) && !book.aborted)
        contactToChat && dispatch(setContactToChat(_tmp.filter((book) => book.id === contactToChat.id)[0]))
        let sorted = _tmp?.sort((a, b) => new Date(a?.reservation?.event_date) - new Date(b?.reservation?.event_date))
        setBooked(sorted);
        dispatch(setContactCardToEvents(sorted.filter((book) =>
            /* eslint-disable-next-line no-mixed-operators */
            ['paid', 'finish', 'accepted', 'pending'].includes(book.reservation.status) && book.status === 'accepted' && !book.aborted)))

        setLoading(false)
    }, [contactToChat, contacts, dispatch])

    useEffect(() => {

        if (![null, ''].includes(role) && !msgTabIndex) {
            dispatch(setMsgTabIndex(role !== 'professional_auditor' ? 'auditor' : 'book'))
        } else if (![null, ''].includes(role)) {
            userId && !booked.length && initMessage(msgTabIndex)
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userId, role, msgTabIndex]);

    return (
        contactToChat
            ? <Chat _type={msgTabIndex} initMessage={initMessage}/>
            : <>

                <div className={width > 500 ? "justify-content-center" : null}>
                    <TextInfoContent
                        useStyles={useN04TextInfoContentStyles}
                        overline={width > 500 ? 'Échange sur les événements' : null}
                        heading={width > 500 ? "Discussions" :
                            <div className="row m-3 justify-content-between">
                                Discussions
                                <InputAdornment position="start" sx={{mt: 4}}>
                                    <Button
                                        id="close_message"
                                        onClick={toggleDrawer('right', false)}
                                        variant="outlined"
                                        endIcon={<CloseIcon/>}>
                                        ESC
                                    </Button>
                                </InputAdornment>
                            </div>}
                    />
                </div>

                {role !== 'professional_auditor' &&
                    <div className="row justify-content-center">
                        <BottomNavigation
                            style={{width: '100%'}}
                            className="mb-3"
                            showLabels
                            value={msgTabIndex}
                            onChange={handleChange}>
                            <BottomNavigationAction
                                label="Avec les auditeurs"
                                value="auditor"
                                icon={<RateReviewIcon/>}
                            />
                            <BottomNavigationAction
                                label="Avec les artistes"
                                value="book"
                                icon={<MessageIcon/>}
                            />
                        </BottomNavigation>
                    </div>}
                <div className="overflow-auto" style={{'maxHeight': '78vh'}}>
                    <Divider sx={{width: '100%'}} orientation="horizontal"/>
                    {loading
                        ? Array.from(Array(6).keys()).map((index) =>
                            <Column gap={2} key={index}>
                                <Row alignItems={'center'}>
                                    {width < 500
                                        ? null
                                        : <Item position={'middle'}>
                                            <SkeletonRectangular height={40} width={40}/>
                                        </Item>}
                                    <Info useStyles={useThemeSmInfoStyles}>
                                        <InfoTitle className="m-1">
                                            <SkeletonRectangular height={20} width={80}/>
                                        </InfoTitle>
                                        <InfoSubtitle className="m-1">
                                            <SkeletonRectangular height={10} width={60}/>
                                        </InfoSubtitle>
                                        {width < 500
                                            ? null
                                            : <InfoCaption className="m-1">
                                                <SkeletonRectangular height={10} width={100}/>
                                            </InfoCaption>}
                                    </Info>
                                    <Item minWidth={30} textAlign={'right'}>
                                        <Tooltip title="Ouvrir la discussion" placement="bottom">
                                            <IconButton classes={defaultGrowStyles}>
                                                <SkeletonRectangular height={25} width={25}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Item>
                                </Row>
                                <Item>
                                    <Divider/>
                                </Item>
                            </Column>)
                        : !booked?.length
                            ? <>
                                {NoDataList("0 Discussion")}
                                {NoDataList(
                                    null,
                                    <IconButton
                                        size="small"
                                        onClick={() => userId && !booked.length && initMessage(msgTabIndex)}>
                                        <small>Rafraîchir</small>&nbsp;<RotateLeftIcon fontSize="small"/>
                                    </IconButton>
                                )}
                            </>
                            : booked.map((book, index) =>
                                <Column gap={2} key={index + 'msg'} className="border-bottom">
                                    <Row className="justify-content-center">
                                        <Item position={'middle'} className="col-2">
                                            <div className={styles.root}>
                                                <Avatar src={msgTabIndex === 'auditor'
                                                    ? book?.reservation?.auditor_photo
                                                    : book?.reservation?.artist_photo}/>
                                            </div>
                                        </Item>
                                        {/* eslint-disable no-useless-concat */}
                                        <div className={"col-8"}>
                                            <Info useStyles={useThemeSmInfoStyles}>
                                                <InfoTitle>
                                                    {msgTabIndex === 'auditor'
                                                        ? book?.lastname + ' ' + book?.name
                                                        : book?.reservation?.artist_name}
                                                </InfoTitle>
                                                <InfoSubtitle>{book?.reservation?.event}</InfoSubtitle>
                                                <InfoCaption>
                                                    Le&nbsp;{dayjs(book?.reservation?.event_date).format("DD-MM-YYYY à HH:mm A")}
                                                </InfoCaption>
                                            </Info>
                                        </div>
                                        <Item minWidth={30} textAlign={'right'} className="col-2">
                                            <Tooltip title="Ouvrir la discussion" placement="bottom">
                                                <IconButton
                                                    classes={defaultGrowStyles}
                                                    onClick={() => dispatch(setContactToChat(book))}>
                                                    <TextsmsIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Item>
                                    </Row>
                                </Column>)}
                </div>
            </>
    );
};

export default Messages;
