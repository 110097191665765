import React from "react";
import {Row} from "reactstrap";

function Privacy({toModal}) {
    return (<div className="content pt-3">
        <Row className="justify-content-center card-flex-row ml-2 mr-2 mt-2 bg-transparent border-0 overflow-auto"
             style={{height: toModal ? "50vh" : "80vh"}}>
            {!toModal && <div className="modal-header justify-content-center">
                <h3 className="bolder">CONDITIONS GÉNÉRALES D'UTILISATION ET DE VENTE</h3>
            </div>}

            <div className="col-12 pl-1 pr-1 text-justify">

                <small className="mt-4 mb-4 text-justify">
                    <span>
                        Les présentes Conditions Générales de Vente (ci-après les « CGV ») sont proposées par
                        <strong> KANTOBIZ</strong>, dont le siège social est sis au
                        <strong> Waviloid Studio IVJ 12 TER EA Ambohimanarina Antananarivo Madagascar </strong>
                        (ci-après « LE VENDEUR »). LE VENDEUR propose, par l’intermédiaire de son site
                        internet <strong>https://www.kantobiz.com </strong>et de son application mobile KantoBiz
                        (ci-après le « <strong>Site et L'application Mobile </strong>»), (ci-après les «
                        <strong> Produits et services </strong>» ). Chaque client reconnaît avoir eu connaissance,
                         de manière lisible et compréhensible,
                        des présentes CGUV ainsi que de l’ensemble des informations nécessaires à l’exécution
                        des présentes, préalablement à la passation de sa réservation ou prise de contact et
                        avant toute conclusion de son événement avec KantoBiz dont le numéro d'identification
                        <strong> STAT</strong> est <strong>70209 11 2023 0 04896</strong>,
                        <strong> NIF : 4018072810</strong>.
                    </span>
                </small>

                <h3 className="text-center text-red bolder mt-4 mb-4">APPLICATION ET OPPOSABILITE DES CGUV</h3>
                <small className="mt-4 mb-4 text-justify">
                    Les présentes CGUV s’appliquent à toute utilisation du Site et de l'application Mobile ainsi qu’à
                    toute réservation de service(s) par tout client, personne physique, agissant à des fins qui
                    n'entrent pas dans le cadre de son activité professionnelle ou tout client, personne physique ou
                    morale, agissant dans le cadre de son activité commerciale, industrielle, artisanale ou libérale
                    (ci-après le « Client ») réalisée sur le Site et l'application Mobile, à compter du 1 juillet
                    2023. Les présentes CGUV ont pour objet de définir les conditions de réservation des services,
                    et de déterminer les droits et obligations respectifs de chacune des parties dans le cadre de
                    la fourniture des services. Sauf accord écrit contraire des parties, ces CGUV l’emportent
                    sur toutes clauses contraires issues de conditions générales antérieurement rédigées, et
                    s’appliquent ainsi à l’exclusion de toute autre convention. Les présentes CGUV doivent
                    être considérées comme faisant partie intégrante et essentielle du contrat conclu entre
                    LE VENDEUR et chacun de ses Clients. Ces CGUV sont systématiquement notifiées au Client
                    lors de la création de son compte personnel sur le Site, de l'application Mobile et de sa
                    réservation sur le Site et l'application Mobile. Chaque Client doit nécessairement en prendre
                    connaissance et les accepter afin de pouvoir valider sa réservation sur
                    le Site et l'application Mobile. Cette acceptation consiste à cocher la case correspondant
                    à la phrase d’acceptation des présentes CGUV, telle que, par exemple, « Je reconnais avoir
                    lu et accepté l’ensemble des conditions générales de vente publiées sur le Site et l'application
                    Mobile ». Le fait de cocher cette case sera réputé avoir la même valeur qu’une signature
                    manuscrite de la part du Client. En cas de modification et/ou adaptation des présentes CGUV
                    par LE VENDEUR, seule la version en vigueur au jour de la réservation
                    du Client sur le Site et/ou l'application Mobile sera valablement applicable.
                    Le Client sera informé des éventuelles modifications apportées aux présentes,
                    lors de sa connexion ultérieure sur le Site et l'application Mobile, par une
                    notification publiée sur le Site et l'application Mobile. Le fait que LE VENDEUR ne se
                    prévale pas, à un moment donné, de l’une quelconque des dispositions des présentes CGUV ne
                    peut être interprété comme valant renonciation à s’en prévaloir ultérieurement.
                    LE VENDEUR invite chaque Client à lire attentivement les présentes CGUV, à les imprimer et/ou
                    les sauvegarder sur tout support durable, avant de procéder à la réservation de tout service sur
                    le Site et/ou l'application Mobile.
                </small>

                <h3 className="text-center text-red bolder mt-4 mb-4">
                    ACCÈS ET DISPONIBILITÉ DU SITE ET DE L'APPLICATION MOBILE
                </h3>
                <small className="mt-4 mb-4 text-justify">
                    LE VENDEUR propose un accès gratuit à son Site et son application Mobile, le Client demeurant en
                    toute hypothèse responsable de son équipement informatique et de sa connexion à Internet,
                    dont les coûts sont à sa charge. L’accès au Site et à l'application Mobile peut être réalisé
                    : <br/>• Depuis un ordinateur ou un terminal équivalant disposant d'un accès à un ou plusieurs
                    réseaux de télécommunications permettant l'accès au réseau Internet et d'un logiciel de navigation
                    sur le réseau Internet (de type Google Chrome, Mozilla Firefox, Safari, etc...) ;<br/>
                    • Un terminal téléphonique disposant d'un accès à un réseau de télécommunications permettant
                    l'accès au réseau Internet (connexion 3G, 4G, Edge, wifi etc.). LE VENDEUR fait ses meilleurs
                    efforts pour rendre le site et l'application Mobile accessible en permanence, sous réserve des
                    opérations de maintenance nécessaires au bon fonctionnement du Site, l'application Mobile ou des
                    serveurs sur lesquels il est hébergé. En cas d’interruption pour maintenance,
                    LE VENDEUR ne pourra être tenu pour responsable de l’impact éventuel de cette indisponibilité sur
                    les activités du Client. Le Client est averti des aléas techniques inhérents à l’Internet
                    et des interruptions d’accès qui peuvent en résulter. En conséquence, LE VENDEUR ne pourra être
                    tenu responsable des éventuelles indisponibilités ou ralentissements du Site et/ou de l'application
                    Mobile. Le Client est informé que les serveurs du VENDEUR sont
                    hébergés par la société <strong>DigitalOcean</strong>.
                     Tout manquement du Client aux obligations lui incombant
                    en vertu des présentes CGUV sera susceptible d’entraîner la suspension ou l’interdiction de l’accès
                    du Client au Site et à l'application Mobile. Les réservations de service(s) sont réalisées par
                    l’intermédiaire du Site et/ou de l'application Mobile. La détention d’un compte utilisateur
                    personnel et obligatoire afin de procéder à la réservation des services sur le Site et
                    l'application Mobile. La création d'un compte utilisateur se fait grâce aux champs
                    obligatoires suivants : Nom, émail. Le compte utilisateur permet d'accéder à une interface
                    personnalisée, de gérer son profil et ses réservations. Toutefois en cas d'information erronée,
                    le client peut ne pas recevoir d'émail ou rencontrer d'autres difficultés pour passer, suivre ou
                    recevoir sa réservation. Le Client choisit directement sur le Site et/ou l'application Mobile
                    le(s) service(s) qu’il souhaite réserver. LE VENDEUR s’efforce de fournir des visuels et des
                    descriptions les plus fidèles possibles aux services. Toutefois, ces visuels et textes
                    d’illustration n’étant pas contractuels, le Client ne saurait engager la responsabilité
                    du VENDEUR à ce titre. Le Client est tenu de renseigner un certain nombre d’informations
                    le concernant en vue de valider sa réservation. Toutes les réservations doivent être dûment
                    remplies et doivent contenir ces informations strictement nécessaires à la réservation.
                    Le Client est responsable de la véracité, de l’exactitude et de la pertinence des données fournies.
                     Le Client pourra procéder à des changements, des corrections, des ajouts ou même annuler
                    sa réservation, et ce, jusqu'à la validation de celle-ci sur la page récapitulative de réservation,
                    avant le paiement.
                </small>

                <h3 className="text-center text-red bolder mt-4 mb-4">PRIX ET MODALITES DE PAIEMENT</h3>
                <h4 className="text-center text-red bolder mt-4 mb-4">PRIX</h4>
                <small className="mt-4 mb-4 text-justify">
                    L’accès au Site et a l'application ainsi qu’à la présentation des Services est gratuit pour
                    le Client. La réservation d’un Services fera l’objet d’un règlement par ce dernier ou quelques
                    fois la prise de contact d'un artiste. Les prix des Services sont mentionnés sur le Site et
                    l'application Mobile selon le pays où il est visionné et toutes taxes comprises.
                    Les prix applicables sont ceux valables au jour de la réservation sur le Site et/ou l'application
                    Mobile par le Client. Les prix des services fournis peuvent varier selon l'artiste.
                    Les prix des Services et les éventuels coûts supplémentaires liés à la réservation sont indiqués,
                    de manière claire et compréhensible, sur le récapitulatif de la réservation.
                    <strong> Avant de passer la réservation, le Client est tenu de confirmer ce récapitulatif</strong>.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">Modalités de paiement</h4>
                <small className="mt-4 mb-4 text-justify">
                    Les prix seront facturés sur la base des tarifs en vigueur au moment de la réservation. Une facture
                    récapitulant l’ensemble des Services réservés par le Client ainsi que leur coût respectif sera
                    systématiquement adressée à ce dernier. Le Client réglera le prix, directement sur le Site et/ou
                    l'application, et impérativement avant toute réalisation par LE VENDEUR, et ce conformément au
                    processus prévu à cet effet. Le VENDEUR utilise un système de paiement sécurisé tiers
                    <strong> Stripe</strong>.
                    Le défaut de paiement à l’échéance entraînera automatiquement, sans mise en demeure préalable
                    et de plein droit, la suspension ou l’invalidation de la réservation du Client, sans préjudice
                    de toute autre voie d’action.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">DROIT DE RETRACTATION</h4>
                <small className="mt-4 mb-4 text-justify">
                    Le Client consommateur dispose d'un délai entre sept (7) et quatorze (14) jours francs à compter
                    de l'accord de l'artiste sur une réservation pour exercer son droit de rétractation. Ceci selon
                    la politique de remboursement de l'artiste bien détaillé avant la réservation, sans avoir à
                    justifier d’un quelconque motif ni à payer de pénalités. S'il entend exercer son droit de
                    rétractation, le Client consommateur doit informer LE VENDEUR de sa décision de se rétracter du
                    contrat soit :<br/>• Par le biais d'annulation mis à sa disposition sur le Site et/ou l'application
                    Mobile, et plus précisément, sur son compte client personnel.<br/>Et/OU<br/> • En présentant toute autre
                    déclaration explicite et non équivoque à cet effet (par exemple, une lettre envoyée par courrier
                    recommandé avec accusé de réception). En tout état de cause, le Client doit indiquer une volonté
                    de se rétracter dénuée d'ambiguïté et non équivoque. En cas de rétractation effectuée sur le Site
                    et/ou l'application Mobile par la transmission en ligne, LE VENDEUR recevra, sans délai, un accusé
                    de réception sur un support durable, à l'adresse émail fournie lors de sa réservation. En cas
                    d'exercice du droit de rétractation par le Client, l’ensemble des sommes versées par le Client
                    lui sera remboursé par LE VENDEUR dans un délai entre sept (7) et quatorze (14) jours à compter de
                    la date à laquelle elle est informée de la décision du Client de se rétracter. Le remboursement
                    sera effectué sur le compte bancaire du Client directement par l’intermédiaire du Site et/ou
                    l'application Mobile par virement bancaire ou par tout autre moyen convenu entre les parties.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">GARANTIE DES PRODUITS</h4>
                <h5 className="text-center text-red bolder mt-4 mb-4">Garantie légale de conformité</h5>
                <small className="mt-4 mb-4 text-justify">
                    Le vendeur est tenu de livrer un bien conforme avec le Client consommateur et de répondre des
                    défauts de conformité existant pendant la réservation du Service. La garantie de conformité pourra
                    s'exercer si un défaut devait exister au jour de la prise de possession du Service. Toutefois,
                    lorsque le défaut est apparu dans les 24 mois qui suivent cette date, il est présumé remplir
                    cette condition. Le Vendeur peut combattre cette présomption si celle-ci n'est pas compatible
                    avec la nature du Service ou le défaut de conformité invoqué. À cet égard, pourra analyser le
                    Service litigieux afin de déterminer si le défaut relevé existait ou non au jour de la délivrance
                    du service au Client. En revanche, passé ce délai de 24 mois, il reviendra au Client de prouver
                    que le défaut existait bien au moment de la prise de possession du Service (La réalisation d'un
                    événement). Le vendeur peut ne pas procéder selon le choix de l'acheteur si ce choix entraîne un
                    coût manifestement disproportionné au regard de l'autre modalité, compte tenu de la valeur du bien
                    ou de l'importance du défaut. Il est alors tenu de procéder, sauf impossibilité, selon la modalité
                    non choisie par l'acheteur.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">RESPONSABILITE</h4>
                <small className="mt-4 mb-4 text-justify">
                    Chacune des parties assume la responsabilité des conséquences résultant
                    de ses fautes, erreurs ou omissions et causant un dommage direct à l’autre partie.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">Responsabilité du Client</h4>
                <small className="mt-4 mb-4 text-justify">
                    Le Client est seul responsable de la qualité, de la précision, de la pertinence et de l’exactitude
                    des informations qu’il renseigne sur le Site et l'application Mobile aux fins de sa réservation.
                    La responsabilité du VENDEUR ne saurait être engagée à ce titre. Le Client est ainsi seul
                    responsable à l’égard du VENDEUR et, le cas échéant, des tiers, de tous dommages, directs ou
                    indirects, de quelque nature que ce soit, causé par une information ou toute autre publication
                    communiquée, transmis ou diffusée à l’occasion des présentes, ainsi que de tout manquement de sa
                    part aux présentes stipulations contractuelles. Le Client est, en outre, seul responsable du
                    choix des Services qu’il a réservés par l’intermédiaire du Site et/ou l’application.
                    Tout Client s’engage à ne pas utiliser le Site et l'application Mobile en contravention de
                    l’ensemble des lois, règles et réglementations en vigueur.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">Responsabilité du VENDEUR</h4>
                <small className="mt-4 mb-4 text-justify">
                    LE VENDEUR met en œuvre toutes les mesures propres à assurer au Client la fourniture (Materiels),
                    dans des conditions optimales, de Services de qualité. Elle assume l’entière responsabilité des
                    Services qu’elle propose et vend aux Clients par le biais du Site, de l'application Mobile et
                    traitera seules des potentielles réclamations relatives auxdits Services. LE VENDEUR ne peut
                    toutefois être tenu responsable de tout dommage, qui serait imputable soit au Client, soit au
                    fait imprévisible et insurmontable d’un tiers étranger au contrat, soit à un cas de force majeure.
                    LE VENDEUR s’assure du bon fonctionnement du Site et de l'application Mobile mais ne saurait en
                    aucun cas garantir qu’il est exempt d’anomalies ou d’erreurs et qu’il fonctionne sans interruption.
                    LE VENDEUR ne saurait être tenu responsable du non-fonctionnement, d’une impossibilité d’accès
                    ou de dysfonctionnements des services du fournisseur d’accès des Clients, à ceux du réseau Internet.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">FORCE MAJEURE</h4>
                <small className="mt-4 mb-4 text-justify">
                    La « force majeure » se définit comme tout événement échappant au contrôle de l’une des parties et
                    insusceptible d’être raisonnablement prévu lors de la conclusion des présentes CGUV. Un tel
                    événement sera caractérisé dès lors que la partie, victime d’un tel événement, serait empêchée
                    d’exécuter convenablement ses obligations contractuelles, et ce, malgré la mise en œuvre de
                    mesures adéquates et appropriées destinées à en limiter les effets. Aucune des deux parties
                    ne sera tenue pour responsable vis-à-vis de l'autre de la non-exécution ou des retards dans
                    l'exécution d'une obligation née des présentes CGUV qui seraient dus au fait de l'autre partie
                    consécutivement à la survenance d'un cas de force majeure, tel que reconnu et défini par
                    la jurisprudence. Le cas de force majeure suspend les obligations nées des présentes CGUV pendant
                    toute la durée de son existence, et aucune des parties ne pourra, pendant cette période,
                    valablement se prévaloir de l’existence d’un tel cas de force majeure afin de justifier la fin de sa
                    relation contractuelle avec l’autre partie.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">PROPRIETE INTELLECTUELLE</h4>
                <small className="mt-4 mb-4 text-justify">
                    Le Site, l'application Mobile, ainsi que les bases de données, textes, documents, informations,
                    images, photographies, graphismes, logos, ou toutes autres données demeurent la propriété exclusive
                    de KantoBiz ou, le cas échéant, de leurs titulaires respectifs dont LE VENDEUR a obtenu
                    les autorisations d’exploitation. LE VENDEUR demeure titulaire de l’ensemble des droits de
                    propriété intellectuelle afférents à sa marque déposée, ainsi que de l’ensemble des droits
                    de propriété intellectuelle et des droits d’auteur afférents à tout autre signe distinctif
                    lui appartenant. Toute reproduction et/ou représentation, téléchargement, traduction, adaptation,
                    exploitation, distribution, diffusion et/ou communication, sous quelque forme que ce soit,
                    à titre commercial ou non, de toute ou partie de la marque et/ou d’une œuvre de l’esprit
                    originale ou donnée contenue sur le Site et l'application Mobile est formellement interdite.
                    Le Client s’interdit également tous agissements et tout acte susceptible de porter atteinte
                    directement ou indirectement aux droits de propriété intellectuelle de KantoBiz.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">PROTECTION DES DONNEES PERSONNELLES</h4>
                <small className="mt-4 mb-4 text-justify">
                    Le Client est informé que la création de son compte personnel sur le Site et/ou l'application
                    Mobile ainsi que sa réservation de Services sur le Site et/ou l'application Mobile donne lieu
                    à la collecte et au traitement automatisé de données à caractère personnel le concernant par
                    LE VENDEUR dont l'utilisation est soumise aux dispositions de la  loi sur la protection des
                    données à caractère personnel. LE VENDEUR met à la disposition du Client, sur son Site et son
                    application Mobile, une charte de confidentialité spécifiant l’ensemble des informations afférentes
                    à l’utilisation des données à caractère personnel du Client collectées par LE VENDEUR et aux
                    droits dont le Client dispose vis-à-vis de ces données personnelles.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">COOKIES</h4>
                <small className="mt-4 mb-4 text-justify">
                    LE VENDEUR a recours à des « cookies », afin d'obtenir des traitements statistiques des et
                    d’améliorer l’expérience de navigation du Client. LE VENDEUR implante un « cookie » dans
                    l'ordinateur du Client avec son consentement préalable. Le Client dispose d'une possibilité
                    de refuser les cookies lorsqu'il visite le Site. La durée de conservation de ces informations
                    dans l'ordinateur du Client est de 13 mois. LE VENDEUR s’engage à ne jamais communiquer le
                    contenu de ces « cookies » à des tierces personnes, sauf en cas de réquisition légale.
                    Le Client peut, en outre, s'opposer à l'enregistrement de « cookies » en configurant son
                    logiciel de navigation. Pour ce faire, le Client procédera au paramétrage de son navigateur :
                    <ul>
                        <li>Pour Internet Explorer :
                            <a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">
                                http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a>
                        </li>
                        <li>Pour Safari : <a
                            href="https://support.apple.com/fr-fr/ht1677">
                            https://support.apple.com/fr-fr/ht1677</a>
                        </li>
                        <li>Pour Google Chrome : <a
                            href="https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en&amp;safe=on">
                            https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en&amp;safe=on</a>
                        </li>
                        <li>Pour Firefox : <a
                            href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">
                            https://support.mozilla.org/fr/kb/activer-desactiver-cookies
                        </a>
                        </li>
                    </ul>
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">LIENS HYPERTEXTES</h4>
                <small className="mt-4 mb-4 text-justify">
                    Le Site et l'application Mobile peuvent inclure des liens hypertextes vers d’autres sites.
                    LE VENDEUR ne peut, en aucun cas, être tenue de la disponibilité technique de sites Internet
                    ou d’applications mobiles exploités par des tiers auxquels le Client accéderait par
                    l’intermédiaire du Site. En outre, LE VENDEUR ne peut donc supporter aucune responsabilité
                    quant aux contenus, publicités, produits et services disponibles sur ou à partir de ces sites,
                    applications mobiles ou sources externes. Si, en dépit des efforts du VENDEUR, un des liens
                    hypertextes présents sur le Site pointait vers un site ou une source internet dont le contenu
                    était ou paraissait non conforme aux exigences, celui-ci s’engage à prendre immédiatement
                    contact avec le directeur de la publication du Site, dont les coordonnées figurent dans les
                    mentions légales publiées sur le Site, afin de lui communiquer l’adresse des pages du site
                    tiers en cause. LE VENDEUR fera alors le nécessaire pour supprimer le lien hypertexte concerné.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">
                    DROIT APPLICABLE ET JURIDICTION COMPETENTE
                </h4>
                <small className="mt-4 mb-4 text-justify">
                    Les présentes CGUV sont régies par le droit Malagasy. En cas de litige auquel les présentes CGUV
                    (ou l’une de leurs clauses) et/ou les relations entre les parties pourraient donner lieu, le Client
                    consommateur peut saisir à son choix, outre l’une des juridictions territorialement compétentes
                    en vertu du Code de procédure civile, la juridiction du lieu où il demeurait au moment de la
                    conclusion du contrat ou de la survenance du fait dommageable. Le Client professionnel devra saisir
                    le Tribunal dans le ressort duquel le VENDEUR a son siège social. À cet effet, le professionnel
                    garantit au consommateur le recours effectif à un dispositif de médiation de la consommation.
                    Tout différend ou litige dit de consommation, peut faire l'objet d'un règlement amiable par
                    médiation auprès du médiateur. Il est rappelé que la médiation n'est pas obligatoire mais uniquement
                    proposée afin de résoudre les litiges en évitant un recours à la justice.
                </small>

                <h4 className="text-center text-red bolder mt-4 mb-4">Service Client</h4>
                <small className="mt-4 mb-4 text-justify">
                    Le service clientèle du présent Site est accessible par courrier électronique à
                    l’adresse suivante : creative.kantobiz@gmail.com ou par courrier postal à l’adresse
                    indiquée dans les mentions légales.</small>
            </div>
        </Row>
    </div>);
}

export default Privacy;
