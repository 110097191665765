import Skeleton from "@mui/material/Skeleton";
import React from "react";

function SkeletonSquare({dimension}) {

    return (
        <Skeleton style={{borderRadius: "5px"}} animation="wave" variant="square" height={dimension} width={dimension}/>
    );
}

export default SkeletonSquare;
