import React from "react";
import {useSelector} from "react-redux";
import NoDataList from "../../tools/NoDataList";
import ServiceSliders from "../../tools/ServiceSliders";
import HomeServiceInfos from "./HomeServiceInfos";


function Services() {

    const dj = useSelector(state => state.serviceResults.dj);
    const others = useSelector(state => state.serviceResults.others);
    const results = useSelector(state => state.searchBarData.results);
    const artistMusician = useSelector(state => state.serviceResults.artistMusician);
    const loadingResult = useSelector(state => state.serviceResults.loadingResult);

    return (
        <div className="content">
            <HomeServiceInfos/>

            {!results?.length && !loadingResult
                ? NoDataList("Aucun Résultat")
                : <div>
                    <ServiceSliders artistGenre={"Artist / Musicien"} dataList={artistMusician}/>

                    <ServiceSliders artistGenre={"Dj"} dataList={dj}/>

                    <ServiceSliders artistGenre={"Autres"} dataList={others}/>
                </div>}
        </div>
    );
}

export default Services;
