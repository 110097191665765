import Divider from "@material-ui/core/Divider";
import {Info, InfoTitle} from "@mui-treasury/components/info";
import React from "react";
import {useSelector} from "react-redux";
import {Row} from "reactstrap";
import {ServiceCard} from "../views/services/ServiceCard";
import SkeletonServiceCard from "../views/skeletonCustom/SkeletonServiceCard";
import {useThemeXsInfoStyles} from "@mui-treasury/styles/info/themeXs";

function ServiceSliders({artistGenre, dataList}) {

    const loadingResult = useSelector(state => state.serviceResults.loadingResult);

    return (
        loadingResult
            ? <div className="ml-3 mr-3">
                <Info className="mb-2" useStyles={useThemeXsInfoStyles}>
                    <InfoTitle>{artistGenre}</InfoTitle>
                </Info>
                <Divider/>
                <Row className="justify-content-start mt-2 mb-4">
                    {
                        Array.from(Array(5).keys()).map(
                            (key, index) =>
                                <div className="panel p-2" key={key + "_artist_musician_shadow_" + index}>
                                    <SkeletonServiceCard/>
                                </div>
                        )
                    }
                </Row>
            </div>
            : !dataList?.length
                ? null
                : <div className="ml-3 mr-3">
                    <Info className="mb-2" useStyles={useThemeXsInfoStyles}>
                        <InfoTitle>{artistGenre}</InfoTitle>
                    </Info>
                    <Divider/>
                    <Row className="justify-content-center overflow-auto">
                        <div className="mt-2">
                            <ServiceCard itemKey="artist_musician" data={dataList}/>
                        </div>
                    </Row>
                </div>
    )
}

export default ServiceSliders;
