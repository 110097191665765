import React from "react"
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import Badge from "@mui/material/Badge";
import moment from "moment/moment";
import {useSelector} from "react-redux";
import AddTaskIcon from '@mui/icons-material/AddTask';
import Tooltip from "@mui/material/Tooltip";
import CardHeader from "@material-ui/core/CardHeader";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useThemeXsInfoStyles} from "@mui-treasury/styles/info/themeXs";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
dayjs.extend(utc)


function ContactCardHeader({userId, book, setShowBook, bookedList}) {

    let now = moment()
    const tabIndex = useSelector(state => state.contactCards.tabIndex);
    const isPaidAllow = tabIndex === 1
        && !bookedList
        && (book?.reservation?.status === 'paid'
            && book?.reservation?.payment
            && now.isAfter(new Date(book?.reservation?.event_date))
        )

    return (
        <Tooltip title="Appuyer pour afficher" placement="left">
            <CardHeader
                style={{width: "100%", marginBottom: 2}}
                onClick={() => setShowBook(book)}
                action={<small className="text-muted">{dayjs(book.created_at).utc().format("DD-MM-YYYY")}</small>}
                avatar={
                    <div className="text-lg-center">
                        <div className="s-24 m-auto">
                            {isPaidAllow
                                ? <AddTaskIcon fontSize="small" sx={{color: "lightGreen!important", padding: 0.4}}/>
                                : tabIndex === 1
                                    ? <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                            mb: 0.5,
                                            color:
                                                ['paid', 'finish', 'accepted'].includes(book.reservation.status)
                                                    ? 'lightGreen!important'
                                                    : book.reservation.status === 'declined' || book.aborted
                                                        ? 'danger!important'
                                                        : 'silver!important'
                                        }}/>
                                    : null}
                            {dayjs(book.reservation.event_date).format("DD")}
                        </div>
                        <span>
                            {dayjs(book.reservation.event_date).format("MM")},&nbsp;
                            <small>{dayjs(book.reservation.event_date).format("YYYY")}</small>
                        </span>
                    </div>
                }
                title={
                    <Info useStyles={useThemeXsInfoStyles}>
                        <InfoCaption>
                            {userId === book.user_id
                                ? <strong>{book.reservation.artist_name}</strong>
                                : <strong>{book?.lastname}&nbsp;{book?.name}</strong>}
                        </InfoCaption>
                        <Badge variant="dot" color="secondary" invisible={!isPaidAllow}>
                            <InfoTitle>
                                {book.reservation.event}
                            </InfoTitle>
                        </Badge>
                        <InfoSubtitle className="d-none d-lg-block">
                            {isPaidAllow
                                ? <strong>Fonds disponibles pour encaissement</strong>
                                    : book?.reservation?.status === 'finish'
                                        ? <strong>Encaissement effectué</strong>
                                        : ""}
                        </InfoSubtitle>
                    </Info>
                }
            />
        </Tooltip>
    )
}

export default ContactCardHeader;
