import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import {Column} from '@mui-treasury/components/flex';
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from '@mui-treasury/components/info';
import {useChatzInfoStyles} from '@mui-treasury/styles/info/chatz';
import dayjs from "dayjs";
import {IconButton} from "@mui/material";
import React, {useEffect} from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useFlexyTagStyles} from '@mui-treasury/styles/tag/flexy';
import {usePushingGutterStyles} from '@mui-treasury/styles/gutter/pushing';
import {useN01TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n01';
import {currencySymbolByAddress} from "../../tools/Tools";


function EventToReserve({address, countries, ContactCardToChat}) {

    const _classes = useFlexyTagStyles();
    const gutterStyles = usePushingGutterStyles();
    const currency = currencySymbolByAddress(ContactCardToChat?.reservation.address, countries)

    useEffect(() => {
        //
    }, []);

    return (
        <CardContent className="width-full">
            <TextInfoContent
                useStyles={useN04TextInfoContentStyles}
                overline={<>
                    Fiche&nbsp;
                    <strong>
                        {ContactCardToChat?.reservation?.title.substring(0, 22)}
                        {ContactCardToChat?.reservation?.title.length > 22 && "..."}
                    </strong>
                    <IconButton
                        size="small"
                        aria-label="settings"
                        onClick={() => {
                            const win = window.open("/artist/service/" + ContactCardToChat?.reservation.services_id, "_blank");
                            win.focus();
                        }}>
                        <OpenInNewIcon/>
                    </IconButton>
                </>}
                heading={ContactCardToChat?.reservation.event}
                body={
                    <div>
                        <TextInfoContent
                            useStyles={useN01TextInfoContentStyles}
                            overline={<strong
                                className="text-lowercase">Le&nbsp;{dayjs(ContactCardToChat?.reservation.event_date).format("DD-MM-YYYY à HH:mm A")}</strong>}
                        />
                        <Column gap={2}>
                            <Info useStyles={useChatzInfoStyles}>
                                <InfoTitle>{address.street}</InfoTitle>
                                <InfoSubtitle>{address.postalCode}&nbsp;{address.city}</InfoSubtitle>
                                <InfoCaption>{address.country}</InfoCaption>
                            </Info>
                        </Column>
                        <Column gap={2}>
                            <Info useStyles={useChatzInfoStyles}>
                                <InfoTitle>
                                    Auditeur:&nbsp;
                                    <small>
                                        {ContactCardToChat?.lastname}&nbsp;{ContactCardToChat?.name}
                                    </small>
                                </InfoTitle>
                                <InfoSubtitle>
                                    Artiste:&nbsp;
                                    <strong>{ContactCardToChat?.reservation?.artist_name}</strong>
                                </InfoSubtitle>
                            </Info>
                        </Column>
                        <Box className={gutterStyles.parent}>
                            <Button classes={{root: _classes.root, label: _classes.label}}>
                                <div>
                                    <span className={_classes.overline}>
                                        À partir de&nbsp;{ContactCardToChat?.reservation.min_amount + currency}
                                    </span>
                                    <div className="media-body mt-1">
                                        <span>Proposition</span>
                                    </div>
                                </div>
                                <div className={_classes.icon}>
                                    <div className="align-self-center mt-2">
                                        <h4>{ContactCardToChat?.reservation.total_amount + currency}</h4>
                                    </div>
                                </div>
                            </Button>
                        </Box>
                    </div>
                }
            />
        </CardContent>
    )
}

export default EventToReserve;
