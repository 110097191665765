const apis = {

    // Auth
    login: 'users/login',
    isTokenValid: 'users/if_token_valid',

    // Artist Bank Account
    upBank: 'banking/update',
    addBank: 'banking/create',
    getStripeDocumentsId: 'banking/checkDocumentStripeId',

    // wallet
    transactions: 'transactions/per_page',
    payOut: 'wallet/bank_transfer_to_artist',
    payOutOrangeMoney: 'transactions/orange_money_payout',

    // Others
    getArtistTypes: 'artist_types/all',
    getAllCountries: 'countries_and_cities/all_countries',

    // Event
    auditorToPaid: 'reservation/auditor_to_paid'
};

export default apis;
