import Button from "@material-ui/core/Button";
import AddIcon from '@mui/icons-material/Add';
import { useGithubBtnStyles } from '@mui-treasury/styles/button/github';
import SmartToyIcon from "@mui/icons-material/SmartToy";
import UndoIcon from "@mui/icons-material/Undo";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {ImpulseSpinner} from "react-spinners-kit";
import {useDispatch, useSelector} from "react-redux";
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import {CardBody, CardFooter, CardHeader, FormGroup, Row} from "reactstrap";
import {changeFields, checkErrorMessage, IPriceField, upCardEvents} from "../../tools/Tools";

function NewProposition(props) {

    const dispatch = useDispatch();
    const styles = useGithubBtnStyles();
    const [newPropose, setNewPropose] = useState(null);
    const auditor = useSelector(state => state.axios.auditor);
    const contacts = useSelector(state => state.profile.contacts);
    const contactCardToEvents = useSelector(state => state.global.contactCardToEvents);
    const contactToChat = useSelector(state => state.global.contactToChat);
    const {setLoading, _type, initMessage, setLoad, setMsgList, msgList, propose, currency, loading, toScroll, load, setPropose} = props

    const newProposition = async (reservationId) => {
        if (newPropose) {
            setLoad(true);
            setLoading(true)
            setPropose(true)
            let _tmp = [...msgList, <ChatMsg side={'right'} avatar={<SmartToyIcon/>} messages={[
                "Je fais une nouvelle proposition de " + newPropose.toString() + ' ' + currency
            ]}/>]
            await setMsgList(_tmp)
            toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
            auditor.put("reservation/new_proposition/" + reservationId, {'new_proposition': newPropose}).then(async (resp) => {
                await upCardEvents(resp.data, contacts, contactCardToEvents, dispatch, _type)
                let tmp = [..._tmp, <ChatMsg
                    avatar={<SmartToyIcon/>}
                    messages={["Votre nouvelle proposition a été prise en compte."]}
                />]
                await initMessage(_type)
                await setMsgList(tmp)
                setLoad(false)
                setLoading(false)
                setPropose(false)
                toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
            }).catch(error => {
                setLoad(false)
                setLoading(false)
                setPropose(false)
                toast.error(checkErrorMessage(error).message)
            });
        }
    };

    return (
        <div>
            <CardHeader>
                <p className="p-2 m-auto text-center">
                    Nouvelle proposition
                </p>
            </CardHeader>
            <CardBody>
                <FormGroup className="mt-3">
                    {IPriceField(
                        'Débuter à partir de ' + contactToChat?.reservation?.minPrice + ' ' + currency,
                        newPropose,
                        currency,
                        (e) => changeFields(setNewPropose, e),
                        "Sera présenter à l'auditeur d'aussitôt.",
                        contactToChat?.reservation?.total_amount)}
                </FormGroup>
            </CardBody>
            <CardFooter>
                <Row className="justify-content-start ml-2">
                    <Button
                        size="small"
                        classes={styles}
                        variant="outlined"
                        color={'primary'}
                        aria-label="delete"
                        hidden={load || loading}
                        onClick={() => setPropose(false)}>
                        <UndoIcon fontSize="inherit" />
                    </Button>
                    <Button
                        size="small"
                        classes={styles}
                        variant="outlined"
                        color={'primary'}
                        className="ml-3"
                        hidden={load || loading}
                        endIcon={<AddIcon sx={{color: "lightGreen!important"}}/>}
                        onClick={() => newProposition(propose)}>
                        Envoyer la proposition
                    </Button>
                    <div style={{margin: 12, paddingTop: 3}} hidden={!load || !loading}>
                        <ImpulseSpinner size={50} frontColor="#f5f5f5"/>
                    </div>
                </Row>
            </CardFooter>
        </div>
    )
}

export default NewProposition;
