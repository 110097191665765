import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {Info, InfoCaption, InfoSubtitle} from "@mui-treasury/components/info";
import {useNewsInfoStyles} from "@mui-treasury/styles/info/news";
import {usePopularInfoStyles} from "@mui-treasury/styles/info/popular";
import {useGmailTabItemStyles, useGmailTabsStyles} from "@mui-treasury/styles/tabs";
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import AddCardIcon from "@mui/icons-material/AddCard";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LaunchIcon from "@mui/icons-material/Launch";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {ImpulseSpinner} from "react-spinners-kit";
import {CardBody, CardFooter, CardHeader, Row} from "reactstrap";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import apis from "../../config/apis";
import {switchPaymentMethod} from "../../reducer";
import {
    cardValidation,
    checkErrorMessage,
    orangeMoneyBtn,
    upCardEvents,
    useWindowSize,
} from "../../tools/Tools";
import Timer from "../../tools/Countdown/timer";
import {useGithubBtnStyles} from '@mui-treasury/styles/button/github';

const indicatorColors = ['#d93025', '#1a73e8', '#188038', '#e37400'];

function CheckOutEvent({serviceFee, totalAmount, currency, currencyCode, close, setLoading, initMessage}) {

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const styles = useGithubBtnStyles();
    const width = useWindowSize();
    const [tabIndex, setTabIndex] = useState(0);
    const [orange, setOrange] = useState(false);
    const [process, setProcess] = useState(false);
    const [orangeResponse, setOrangeResponse] = useState({});
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const auditor = useSelector(state => state.axios.auditor);
    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[1]});
    const contacts = useSelector(state => state.profile.contacts);
    const {
        countryLog,
        isDev,
        paymentMethod,
        countryOrangeMoneyAllowed,
        contactCardToEvents,
        contactToChat
    } = useSelector(state => state.global);

    const auditorToPay = async (stripe_token) => {
        await auditor.put(apis.auditorToPaid + "/" + contactToChat?.reservation.id, {stripe_token}).then(async (resp) => {
            await upCardEvents(resp.data, contacts, contactCardToEvents, dispatch, 'book')
            setLoading(false)
            setProcess(false)
            await initMessage('book')
            close()
            toast("Votre paiement à été un succès." +
                "\nL'événement a été ajouté à votre agenda ainsi qu'à celui de l'artiste.", {
                icon: <ThumbUpIcon sx={{color: "lightGreen!important"}}/>,
            });
        }).catch((error) => {
            setLoading(false)
            setProcess(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    const checkValidationOrangeMoney = async () => {
        setProcess(true);
        let data = {
            "amount": isDev ? 1 : totalAmount,
            "order_id": orangeResponse['order_id'],
            "pay_token": orangeResponse['pay_token']
        }
        auditor.post("payments/orange_money_status", data).then(async (resp) => {
            setProcess(false);
            let status = resp.data.status
            if (['INITIATED', 'PENDING'].includes(status)) {
                toast("Le paiement est en cours, je vous prie d'attendre jusqu’à la fin", {duration: 4000});
            } else if (['EXPIRED', 'FAILED'].includes(status)) {
                toast.error("Le paiement a échoué, veuillez recommencer.")
                await setOrange(false)
            } else {
                await setProcess(true)
                await setOrange(false)
                await auditorToPay({
                    "payment_url": orangeResponse['payment_url'],
                    "reference": orangeResponse['order_id']
                })
            }
        }).catch((error) => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    const orangeMoney = () => {
        setProcess(true)
        let data = {"amount": totalAmount, "currency": currencyCode}
        auditor.post("payments/orange_money", data).then(async (resp) => {
            setProcess(false)
            await setOrangeResponse(resp.data)
            await setOrange(true)
        }).catch((error) => {
            setProcess(false)
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    const onChangePaymentMethod = (e) => {
        let paymentType = e.value.type
        dispatch(switchPaymentMethod(paymentType))
    }

    const auditorValidation = async () => {
        setLoading(true)
        setProcess(true)
        if (tabIndex) {
            orangeMoney()
        } else {

            if (paymentMethod === 'paypal') {
                localStorage.setItem('contactToChat', JSON.stringify(contactToChat));
            }

            let return_url = window.location.protocol + "//" + window.location.host + '/artist-contacted-demand'
            cardValidation(stripe, elements, return_url).then(async stripe_token => {
                if (stripe_token) {
                    await auditorToPay(stripe_token)
                } else {
                    setLoading(false)
                    setProcess(false)
                }
            }).catch(error => {
                setLoading(false)
                setProcess(false)
                toast.error(checkErrorMessage(error).message)
            })
        }
    }

    return (
        orange
            ? <div>
                <CardHeader>
                    <Row className="justify-content-center">
                        <span className="mr-4">Expire dans</span>
                        <Timer timerData={{days: 0, hours: 0, minutes: 10, seconds: 0}}/>
                    </Row>
                </CardHeader>
                <iframe
                    className="mt-3"
                    height={width < 500 ? 505 : 700}
                    width="100%"
                    title="orange money payment"
                    src={orangeResponse.payment_url}/>
                <CardFooter>
                    <Row className="justify-content-center">
                        <small>
                            Appuyez sur "Continuer" après avoir confirmé votre paiement.
                        </small>
                    </Row>
                    <Row className="justify-content-end mt-2">
                        <Tooltip title="Annuler la transaction" placement="top">
                            <Button
                                size="small"
                                classes={styles}
                                variant="outlined"
                                color={'primary'}
                                aria-label="delete"
                                disabled={process}
                                onClick={() => setOrange(false)}>
                                <UndoIcon fontSize="inherit"/>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Envoyer la prise contact après validation" placement="top">
                            <Button
                                size="small"
                                classes={styles}
                                variant="outlined"
                                color={'primary'}
                                className="ml-3"
                                disabled={process}
                                endIcon={<DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                                onClick={() => checkValidationOrangeMoney()}>
                                Continuer
                            </Button>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </div>
            : <div>
                <CardHeader>
                    <p className="pt-1 m-auto text-center">
                        Méthode(s) de Paiement
                    </p>
                    <hr/>
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between">
                            <div className="text-left">
                                    <pre className="my-0 pl-1 pr-1 rounded">
                                        {contactToChat?.reservation?.artist_name}&nbsp;
                                        <small>({contactToChat?.reservation?.event})</small>
                                    </pre>
                                <small className="text-muted ml-1">Frais de prestation</small>
                            </div>
                            <small className="text-red">
                                {contactToChat?.reservation?.total_amount + ' ' + currency}
                            </small>
                        </li>
                        {contactToChat?.reservation?.travel_expenses
                            ? <li className="list-group-item d-flex justify-content-between">
                                <div className="text-left">
                                        <pre className="my-0 pl-1 pr-1 rounded">
                                            Frais de déplacement
                                        </pre>
                                </div>
                                <small className="text-red">
                                    {contactToChat?.reservation?.travel_expenses + ' ' + currency}
                                </small>
                            </li>
                            : null}

                        <li className="list-group-item d-flex justify-content-between">
                            <div className="text-left">
                                    <pre className="my-0 pl-1 pr-1 rounded">
                                        Total&nbsp;
                                        <small>TTC</small>
                                    </pre>
                            </div>
                            <strong className="text-red">
                                {totalAmount + ' ' + currency}
                            </strong>
                        </li>

                        <Info useStyles={usePopularInfoStyles}>
                            <InfoCaption>
                                Dont&nbsp;{serviceFee + currency}
                                &nbsp;inclus de frais de service.
                            </InfoCaption>
                        </Info>

                        <Info useStyles={useNewsInfoStyles} className="mt-2">
                            <InfoCaption>Addresse de facturation</InfoCaption>
                            <InfoSubtitle>{contactToChat?.reservation?.address}</InfoSubtitle>
                            <hr/>
                        </Info>
                    </ul>
                </CardHeader>
                <CardBody className="pt-0">
                    {isDev || countryOrangeMoneyAllowed.includes(countryLog.toLowerCase())
                        ? <Tabs
                            classes={tabsStyles}
                            value={tabIndex}
                            className="mb-3"
                            onChange={(e, index) => setTabIndex(index)}
                            TabIndicatorProps={{
                                children: <div className={`MuiIndicator-${tabIndex}`}/>,
                            }}
                        >
                            <Tab
                                classes={tabItem2Styles}
                                disableTouchRipple
                                label={
                                    <div className={'MuiTabItem-labelGroup'}>
                                        <div className={'MuiTabItem-label'}>
                                            Classique
                                        </div>
                                        <div className={'MuiTabItem-subLabel'}>
                                            Visa, Mastercard, paypal ...
                                        </div>
                                    </div>
                                }
                                icon={<AddCardIcon/>}
                            />
                            {isDev
                                ? orangeMoneyBtn(tabItem2Styles)
                                : countryOrangeMoneyAllowed.includes(countryLog.toLowerCase())
                                    ? orangeMoneyBtn(tabItem2Styles)
                                    : null}
                        </Tabs>
                        : null}
                    {tabIndex === 0
                        ? <PaymentElement onChange={(e) => onChangePaymentMethod(e)}/>
                        : <div className="card p-2 rounded-lg">
                            <TextInfoContent
                                useStyles={useBlogTextInfoContentStyles}
                                overline={<div className="pt-2">
                                    Orange Money
                                    <hr/>
                                </div>}
                                heading=""
                                body={
                                    <div className="row mt-0 pt-0 ml-1 mr-1">
                                        <div className="col-md-2 pt-2">
                                            <LaunchIcon sx={{color: 'gray !important'}} fontSize="large"/>
                                        </div>
                                        <div className="col-md-10 text-justify text-muted">
                                            En sélectionnant Orange Money, nous vous redirigerons vers une page
                                            sécurisée pour valider les étapes suivantes.
                                        </div>
                                    </div>}
                            />
                        </div>}
                </CardBody>
                <CardFooter className="ml-3">
                    <Row className="justify-content-start mt-4">
                        <Button
                            size="small"
                            classes={styles}
                            variant="outlined"
                            color={'primary'}
                            aria-label="delete"
                            disabled={process}
                            onClick={() => close()}>
                            <UndoIcon fontSize="inherit"/>
                        </Button>
                        <Button
                            size="small"
                            classes={styles}
                            variant="outlined"
                            color={'primary'}
                            className="ml-3"
                            hidden={process}
                            endIcon={<DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                            onClick={() => auditorValidation()}>
                            {tabIndex ? "Accéder à orange money" : "Finaliser"}
                        </Button>
                        <div style={{margin: 12, paddingTop: 3}} hidden={!process}>
                            <ImpulseSpinner size={50} frontColor="#f5f5f5"/>
                        </div>
                    </Row>
                </CardFooter>
            </div>
    )
}

export default CheckOutEvent;
