import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {Elements} from "@stripe/react-stripe-js";
import React, {useEffect, useRef, useState} from "react";
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {Card, CardBody, CardHeader, Spinner} from "reactstrap";
import {ImpulseSpinner} from "react-spinners-kit";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import apis from "../../config/apis";
import {setContactToChat} from "../../reducer";
import {payment_appearance} from "../../tools/Variables";
import CheckOutEvent from "../../views/checkout/CheckOutEvent";
import AllowEvent from "../../views/reservations/AllowEvent";
import DeclineEvent from "../../views/reservations/DeclineEvent";
import {
    checkErrorMessage,
    checkStripePromise,
    outputHtPrice, removePaymentSearchParams,
    reservationChatInfo,
    reservationChatUpdate, upCardEvents,
    useWindowSize
} from "../../tools/Tools";
import NewProposition from "../../views/reservations/NewProposition";
import ChatFooter from "./ChatFooter";
import ChatNavigation from "./ChatNavigation";


function Chat({_type, initMessage}) {

    const toScroll = useRef()
    const isMounted = useRef(false);
    const width = useWindowSize();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [load, setLoad] = useState(false);
    const [process, setProcess] = useState(true);
    const [loading, setLoading] = useState(false);
    const [toPay, setToPay] = useState(false);
    const [stripePromise, setStripPromise] = useState();
    const [clientSecret, setClientSecret] = useState('');
    const [allowEvent, setAllowEvent] = useState(false);
    const [currency, setCurrency] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    const [propose, setPropose] = useState(false);
    const [serviceFee, setServiceFee] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const {userId, contacts} = useSelector(state => state.profile);
    const auditor = useSelector(state => state.axios.auditor);
    const {isDev, contactCardToEvents, contactToChat, countries} = useSelector(state => state.global);
    const updateInfo = contactToChat?.reservation?.last_user_updated;
    const [closeDiscuss, setCloseDiscuss] = useState(false);
    const [warnDecline, setWarnDecline] = useState(false);
    const [msgList, setMsgList] = useState([
        <ChatMsg
            avatar={<SmartToyIcon/>}
            messages={[
                "Bonjour je suis Izuka." +
                " Mon rôle c'est vous renseignez au maximum sur l'événement en cours. Je mets a disposition toutes les" +
                " commandes nécessaires pour le bon déroulement de la réservation."
            ]}
        />
    ]);

    const assign = async (contactCard) => {
        /* eslint-disable-next-line no-unused-vars */
        let [__, _, country] = contactCard.reservation.address.split(', ')
        let _country = countries?.filter(c => c.name === country || c.nativeName === country)[0]
        setCurrency(_country.currencies.symbol)
        setCurrencyCode(_country.currencies.code)

        let ttc = contactCard?.reservation?.total_amount + contactCard?.reservation?.travel_expenses
        const {serviceFee} = outputHtPrice(ttc)
        setServiceFee(serviceFee)
        setTotalAmount(ttc)

        let tmp = [<ChatMsg avatar={<SmartToyIcon/>} messages={[
            reservationChatInfo(contactCard?.reservation, _country.currencies.symbol)
        ]}/>]
        if (updateInfo) {
            tmp = [...tmp, <ChatMsg avatar={<SmartToyIcon/>} messages={[
                reservationChatUpdate(contactCard?.reservation, _country.currencies.symbol, userId)
            ]}/>]
        }
        await setMsgList([...msgList, ...tmp])

        if (['pending', 'accepted'].includes(contactCard?.reservation?.status)) {
            await checkStripePromise(isDev, setStripPromise, auditor, ttc, _country.currencies.code, setClientSecret, setProcess)
        } else {
            setProcess(false)
        }

        toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
    }

    const checkContactToAssign = async (contactCard) => {

        let x = {}
        searchParams.forEach((value, key) => {
            x[key] = value
        });

        let {payment_intent, redirect_status} = x

        if (redirect_status === 'succeeded') {
            let stripe_token = {'id': payment_intent}
            localStorage.removeItem('contactToChat');
            removePaymentSearchParams(searchParams, setSearchParams);
            await auditor.put(apis.auditorToPaid + "/" + contactCard?.reservation.id, {stripe_token}).then(async (resp) => {
                await dispatch(setContactToChat(null))
                await upCardEvents(resp.data, contacts, contactCardToEvents, dispatch, 'book')
                await initMessage('book')
                toast("Votre paiement à été un succès." +
                    "\nL'événement a été ajouté à votre agenda ainsi qu'à celui de l'artiste.", {
                    icon: <ThumbUpIcon sx={{color: "lightGreen!important"}}/>,
                });
            }).catch((error) => {
                dispatch(setContactToChat(null))
                setProcess(false)
                toast.error(checkErrorMessage(error).message)
            })
        } else if (contactCard?.reservation?.address) {
            await assign(contactCard)
        }
    }

    const close = () => {
        setToPay(false)
        setAllowEvent(true)
    }

    useEffect(() => {

        if (!isMounted.current && process && contactToChat && userId) {
            checkContactToAssign(contactToChat).then(r => null)

            return () => {
                isMounted.current = true
            };
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contactToChat, userId, process]);

    return (
        <Card className="card-stats mb-0" style={{height: width < 500 ? '130vh' : '100vh'}}>
            {process
                ? <div className="row justify-content-center mt-5">
                    <Spinner type="grow" size="sm"/>
                </div>
                : toPay
                    ? <Elements stripe={stripePromise} options={{clientSecret, payment_appearance}}>
                        <CheckOutEvent
                            close={close}
                            currency={currency}
                            setLoading={setLoading}
                            serviceFee={serviceFee}
                            totalAmount={totalAmount}
                            initMessage={initMessage}
                            currencyCode={currencyCode}/>
                    </Elements>
                    : allowEvent
                        ? <AllowEvent
                            setToPay={setToPay}
                            initMessage={initMessage}
                            setLoading={setLoading}
                            setAllowEvent={setAllowEvent}/>
                        : warnDecline
                            ? <DeclineEvent
                                msgList={msgList}
                                setLoad={setLoad}
                                toScroll={toScroll}
                                setLoading={setLoading}
                                setMsgList={setMsgList}
                                warnDecline={warnDecline}
                                initMessage={initMessage}
                                setCloseDiscuss={setCloseDiscuss}
                                setWarnDecline={setWarnDecline}/>
                            : propose
                                ? <NewProposition
                                    load={load}
                                    _type={_type}
                                    msgList={msgList}
                                    setLoad={setLoad}
                                    propose={propose}
                                    loading={loading}
                                    toScroll={toScroll}
                                    currency={currency}
                                    setMsgList={setMsgList}
                                    setLoading={setLoading}
                                    setPropose={setPropose}
                                    initMessage={initMessage}/>
                                : <div>
                                    <CardHeader>
                                        <div className="row justify-content-center">
                                            <ChatNavigation
                                                load={load}
                                                _type={_type}
                                                msgList={msgList}
                                                currency={currency}
                                                toScroll={toScroll}
                                                updateInfo={updateInfo}
                                                setPropose={setPropose}
                                                setLoading={setLoading}
                                                setMsgList={setMsgList}
                                                closeDiscuss={closeDiscuss}
                                                setAllowEvent={setAllowEvent}
                                                setWarnDecline={setWarnDecline}/>
                                        </div>
                                        <hr/>
                                    </CardHeader>

                                    <CardBody className="mt-0">

                                        <div className="overflow-auto" style={{height: width < 500 ? '55vh' : '70vh'}}>
                                            <p style={{whiteSpace: 'pre-line'}} ref={toScroll}>
                                                {msgList?.map((value, index) => <p key={index}>{value}</p>)}
                                                <div style={{margin: 12, paddingTop: 10}} hidden={!loading}>
                                                    <ImpulseSpinner size={50} frontColor="#f5f5f5"/>
                                                </div>
                                            </p>
                                        </div>

                                        <ChatFooter
                                            msgList={msgList}
                                            toScroll={toScroll}
                                            currency={currency}
                                            setLoading={setLoading}
                                            setMsgList={setMsgList}
                                            closeDiscuss={closeDiscuss}/>
                                    </CardBody>
                                </div>
            }
        </Card>
    );
}

export default Chat;
