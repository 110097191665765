import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core/styles";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import p_cover from 'assets/img/damir-bosnjak.jpg';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Card,
    CardBody,
    Row
} from "reactstrap";
import {useFlexyTagStyles} from '@mui-treasury/styles/tag/flexy';
import {usePushingGutterStyles} from '@mui-treasury/styles/gutter/pushing';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {IconButton} from "@mui/material";
import Rating from '@mui/material/Rating';
import Skeleton from "@mui/material/Skeleton";
import Tooltip from '@mui/material/Tooltip';
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {addProfileCheckedNotes, addProfileConditions, addProfileData} from "../../reducer";
import {changeFields, checkErrorMessage, deleteInObject, fillInBodyForm} from "../../tools/Tools";
import SkeletonRectangular from "../skeletonCustom/SkeletonRectangular";
import AddBiography from "./Edits/AddBiography";
import AddDefaultAddress from "./Edits/AddDefaultAddress";
import AddGender from "./Edits/AddGender";
import EditProfileName from "./Edits/EditProfileName";
import ProfileFooter from "./ProfileFooter";

const Profile = ({checked, setToArtist, toCloseProfile}) => {

    const dispatch = useDispatch();
    const classes = useFlexyTagStyles();
    const [edit, setEdit] = useState(false);
    const gutterStyles = usePushingGutterStyles();
    const [newAmount, setNewAmount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeBio, setActiveBio] = useState(false);
    const [activeEditName, setActiveEditName] = useState(false);
    const [activeEditGender, setActiveEditGender] = useState(false);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [customProps, setCustomProps] = useState(false);
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const conditions = useSelector(state => state.profile.conditions);
    const currency = useSelector(state => state.global.globalCurrencySymbol);
    const [activeDefaultAddress, setActiveDefaultAddress] = useState(false);
    const userId = useSelector(state => checked ? state.profileChecked.userId : state.profile.userId);
    const users_who_rated = useSelector(state => state.profileChecked.users_who_rated);
    const [rating, setRating] = useState(users_who_rated[userId.toString()] || 0);
    const role = useSelector(state => checked ? state.profileChecked.role : state.profile.role);
    const notes = useSelector(state => checked ? state.profileChecked.notes : state.profile.notes);
    const profileData = useSelector(state => checked ? state.profileChecked.profileData : state.profile.profileData);

    const updateContactAmount = () => {
        let tmp = deleteInObject({...conditions});
        tmp['contact_amount'] = newAmount
        auditor.put('artist_conditions/update', {...tmp}).then(async () => {
            setEdit(false)
            setNewAmount(null)
            toast.success("Frais de contact à Jour.")
            await dispatch(addProfileConditions({...tmp}));
        }).catch((error) => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    const updateProfilePhoto = async (photo, cover_area, message) => {
        setLoadingPhoto(true);
        const bodyFormData = fillInBodyForm(new FormData(), profileData);
        bodyFormData.append('cover_photo', cover_area ? cover_area : profileData?.cover_photo);
        bodyFormData.append('photo', photo ? photo : profileData?.photo);
        await auditorForm.put("profiles/updateProfile", bodyFormData).then(async resp => {
            await dispatch(addProfileData(resp.data));
            setLoadingPhoto(false);
            toast.success("Photo " + message + " mis a jour");
        }).catch(error => {
            toast.error(checkErrorMessage(error).message);
            setLoadingPhoto(false);
        });
    };

    const splitAddress = async () => {
        if (profileData?.address && profileData?.address !== "null") {
            let [streetDefault, pcCity] = profileData?.address.split(', ')
            let [postalCodeDefault, cityDefault] = pcCity.split(' ')
            await setCustomProps({streetDefault, postalCodeDefault, cityDefault})
        }
        setActiveDefaultAddress(true)
    }

    const updateNotes = (note) => {
        if (!auth) {
            document.getElementById("login_button").click()
        } else {
            let data = {user_id: userId, note: [note]}
            auditor.put('stars/update', data).then(async (resp) => {
                setRating(note)
                let _note = resp.data['note']['note'][0]
                await dispatch(addProfileCheckedNotes(_note))
                toast.success("Merci d'avoir noté")
            })
        }
    }

    useEffect(() => {
        userId && setLoading(false)
    }, [userId])

    useEffect(() => {

    }, [])

    return (
        <Card className="card-user" style={{
            marginBottom: 0,
            borderTopLeftRadius: 12.5,
            borderTopRightRadius: 12.5,
            height: checked ? 'auto' : '100vh'
        }}>

            <div className="image">
                {loading || loadingPhoto
                    ? <SkeletonRectangular width="100%" height={130}/>
                    : <img
                        alt="..."
                        src={profileData?.cover_photo && profileData?.cover_photo !== "null"
                            ? profileData?.cover_photo
                            : p_cover}
                    />}
            </div>

            <CardBody>
                <div className="author">
                    <div style={{position: 'absolute', right: 15, marginTop: 5}}>
                        <div className="cursor-pointer absolute ml-5">
                            <input id="cover_area_file"
                                   hidden={true}
                                   disabled={!!checked}
                                   type="file"
                                   accept="image/png, image/jpeg"
                                   className="input-file"
                                   onChange={(e) => {
                                       updateProfilePhoto(
                                           null,
                                           e.target.files[0],
                                           "de couverture"
                                       ).then(r => null)
                                   }}
                            />
                            <input id="picture"
                                   hidden={true}
                                   disabled={!!checked}
                                   accept="image/png, image/jpeg"
                                   className="input-file"
                                   type="file"
                                   onChange={(e) => {
                                       updateProfilePhoto(
                                           e.target.files[0],
                                           null,
                                           "de profile"
                                       ).then(r => null);
                                   }}
                            />
                        </div>
                        <Tooltip title="Modifier le photo de couverture" placement="bottom">
                            <IconButton>
                                {loading || loadingPhoto
                                    ? <Skeleton
                                        hidden={!!checked}
                                        animation="wave"
                                        variant="rectangular"
                                        sx={{borderRadius: 1}}
                                        width={25}
                                        height={19}/>
                                    : <AddAPhotoIcon fontSize="medium"
                                                     hidden={!!checked}
                                                     disabled={!!checked}
                                                     onClick={() => document.getElementById("cover_area_file").click()}/>}
                            </IconButton>
                        </Tooltip>
                    </div>
                    <a href="#pablo" onClick={() => !loading && document.getElementById("picture").click()}>
                        {loading || loadingPhoto
                            ? <Row className="justify-content-center mb-4">
                                <SkeletonRectangular height={124} width={124}/>
                            </Row>
                            : profileData?.photo && profileData?.photo !== "null"
                                ? <Tooltip title={checked ? "" : "Modifier le photo de profil"} placement="right">
                                    <img
                                        alt="..."
                                        className="avatar border-gray"
                                        src={profileData?.photo}
                                    />
                                </Tooltip>
                                : <Tooltip title={checked ? "" : "Modifier le photo de profil"} placement="right">
                                    <Avatar className={styles.logo}>
                                        {checked
                                            ? profileData?.artist_name?.substring(0, 1).toUpperCase()
                                            : profileData?.name?.substring(0, 1).toUpperCase()}
                                    </Avatar>
                                </Tooltip>}
                    </a>
                    {/* eslint-disable no-useless-concat */}
                    <h5 className={"title " + `${loading ? " " : profileData?.photo ? "mt-2" : "mt-5"}`}>
                        {loading ?
                            <Row className="justify-content-center mb-3">
                                <Skeleton animation="wave" variant="circular" height={17} width={17}/>
                                <Skeleton animation="wave" sx={{marginLeft: "5px"}} height={17} width="43%"/>
                            </Row> :
                            <div className="row justify-content-center mt-5">
                                {activeEditName || activeEditGender
                                    ? null
                                    : profileData?.gender === 'male'
                                        ? <Tooltip title="Homme" placement="top">
                                            <MaleIcon
                                                sx={{cursor: "pointer"}}
                                                onClick={() => setActiveEditGender(true)}/>
                                        </Tooltip>
                                        : profileData?.gender === 'female'
                                            ? <Tooltip title="Femme" placement="top">
                                                <FemaleIcon
                                                    sx={{cursor: "pointer"}}
                                                    onClick={() => setActiveEditGender(true)}/>
                                            </Tooltip>
                                            : <Tooltip title="Sexe non défini" placement="top">
                                                <CircleIcon
                                                    fontSize="small"
                                                    sx={{marginTop: 0.2, cursor: "pointer"}}
                                                    onClick={() => setActiveEditGender(true)}/>
                                            </Tooltip>}
                                &nbsp;

                                {activeEditGender && <AddGender closeModal={() => setActiveEditGender(false)}/>}

                                {activeEditName || activeEditGender
                                    ? "" : checked ? profileData?.artist_name : profileData?.name}

                                {activeEditGender
                                    ? ""
                                    : activeEditName
                                        ? <EditProfileName closeModal={() => setActiveEditName(false)}/>
                                        : role !== 'professional_auditor' && !checked
                                            ? profileData?.artist_name
                                                ? <small
                                                    className="mt-1">{" (aKa: " + profileData?.artist_name + ")"}</small>
                                                : <Tooltip title="Vous n'avez pas de nom d'artiste" placement="top">
                                                    <IconButton sx={{top: "-7px", marginLeft: 1}} aria-label="search">
                                                        <WarningAmberIcon
                                                            fontSize="small"
                                                            sx={{color: "grey!important"}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            : ""}
                                &nbsp;
                                {!activeEditName && !activeEditGender
                                    && <Tooltip
                                        title={
                                            role !== 'professional_auditor'
                                                ? "Modifier mon pseudo"
                                                : "Modifier le pseudo/Nom d'artiste"}
                                        placement="top">
                                        <EditIcon
                                            fontSize="small"
                                            sx={{marginTop: 0.2, cursor: "pointer"}}
                                            hidden={!!checked}
                                            disabled={!!checked}
                                            onClick={() => setActiveEditName(true)}/>
                                    </Tooltip>}
                            </div>}
                    </h5>
                </div>

                <p className={"description text-center " + `${loading ? "mb-3" : ""}`} hidden={checked}>
                    {loading
                        ? <Skeleton animation="wave" sx={{margin: "0 auto"}} height={17} width="55%"/>
                        : profileData?.email}
                </p>

                {loading
                    ? <Skeleton animation="wave" sx={{margin: "0 auto"}} height={17} width="50%"/>
                    : checked
                        ? !profileData?.description || (profileData?.description === "null")
                            ? <p className="text-center">Je n'ai pas de biographie pour le moment ;)</p>
                            : profileData?.description

                        : <Row className="justify-content-between ml-2 mr-1">
                            {activeBio
                                ? <>
                                    <AddBiography closeModal={() => setActiveBio(false)}/>
                                    <IconButton aria-label="settings" onClick={() => setActiveBio(false)}>
                                        <CloseIcon/>
                                    </IconButton>
                                </>
                                : <>
                                    <a href="#kantobiz_artist_bio" className="mt-2" onClick={() => setActiveBio(true)}>
                                        {!profileData?.description || (profileData?.description === "null")
                                            ? 'Ajouter une biographie ...'
                                            : profileData?.description}
                                    </a>
                                    <IconButton aria-label="settings" onClick={() => setActiveBio(true)}>
                                        <EditIcon fontSize="small"/>
                                    </IconButton>
                                </>}
                        </Row>}

                {!checked
                    ? loading
                        ? <Skeleton animation="wave" sx={{margin: "0 auto"}} height={17} width="63%"/>
                        : <Row className="justify-content-between ml-2 mr-1">
                            {activeDefaultAddress
                                ? <>
                                    <AddDefaultAddress {...customProps}
                                                       closeModal={() => setActiveDefaultAddress(false)}/>
                                    <IconButton aria-label="settings" onClick={() => setActiveDefaultAddress(false)}>
                                        <CloseIcon/>
                                    </IconButton>
                                </>
                                : <>
                                    <a href="#kantobiz_artist_address" className="mt-2"
                                       onClick={() => setActiveDefaultAddress(true)}>
                                        {!profileData?.address || (profileData?.address === "null")
                                            ? 'Définir un adresse par défaut ...'
                                            : profileData?.address}
                                    </a>
                                    <IconButton aria-label="settings" onClick={() => splitAddress()}>
                                        <EditIcon fontSize="small"/>
                                    </IconButton>
                                </>}
                        </Row>
                    : null}

                <div className="col mt-4" hidden={!checked}>
                    <h1 className="text-center">😎</h1>
                    <div className="col text-center mt-4">
                        <Tooltip title="Je note" placement="bottom">
                            <Rating
                                name="half-rating"
                                value={rating}
                                size="large"
                                onChange={(e, newValue) => updateNotes(newValue)}/>
                        </Tooltip>
                        <small className="mb-1">{notes}✩</small>
                    </div>
                </div>

                <div className="mt-4" hidden={role === 'professional_auditor' || checked}>
                    {loading
                        ? <Skeleton animation="wave" sx={{margin: "0 auto"}} height={100} width={200}/>
                        : <Row className="justify-content-between ml-1 mr-1">
                            <div>
                                <Box className={gutterStyles.parent}>
                                    <Button classes={{root: classes.root, label: classes.label}}>
                                        <div>
                                            <span className={classes.overline}>Frais de contact</span>
                                            <div className="media-body mt-1">
                                                <span>Pour les auditeurs</span>
                                            </div>
                                        </div>
                                        <div className={classes.icon}>
                                            <div className="align-self-center mt-2">
                                                <h4>
                                                    <small hidden={edit}>{currency}</small>
                                                    {!edit
                                                        ? conditions?.contact_amount
                                                        : <InputGroup className="no-border" style={{width: 200}}>
                                                            <Input
                                                                type="number"
                                                                value={newAmount}
                                                                onChange={(e) => changeFields(setNewAmount, e)}
                                                                placeholder={conditions?.contact_amount + currency}/>
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText onClick={() => {setEdit(false)}}>
                                                                    <i className="nc-icon nc-simple-remove text-danger ml-2"/>
                                                                </InputGroupText>
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-send text-success ml-2"
                                                                       onClick={() => newAmount.length && updateContactAmount()}/>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>}
                                                    <ModeEditOutlinedIcon
                                                        hidden={edit}
                                                        ontSize="small"
                                                        onClick={() => setEdit(true)}
                                                        className="pb-1"
                                                        sx={{color: "#51cbce!important"}}/>
                                                </h4>
                                            </div>
                                        </div>
                                    </Button>
                                </Box>
                            </div>
                            <Tooltip
                                placement="top"
                                title="Voici le montant nécessaire pour vous contacter. Survolez ou cliquez pour le modifier.">
                                <IconButton aria-label="settings">
                                    <InfoIcon fontSize="small" sx={{color: "gray !important"}}/>
                                </IconButton>
                            </Tooltip>
                        </Row>}
                </div>
            </CardBody>

            <ProfileFooter
                role={role}
                userId={userId}
                checked={checked}
                profileData={profileData}
                setToArtist={setToArtist}
                toCloseProfile={toCloseProfile}/>

        </Card>
    );
};

const styles = makeStyles({
    logo: {
        transition: '0.3s',
        width: 200,
        height: 200,
        fontSize: '4rem',
        cursor: 'pointer',
        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.24)',
        borderRadius: '1rem',
    }
});

export default Profile;
