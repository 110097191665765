import {PaymentElement} from "@stripe/react-stripe-js";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {useDispatch, useSelector} from "react-redux";
import {addContactFormTabIndex, switchPaymentMethod} from "../../../reducer";
import {useGmailTabItemStyles, useGmailTabsStyles} from "@mui-treasury/styles/tabs";
import {orangeMoneyBtn} from "../../../tools/Tools";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import LaunchIcon from '@mui/icons-material/Launch';

const indicatorColors = ['#d93025', '#1a73e8', '#188038', '#e37400'];


function AddPaymentMethods() {

    const dispatch = useDispatch();
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const {service, tabIndex} = useSelector(state => state.toContact);
    const contact_amount = service?.contact_amount
    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[1]});
    const {countryLog, isDev, countryOrangeMoneyAllowed} = useSelector(state => state.global);

    const onChangePaymentMethod = (e) => {
        let paymentType = e.value.type
        dispatch(switchPaymentMethod(paymentType))
    }

    return (
        <div className="justify-content-center">
            {(contact_amount && isDev) || (contact_amount && countryOrangeMoneyAllowed.includes(countryLog.toLowerCase()))
                ? <Tabs
                    classes={tabsStyles}
                    value={tabIndex}
                    className="mb-3"
                    onChange={(e, index) => dispatch(addContactFormTabIndex(index))}
                    TabIndicatorProps={{
                        children: <div className={`MuiIndicator-${tabIndex}`}/>,
                    }}
                >
                    <Tab
                        classes={tabItem2Styles}
                        disableTouchRipple
                        label={
                            <div className={'MuiTabItem-labelGroup'}>
                                <div className={'MuiTabItem-label'}>
                                    Classique
                                </div>
                                <div className={'MuiTabItem-subLabel'}>Visa, Mastercard, paypal ...</div>
                            </div>
                        }
                        icon={<CreditCardIcon/>}
                    />
                    {orangeMoneyBtn(tabItem2Styles)}
                </Tabs> : null}
            {contact_amount && tabIndex === 0
                ? <div className="form-group">
                    <PaymentElement onChange={(e) => onChangePaymentMethod(e)}/>
                </div>
                : <div className="card p-2 rounded-lg">
                    <TextInfoContent
                        useStyles={useBlogTextInfoContentStyles}
                        overline={<div className="pt-2">
                            Orange Money
                            <hr/>
                        </div>}
                        heading=""
                        body={
                        <div className="row mt-0 pt-0 ml-1 mr-1">
                            <div className="col-md-2 pt-2">
                                <LaunchIcon sx={{color: 'gray !important'}} fontSize="large"/>
                            </div>
                            <div className="col-md-10 text-justify text-muted">
                                En sélectionnant Orange Money, nous vous redirigerons vers une page sécurisée pour valider les étapes suivantes.
                            </div>
                        </div>}
                    />
                </div>}
        </div>
    )
}

export default AddPaymentMethods;
