import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {Item} from "@mui-treasury/components/flex";
import Skeleton from "@mui/material/Skeleton";
import cx from "clsx";
import randomColor from "randomcolor";
import React from "react";
import {useSlopeCardMediaStyles} from '@mui-treasury/styles/cardMedia/slope';
import {Row} from "reactstrap";

function SkeletonServiceCard() {

    const slopeStyles = useSlopeCardMediaStyles();
    const useStyles = makeStyles(({palette}) => ({
        root: {
            cursor: "pointer",
            position: 'relative',
            borderRadius: '1rem',
            width: 250,
            '&:before': {
                transition: '0.2s',
                position: 'absolute',
                width: '100%',
                height: '100%',
                content: '""',
                display: 'block',
                borderRadius: '1rem',
                zIndex: 0,
                bottom: 0,
            },
            '&:hover': {
                '&:before': {
                    bottom: -6,
                },
                '& $logo': {
                    transform: 'scale(1.1)',
                    boxShadow: '0 6px 20px 0 rgba(0,0,0,0.38)',
                },
            },
        },
        cover: {
            borderRadius: '1rem',
        },
        content: ({color}: { color: string }) => ({
            position: 'relative',
            zIndex: 1,
            borderRadius: '1rem',
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 0,
                width: '100%',
                height: '100%',
                clipPath:
                    'polygon(0% 100%, 0% 35%, 0.3% 33%, 1% 31%, 1.5% 30%, 2% 29%, 2.5% 28.4%, 3% 27.9%, 3.3% 27.6%, 5% 27%,95% 0%,100% 0%, 100% 100%)',
                borderRadius: '1rem',
            },
        }),
    }));
    const styles = useStyles({color: randomColor({luminosity: 'dark'})});

    return (
        <Box className={cx(styles.root)}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                classes={slopeStyles}
                sx={{
                    height: "200px !important",
                    marginBottom: "-40px",
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem'
                }}/>
            <Box className={styles.content} p={2} sx={{borderBottom: 1}}>
                <Box position={'relative'} zIndex={1}>
                    <Row p={0} className="width-full justify-content-center">
                        <Item className="col-4">
                            <Skeleton animation="wave" variant="rectangular"
                                      className={styles.cover} width={75} height={75}/>
                        </Item>
                        <Item position={'bottom'} className="col-8 pl-4 mt-4 pt-2">
                            <h2><Skeleton animation="wave" height={20} width="60%"/></h2>
                            <span className="mt-2">
                                <Skeleton animation="wave" height={15} width="70%"/>
                            </span>
                        </Item>
                    </Row>
                    <Row className="mt-3">
                        <Skeleton className="mt-2 ml-1" animation="wave" height={20} width="80%"/>
                    </Row>
                    <Row className="pt-2 justify-content-between"
                         style={{width: "300px !important"}}>
                        <Item className="justify-content-start m-1">
                            <Skeleton animation="wave" height={20} width={50}/>
                        </Item>
                        <Item className="justify-content-end m-1">
                            <Skeleton animation="wave"
                                      variant="rectangular"
                                      height={20}
                                      className={styles.cover}
                                      width={50}/>
                        </Item>
                    </Row>
                </Box>
            </Box>
        </Box>
    );
}

export default SkeletonServiceCard;
