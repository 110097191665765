import Button from "@material-ui/core/Button";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, {useEffect, useRef, useState} from "react";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import {Col, Row} from "reactstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {addReservationPrice} from "../../reducer";
import {outputHtPrice} from "../../tools/Tools";

function ContactAmountInfos({isPaid}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {currencySymbol, send, process, service} = useSelector(state => state.toContact);
    const isMounted = useRef(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [ttcArtist, setTTCArtist] = useState(0);
    const [serviceFee, setServiceFee] = useState(0);

    useEffect(() => {

        let _tmp = service?.contact_amount;
        let tmp = outputHtPrice(_tmp)
        setTTCArtist(tmp.artistFee)
        setServiceFee(tmp.serviceFee)
        setTotalAmount(_tmp);
        dispatch(addReservationPrice(_tmp))

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div>
            {service?.contact_amount && !isPaid && !send?
            <>
                <h6 className="d-flex justify-content-between align-items-center mb-3">
                    <span className="text-dark">Prise de contact</span>
                    <span className="badge badge-primary">{totalAmount + ' ' + currencySymbol}</span>
                </h6>
                <ul className="list-group mb-4">
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="text-left">
                            <pre className="my-0 pl-1 pr-1 rounded">
                                {service?.artist_name}&nbsp;
                                <small>({service?.title})</small>
                            </pre>
                            <small className="text-muted ml-1">Frais de contact</small>
                        </div>
                        <small className="text-red">{ttcArtist + ' ' + currencySymbol}</small>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="text-left">
                            <pre className="my-0 pl-1 pr-1 rounded">Frais de service</pre>
                        </div>
                        <small className="text-red">{serviceFee + ' ' + currencySymbol}</small>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="text-left">
                            <pre className="my-0 pl-1 pr-1 rounded">
                                Total&nbsp;
                                <small>TTC du contact</small>
                            </pre>
                        </div>
                        <strong className="text-red">{totalAmount + ' ' + currencySymbol}</strong>
                    </li>
                </ul>
                <hr/>
            </> : isPaid || send
                    ? <Row className="justify-content-end">
                        <Col className="ml-2 mr-2">
                            <CheckCircleIcon fontSize="large" sx={{mr: 1, mb: 1, color: 'lightGreen !important'}}/>
                            Le contact a été établi avec succès.
                        </Col>
                    </Row> : ''}
            <Box className="width-full">
                <CardContent>
                    <TextInfoContent
                        useStyles={useBlogTextInfoContentStyles}
                        overline={'Informations'}
                    />
                    <TextInfoContent
                        useStyles={useBlogTextInfoContentStyles}
                        heading={"A quoi sert une prise de contact ?"}
                        body={"La prise de contact permet de présenter à l'artiste tous les détails nécessaires pour l'événement que vous lui proposez. La réponse est sous 48 heures au maximum."}
                    />
                    <TextInfoContent
                        useStyles={useBlogTextInfoContentStyles}
                        heading={"Ce qui se passe après le contact ?"}
                        body={<>
                            {"• Une discussion avec l'artiste sera ensuite ouverte concernant la réservation de l'événement."}
                            <br/>
                            {"• La conversation porte généralement sur l'échange d'informations pour assurer le bon déroulement de l'événement."}
                            <br/>
                        </>}
                    />
                    <Button
                        variant="contained"
                        disabled={process}
                        onClick={() => navigate('/faq')}
                        endIcon={<ChevronRightIcon />}>
                        Voir plus
                    </Button>
                </CardContent>
            </Box>
        </div>
    );
}

export default ContactAmountInfos;
