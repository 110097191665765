function NotifyTitle(type, subject) {

    switch (true) {
        case type === "contact_card_notify" && subject === 'pending':
            return "Nouvelle prise de contact"
        case type === "contact_card_notify" && subject === 'accepted':
            return "Votre prise de contact a été accordée"
        case type === "contact_card_notify" && subject === 'declined':
            return "Prise de contact declinée"
        case type === "reservation_notify" && subject === 'accepted':
            return "Réservation acceptée"
        case type === "reservation_notify" && subject === 'proposition':
            return "Une proposition a été faite"
        case type === "reservation_notify" && subject === 'paid':
            return "Votre agenda bouge"
        case type === "reservation_notify" && subject === 'declined':
            return "La réservation a été annulée"
        default:
            return ""
    }
}

export default NotifyTitle;
