import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {Container, Navbar, NavbarText} from "reactstrap";
import {routes} from "routes";
import {getPathFromUrl, useWindowSize} from "../../tools/Tools";
import SearchBar from "./Search/SearchBar";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';


function NavbarCustom(props) {

    const color = "transparent";
    const navigate = useNavigate();
    const location = useLocation();
    const sidebarToggle = useRef();
    const width = useWindowSize();
    const [isDashPath, setIsDashPath] = useState(false);
    const role = useSelector(state => state.profile.role);
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);

    const getBrand = (_routes) => {
        const pathname = getPathFromUrl(window.location.href);
        let brandName = "Page non trouvée";

        _routes.forEach((prop) => {
            if (prop.component?.length && !(prop.requireOAuth && !auth && !role)) {
                prop.component.forEach((a) => {
                    if (pathname.includes(a.path?.split(":")[0])) {
                        brandName = a.desc || a.name;
                    }
                });
            } else if (pathname.includes(prop.path?.split(":")[0])) {
                brandName = prop.desc || prop.name;
            }
        });

        return brandName;
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };

    const handleClick = (e) => {
        let srcElement = e.srcElement
        if (srcElement?.id && srcElement.id.includes("no-sidebar-close-id")) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }

    useEffect(() => {
        if (
            width < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }

        const pList = ["artist-contact-demand", "artist-contacted-demand", "my-kantobiz-cards", "my-kantobiz-transfers"]
        const pathname = getPathFromUrl(window.location.href);
        setIsDashPath(pList.includes(pathname) && width > 500)

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [location, auth, auditor, width]);

    return (
        <Navbar
            color={color}
            // color={props.location?.pathname?.indexOf("full-screen-maps") !== -1 ? "dark" : color}
            expand="lg"
            className={"navbar-absolute fixed-top " + (color === "transparent" ? "navbar-transparent " : "")}
            // className={
            //     props.location?.pathname?.indexOf("full-screen-maps") !== -1
            //         ? "navbar-absolute fixed-top"
            //         : "navbar-absolute fixed-top " +
            //         (color === "transparent" ? "navbar-transparent " : "")
            // }
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            className="navbar-toggler"
                            onClick={() => openSidebar()}
                        >
                            <span className="navbar-toggler-bar bar1"/>
                            <span className="navbar-toggler-bar bar2"/>
                            <span className="navbar-toggler-bar bar3"/>
                        </button>
                    </div>
                    <div className="row">
                        <Tooltip title="Retour" placement="bottom">
                            <IconButton
                                onClick={() => navigate(-1)}
                                className="border-right mb-2 mt-2 border-bottom text-uppercase"
                                sx={{mr: 0.5, ml: 2, fontSize: width < 500 ? 12 : 15}}>
                                <KeyboardBackspaceIcon/>
                            </IconButton>
                        </Tooltip>
                        <NavbarText className="mt-2 ml-2">
                            <div role="presentation">
                                <Breadcrumbs aria-label="breadcrumb">
                                    {isDashPath
                                        ? <Typography variant="caption">
                                        Tableau de Bord
                                    </Typography>
                                        : null}
                                    <Typography>
                                        {getBrand(routes(role, auth))}
                                    </Typography>
                                </Breadcrumbs>
                            </div>

                        </NavbarText>
                    </div>
                </div>
                <SearchBar/>
            </Container>
        </Navbar>
    );
}

export default NavbarCustom;
