import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import calendar from "../../assets/img/eventConfirm.png";
import sendContact from "../../assets/img/sendContact.png";
import eventCreate from "../../assets/img/eventCreate.png";
import cx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {useGmailListItemStyles} from '@mui-treasury/styles/listItem/gmail';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import EventIcon from '@mui/icons-material/Event';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';


const useStyles = makeStyles(({breakpoints, spacing}) => ({
    root: {
        margin: 'auto',
        marginBottom: '20px',
        borderRadius: spacing(1.5), // 16px
        transition: '0.3s',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        position: 'relative',
        width: 400,
        height: 165,
        marginLeft: 'auto',
        overflow: 'initial',
        background: 'rgba(171,236,244,0.16)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: spacing(2),
        [breakpoints.up('md')]: {
            flexDirection: 'row',
            paddingTop: spacing(2),
        },
    },
    media: {
        height: 0,
        width: '88%',
        margin: 'auto',
        paddingBottom: '35%',
        borderRadius: spacing(1.5),
        position: 'relative',
        [breakpoints.up('md')]: {
            width: '70%',
            marginLeft: spacing(-2),
            marginTop: spacing(-0.5),
            transform: 'translateX(-2px)',
        },
        '&:after': {
            top: 0,
            left: 0,
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(to right bottom, #b4d4d2, #a8c9d3, #a6bcd1, #acadc8, #b49eb6)',
            borderRadius: spacing(1.5), // 16
            opacity: 0.3,
        },
    },
    content: {
        padding: 14,
    },
    cta: {
        marginTop: 24,
        textTransform: 'initial',
    },
}));

const contactArtist = "Contacter tous types d'artistes pour divers événements (show-cases, anniversaires ...)";
const plan = "Organiser un événement avec un artiste à la date et au lieu de votre choix.";
const agenda = "Simplifie la gestion de votre agenda, vous permettant ainsi de mieux vous organiser.";

function HomeServiceInfos() {
    const styles = useStyles();
    const {button: buttonStyles, ...contentStyles} = useBlogTextInfoContentStyles();
    const shadowStyles = useOverShadowStyles();
    const selectedStyles = useGmailListItemStyles({color: '#da3125'});
    const _styles = useGmailListItemStyles({});
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState(null);
    const [popOverDesc, setPopOverDesc] = useState('');

    const handleClick =
        (newPlacement, msg) =>
            (event) => {
                setPopOverDesc(msg);
                setAnchorEl(event.currentTarget);
                setOpen((prev) => placement !== newPlacement || !prev);
                setPlacement(newPlacement);
            };

    return (
        <div className="row justify-content-center">
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <Typography sx={{p: 2}}>
                                {popOverDesc}
                            </Typography>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <div className="col-md-4 p-0 d-none d-lg-block">
                <Card className={cx(styles.root, shadowStyles.root)}>
                    <CardMedia
                        className={styles.media}
                        image={sendContact}
                    />
                    <CardContent>
                        <TextInfoContent
                            classes={contentStyles}
                            overline={''}
                            heading={"Contacter un artiste"}
                            body={contactArtist}
                        />
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4 p-0 d-none d-lg-block">
                <Card className={cx(styles.root, shadowStyles.root)}>
                    <CardMedia
                        className={styles.media}
                        image={eventCreate}
                    />
                    <CardContent>
                        <TextInfoContent
                            classes={contentStyles}
                            overline={''}
                            heading={"Planifier un événement"}
                            body={plan}
                        />
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4 p-0 d-none d-lg-block">
                <Card className={cx(styles.root, shadowStyles.root)}>
                    <CardMedia
                        className={styles.media}
                        image={calendar}
                    />
                    <CardContent>
                        <TextInfoContent
                            classes={contentStyles}
                            overline={''}
                            heading={"Gérer vos agendas"}
                            body={agenda}
                        />
                    </CardContent>
                </Card>
            </div>
            <div className="col d-lg-none justify-content-center mt-3">
                <Box>
                    <CardContent>
                        <TextInfoContent
                            useStyles={useBlogTextInfoContentStyles}
                            overline={"Donner vie à vos rêves d'événements."}
                            heading={'Pourquoi KantoBiz ?'}
                        />
                        <Box sx={{borderRadius: 5, marginTop: '15px'}} bgcolor={'#ecebeb'}>
                            <List>
                                <ListItem
                                    classes={selectedStyles}
                                    button
                                    selected
                                >
                                    <SendToMobileIcon/>
                                    Contacter un artiste
                                    <span
                                        className={'MuiLabel-amount'}
                                        onClick={handleClick('top', contactArtist)}>
                                                <ArrowDropDownIcon/>
                                            </span>
                                </ListItem>
                                <ListItem
                                    classes={_styles}
                                    button
                                >
                                    <EditCalendarIcon/>
                                    Planifier un événement
                                    <span
                                        className={'MuiLabel-amount'}
                                        onClick={handleClick('top', plan)}>
                                                <ArrowDropDownIcon/>
                                            </span>
                                </ListItem>
                                <ListItem
                                    classes={_styles}
                                    button
                                >
                                    <EventIcon/>
                                    <b>Gérer vos agendas</b>
                                    <span
                                        className={'MuiLabel-amount'}
                                        onClick={handleClick('top', agenda)}>
                                                <ArrowDropDownIcon/>
                                            </span>
                                </ListItem>
                            </List>
                        </Box>
                    </CardContent>
                </Box>
            </div>
        </div>
    )
}

export default HomeServiceInfos;
