import React from "react";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useN03TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n03';


function NoDataList(text_message, btn) {

    return (
        <div className="vh-25 d-flex justify-content-center align-items-center">
            <div className="m-3 justify-content-center">
                <div className="row mb-2 justify-content-center">
                    {btn ? btn : <DoNotDisturbIcon sx={{color: 'silver!important'}}/>}
                </div>
                <div className="row justify-content-center">
                    <TextInfoContent
                        useStyles={useN03TextInfoContentStyles}
                        overline={text_message}
                    />
                </div>
            </div>
        </div>
    )
}

export default NoDataList;
