const initState = {
    tabIndex: 0,
    service: {},
    currency: '',
    currencySymbol: '',
    length: {},
};

const ContactCardsData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_RESERVATIONS_LENGTH":
            return {
                ...state,
                length: action.data
            };
        case "ADD_CONTACT_SERVICE":
            return {
                ...state,
                service: action.data
            };
        case "ADD_CONTACT_CURRENCY":
            return {
                ...state,
                currency: action.data
            };
        case "ADD_CONTACT_CURRENCY_SYMBOL":
            return {
                ...state,
                currencySymbol: action.data
            };
        case "ADD_RESERVATIONS_TABINDEX":
            return {
                ...state,
                tabIndex: action.data
            };
        default:
            return state;
    }
};

export default ContactCardsData;
