import Divider from "@material-ui/core/Divider";
import {Column, Item} from "@mui-treasury/components/flex";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useThemeXsInfoStyles} from "@mui-treasury/styles/info/themeXs";
import {useThemeSmInfoStyles} from '@mui-treasury/styles/info/themeSm';
import CelebrationIcon from '@mui/icons-material/Celebration';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import React, {useState, useEffect} from 'react';
import {useWindowSize} from "../../tools/Tools";

export default function ServiceDetails({service, currency}) {

    const width = useWindowSize();
    const transEx = service.travel_expenses
    const [events, setEvents] = useState([]);
    const [thematics, setThematics] = useState([]);

    const separateEventAndThematics = () => {
        let tmpX = [...service?.events]
        const groupedEvents = [...Array(Math.ceil(
            tmpX?.length / 3
        ))].map(_ => tmpX?.splice(0, 4))

        let tmpY = [...service?.thematics]
        const groupedThematics = [...Array(Math.ceil(
            tmpY?.length / 3
        ))].map(_ => tmpY?.splice(0, 4))

        setEvents(groupedEvents)
        setThematics(groupedThematics)
    }

    useEffect(() => {
        separateEventAndThematics()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Column gap={1.5} minWidth={200}>
            <Info useStyles={width < 500 ? useThemeXsInfoStyles : useThemeSmInfoStyles}>
                <InfoTitle className="mb-2">Événements</InfoTitle>

                {events?.map((value, index) =>
                    <InfoSubtitle key={"event_" + index}>
                        <strong>
                            <em>
                                {value.join(", ")}
                            </em>
                        </strong>
                    </InfoSubtitle>
                )}

                <InfoCaption>
                    <CelebrationIcon sx={{pb: 0.25}} fontSize="small"/>
                    &nbsp;•&nbsp;Ce sont les types d'événement prise en charge par l'artiste
                </InfoCaption>
            </Info>
            <Item>
                <Divider/>
            </Item>
            <Info useStyles={width < 500 ? useThemeXsInfoStyles : useThemeSmInfoStyles}>
                <InfoTitle className="mb-2">Styles musicaux</InfoTitle>
                {thematics?.map((value, index) =>
                    <InfoSubtitle key={"thematics_" + index}>
                        <strong>
                            <em>
                                {value.join(", ")}
                            </em>
                        </strong>
                    </InfoSubtitle>
                )}
            </Info>
            <Item>
                <Divider/>
            </Item>
            <Info useStyles={width < 500 ? useThemeXsInfoStyles : useThemeSmInfoStyles}>
                <InfoTitle className="mb-2">Localisation</InfoTitle>
                <InfoSubtitle>
                    <strong>
                        <em>
                            {service.cities[0] === 'all' ? service.country : service.cities.join(", ")}
                        </em>
                    </strong>
                </InfoSubtitle>
                <InfoCaption>
                <MyLocationIcon sx={{pb: 0.25}} fontSize="small"/>
                    &nbsp;•&nbsp;Cette fiche est valable uniquement pour cet ou ces emplacements.
                </InfoCaption>
            </Info>
            <Item>
                <Divider/>
            </Item>

            <Info useStyles={width < 500 ? useThemeXsInfoStyles : useThemeSmInfoStyles}>
                <InfoTitle className="mb-2">Déplacement</InfoTitle>
                <InfoSubtitle>
                    <strong>
                        <em>
                            {transEx?.from && transEx?.to
                                ? "Entre "
                                + transEx?.from.toString()
                                + ' et '
                                + transEx?.to.toString()
                                + currency : transEx?.from ? transEx?.from.toString()
                                    + currency : "*Offert"}
                        </em>
                    </strong>
                </InfoSubtitle>
                <InfoCaption>
                    <FlightTakeoffIcon sx={{pb: 0.25}} fontSize="small"/>
                    &nbsp;•&nbsp;Les frais de déplacement de l'artiste(s).
                </InfoCaption>
            </Info>
            <Item>
                <Divider/>
            </Item>
        </Column>
    );
}
