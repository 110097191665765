import Skeleton from "@mui/material/Skeleton";
import React from "react";

function SkeletonRectangular({mrTopRadius, height, width, className}) {

    return (
        mrTopRadius
            ? <Skeleton
                animation="wave"
                variant="rectangular"
                className={className}
                height={height} width={width}
                sx={{borderTopRadius: '5px', marginRight: '1px'}}/>
            : <Skeleton
                height={height}
                width={width}
                animation="wave"
                className={className}
                variant="rectangular"/>
    );
}

export default SkeletonRectangular;
