import AddIcon from "@mui/icons-material/Add";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import React, {useState} from "react";
import {useWindowSize} from "../../tools/Tools";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


function ServiceListHeader(props) {

    const width = useWindowSize();
    const [tabIndex, setTabIndex] = useState(0);
    const {setAdd, setTravelSet, setPolicySet, checked} = props

    const handleTabIndex = (event, index) => {
        setTabIndex(index);
    };

    return(
        <div hidden={checked} className="row justify-content-center width-full m-2 pb-3">
            <ToggleButtonGroup
                value={tabIndex}
                exclusive
                onChange={handleTabIndex}
                aria-label="text alignment"
            >
                <ToggleButton
                    value={0}
                    aria-label="left aligned"
                    onClick={() => {
                        setAdd(true)
                        setTabIndex(0)
                    }}>
                    <AddIcon sx={{color: 'blue!important', marginRight: width < 600 ? 0 : 1}} fontSize="small"/>
                    <span hidden={width < 600}>Créer une fiche</span>
                </ToggleButton>
                <ToggleButton
                    value={1}
                    aria-label="centered"
                    onClick={() => {
                        setTravelSet(true)
                        setTabIndex(1)
                    }}>
                    <AddBusinessIcon sx={{color: 'lightGreen!important', marginRight: width < 600 ? 0 : 1}}
                                     fontSize="small"/>
                    <span hidden={width < 600}>Transport</span>
                </ToggleButton>
                <ToggleButton
                    value={2}
                    aria-label="right aligned"
                    onClick={() => {
                        setPolicySet(true)
                        setTabIndex(2)
                    }}>
                    <PublishedWithChangesIcon sx={{marginRight: width < 600 ? 0 : 1}} fontSize="small"/>
                    <span hidden={width < 600}>Politique</span>
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    )
}

export default ServiceListHeader;
