// javascript plugin used to create scrollbars on Windows
import PerfectScrollbar from "perfect-scrollbar";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {Nav} from "reactstrap";
import logo from "../../assets/img/logo.png"
import AppleIcon from '@mui/icons-material/Apple';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ShopIcon from '@mui/icons-material/Shop';
import IconButton from '@material-ui/core/IconButton';
import {getPathFromUrl} from "../../tools/Tools";

let ps;

function Sidebar(props) {

    const navigate = useNavigate();
    const sidebar = useRef();
    const [routes, setRoutes] = useState([]);
    const role = useSelector(state => state.profile.role);
    const auth = useSelector(state => state.session.authenticated);
    // verifies if routeName is the one active (in browser input)
    const [show, setShow] = useState(false);
    const pathname = getPathFromUrl(window.location.href);


    const onDashClick = (e) => {
        e && e.preventDefault();
        setShow(!show)
    }

    const sideBarItemElement = (route, key, isSmall) => {

        const isActive = route.path?.split(":")[0] === pathname

        return (
            <li className={`mt-0 ${isSmall ? "" : "mb-2 pb-1"}`} key={key + route.path}>
                <NavLink
                    to={route.path}
                    className="nav-link"
                    style={{color: isActive ? "#b9f6ca" : null}}>
                    {typeof route.icon === 'string'
                        ? <i className={route.icon}/>
                        : <div className="row justify-content-start ml-1">
                            {route.icon(isActive)}<p className="ml-2">{route.name}</p>
                        </div>}

                    {typeof route.icon === 'string' ? <p>{route.name}</p> : null}
                </NavLink>
            </li>
        )
    }

    const expandIcon = () => {
        return show
            ? <ExpandLess fontSize="small" id="no-sidebar-close-id-4"/>
            : <ExpandMore fontSize="small" id="no-sidebar-close-id-5"/>
    }

    const SideBarProcess = (route, key) => {

        switch (true) {
            case Boolean(route.component?.length) && !(route.requireOAuth && !auth && !role):
                return (
                    <div key={key + route.name} className="mb-2 pb-1" id="no-sidebar-close-id-0">
                        <li onClick={(e) => onDashClick()} id="no-sidebar-close-id-1">
                            <NavLink className="nav-link" id="no-sidebar-close-id-#">
                                {typeof route.icon === 'string'
                                    ? <i className={route.icon} id="no-sidebar-close-id-2"/>
                                    : <div className="row justify-content-start ml-1" id="no-sidebar-close-id-6">
                                        {route.icon(false)}
                                        <p className="ml-2" id="no-sidebar-close-id-6">
                                            {route.name}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {expandIcon()}
                                        </p>
                                    </div>}
                                {typeof route.icon === 'string'
                                    ? <p id="no-sidebar-close-id-3">
                                        {route.name}
                                        {expandIcon()}
                                    </p>
                                    : null}
                            </NavLink>
                        </li>

                        <div aria-expanded="true" className={`collapse pl-3 navbar-collapse ${show ? "show" : ""}`}>
                            {route.component?.map((r, index) => sideBarItemElement(r, index, true))}
                        </div>
                    </div>
                )
            case !(route.requireOAuth && !auth && !role):
                return sideBarItemElement(route, key)
            default:
                return null
        }
    }

    const filterRoutes = (_routes) => {
        const pList = ["artist-contact-demand", "artist-contacted-demand", "my-kantobiz-cards", "my-kantobiz-transfers"]
        for (let i = 0; i < pList.length; i++) {
            if (pathname.includes(pList[i])) {
                setShow(true)
            }
        }

        let new_routes = _routes.filter((value) => !value.hidden)

        for (let row in new_routes) {
            if (new_routes[row]?.component?.length) {
                new_routes[row]['component'] = _routes[row]?.component?.filter((value) => !value.hidden)
            }
        }

        return new_routes
    }

    useEffect(() => {
        setRoutes(filterRoutes(props.routes))
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [props.routes]);

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }

        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });

    return (
        <div
            className="sidebar"
            data-color={props.bgColor}
            data-active-color={props.activeColor}
        >
            <div className="logo">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    href={null}
                    onClick={() => navigate('/')}
                    className="simple-text ml-2 logo-mini"
                >
                    <div className="logo-img">
                        <img src={logo} alt="react-logo"/>
                    </div>
                </a>
                <a
                    href="!#"
                    onClick={() => navigate('/')}
                    className="simple-text logo-normal"
                >
                    KantoBiz
                </a>
            </div>
            <div className="sidebar-wrapper pt-2" ref={sidebar}>
                <Nav>
                    {routes.map((prop, key) => {
                        return SideBarProcess(prop, key)
                    })}
                </Nav>

                {/*<hr/>*/}
                <div className="mt-3">
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a hidden
                       target="_blank"
                       rel="noreferrer"
                       id="go_to_apple_app_auditor"
                       href="https://apps.apple.com/us/app/kantobiz/id1606736780"/>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a hidden
                       target="_blank"
                       rel="noreferrer"
                       id="go_to_google_app_auditor"
                       href="https://play.google.com/store/apps/details?id=com.kantobiz"/>

                    <div className="row mt-4 justify-content-center">
                        <IconButton
                            className="border mr-1 text-light p-1"
                            onClick={() => document.getElementById("go_to_apple_app_auditor").click()}>
                            <AppleIcon fontSize="small"/>
                            <span className="paragraph text-left ml-2" style={{fontSize: 10}}>
                                <small className="d-block">
                                    Disponible sur&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </small>
                                <strong>App Store</strong>
                            </span>
                        </IconButton>
                        <IconButton
                            className="border ml-1 text-light p-1"
                            onClick={() => document.getElementById("go_to_google_app_auditor").click()}>
                            <ShopIcon fontSize="small"/>
                            <span className="paragraph text-left ml-2" style={{fontSize: 10}}>
                                <small className="d-block">
                                    Télécharger sur&nbsp;&nbsp;&nbsp;&nbsp;
                                </small>
                                <strong>Google Play</strong>
                            </span>
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
