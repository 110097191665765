import React, {useState} from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import CloseIcon from "@mui/icons-material/Close";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AddCardIcon from '@mui/icons-material/AddCard';
import {useDispatch, useSelector} from "react-redux";
import {setContactToChat} from "../../reducer";
import {reservationChatUpdate} from "../../tools/Tools";

function ChatNavigation({
                            closeDiscuss,
                            load,
                            updateInfo,
                            setMsgList,
                            msgList,
                            _type,
                            currency,
                            setLoading,
                            setPropose,
                            setAllowEvent,
                            setWarnDecline,
                            toScroll
                        }) {

    const dispatch = useDispatch();
    const [value, setValue] = useState('recents');
    const userId = useSelector(state => state.profile.userId);
    const contactToChat = useSelector(state => state.global.contactToChat);
    let isArtistConnected = parseInt(contactToChat?.reservation?.artist_owner_id) === parseInt(userId)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getNewInfo = async (reservation) => {
        setLoading(true)
        if (!updateInfo) {
            await setMsgList([...msgList,
                <ChatMsg avatar={<SmartToyIcon/>} messages={["Aucune action n'a été faite pour le moment"]}/>])
            setLoading(false)
            toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        } else {
            await setMsgList([...msgList,
                <ChatMsg avatar={<SmartToyIcon/>} messages={[reservationChatUpdate(reservation, currency, userId)]}/>])
            setLoading(false)
            toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        }
    }

    return (<div>

        <BottomNavigation showLabels value={value} onChange={handleChange}>
            {_type === 'auditor' && (updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) !== parseInt(userId)) ?
                <BottomNavigationAction
                    label="Accepter"
                    value="accept"
                    disabled={closeDiscuss || load}
                    sx={{color: 'lightGreen!important'}}
                    onClick={() => setAllowEvent(true)}
                    icon={<EventAvailableIcon sx={{color: 'lightGreen!important'}}/>}
                /> : updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) === parseInt(userId)
                    ? null : updateInfo?.object === 'accepted'
                        ? null : updateInfo?.object === 'paid'
                            ? null : _type === 'auditor' &&
                            <BottomNavigationAction
                                label="Accepter"
                                value="accept"
                                disabled={closeDiscuss || load}
                                sx={{color: 'lightGreen!important'}}
                                onClick={() => setAllowEvent(true)}
                                icon={<EventAvailableIcon sx={{color: 'lightGreen!important'}}/>}
                            />}
            {_type === 'book' && (updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) !== parseInt(userId)) ?
                <BottomNavigationAction
                    label="Accepter"
                    value="accept"
                    disabled={closeDiscuss || load}
                    sx={{color: 'lightGreen!important'}}
                    onClick={() => setAllowEvent(true)}
                    icon={<EventAvailableIcon sx={{color: 'lightGreen!important'}}/>}
                /> : _type === 'book' && updateInfo?.object !== 'proposition'
                    ? null : updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) === parseInt(userId)
                        ? null : _type === 'book' &&
                        <BottomNavigationAction
                            label="Accepter"
                            value="accept"
                            disabled={closeDiscuss || load}
                            sx={{color: 'lightGreen!important'}}
                            onClick={() => setAllowEvent(true)}
                            icon={<EventAvailableIcon sx={{color: 'lightGreen!important'}}/>}
                        />}
            {_type === 'book'
                ? updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) !== parseInt(userId)
                    ? <BottomNavigationAction
                        label='Proposition'
                        value="proposition"
                        disabled={closeDiscuss || load}
                        onClick={() => setPropose(contactToChat?.reservation?.id)}
                        icon={<AutoAwesomeIcon/>}
                    /> : updateInfo?.object === 'accepted'
                        ? <BottomNavigationAction
                            label='Payment'
                            value="paiment"
                            disabled={closeDiscuss || load || contactToChat?.reservation?.status === 'declined'}
                            onClick={() => setAllowEvent(true)}
                            icon={<AddCardIcon/>}
                        /> : <BottomNavigationAction
                            label='Annuler'
                            value="decline"
                            disabled={closeDiscuss || load || contactToChat?.reservation?.status === 'declined'}
                            onClick={() => setWarnDecline(contactToChat?.reservation?.id)}
                            icon={<EventBusyIcon/>}
                        /> : updateInfo?.object === 'accepted'
                    ? null : updateInfo?.object === 'paid'
                        ? null : <BottomNavigationAction
                            label='Proposition'
                            value="proposition"
                            disabled={closeDiscuss || load}
                            onClick={() => setPropose(contactToChat?.reservation?.id)}
                            icon={<AutoAwesomeIcon/>}
                        />}
            {contactToChat?.reservation?.invoice && !isArtistConnected &&
                <BottomNavigationAction
                    label="Facture"
                    value="Invoice"
                    onClick={() => document.getElementById("download-invoice").click()}
                    icon={<VerticalAlignBottomOutlinedIcon/>}
                />}

            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href={contactToChat?.reservation?.invoice}
               rel="noreferrer"
               target="_blank"
               download
               id="download-invoice"
               hidden/>
            <BottomNavigationAction
                label="Information"
                value="Info"
                onClick={() => getNewInfo(contactToChat?.reservation)}
                disabled={closeDiscuss || load}
                icon={<PrivacyTipIcon/>}
            />
            <BottomNavigationAction
                onClick={() => dispatch(setContactToChat(null))}
                label="Fermer"
                value="close"
                disabled={load}
                icon={<CloseIcon/>}
            />
        </BottomNavigation>

    </div>);
}

export default ChatNavigation;
