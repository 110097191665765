import React from "react";

function PassWordHints() {

    return (
        <div>
            <br/>
            <em>{"• Minimum 1 majuscule\n"}</em>
            <br/>
            <em>{"• Minimum 1 minuscule\n"}</em>
            <br/>
            <em>{"• Minimum 1 chiffre\n"}</em>
            <br/>
            <em>{"• Minimum 1 caractère spéciaux (!@#$$%^&*...\n"}</em>
            <br/>
            <em>{"• Minimum 8 caractères.\n"}</em>
            <br/>
            <br/>
        </div>
    )
}

export default PassWordHints;
