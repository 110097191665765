function NotificationMsg(notify) {

    let type = notify?.type;
    let subject = notify?.subject;
    let isArtist = notify?.isArtist;

    switch (true) {
        case type === "contact_card_notify" && subject === 'pending':
            return {
                title: "Un auditeur Pro vous contact",
                message: "Un événement est en préparation, vous avez reçu une prise de contact pour une invitation. Veuillez jeter un œil à votre tableau de bord."
            };
        case type === "contact_card_notify" && subject === 'accepted':
            return {
                title: "L'artiste veut en parler",
                message: "L'artiste a accepté votre prise de contact. Veuillez ouvrir la discussion pour voir la suite."
            };
        case type === "contact_card_notify" && subject === 'declined' && isArtist:
            return {
                title: "L'auditeur annule la prise de contact",
                message: "L'auditeur est vraiment navré pour l'annulation soudain de la prise de contact."
            };
        case type === "contact_card_notify" && subject === 'declined' && !isArtist:
            return {
                title: "L'artiste a décliné le contact",
                message: "L'artiste(s) est vraiment navré de ne pas donner suite à votre prise de contact."
            };
        case type === "reservation_notify" && subject === 'accepted':
            return {
                title: "L'artiste accepte la réservation",
                message: "L'artiste accepte l'événement que vous avez proposé, Ouvrir la discussion pour en savoir plus."
            };
        case type === "reservation_notify" && subject === 'proposition':
            return {
                title: "Nouvelle proposition",
                message: "Une nouvelle proposition vous a été envoyée concernant un événement en préparation. Veuillez jeter un coup d'œil à votre message."
            };
        case type === "reservation_notify" && subject === 'paid':
            return {
                title: "Un événement a été ajouté à votre agenda",
                message: "Un événement a été ajouter a votre agenda. Veuillez verifier votre agenda."
            };
        case type === "reservation_notify" && subject === 'declined':
            return {
                title: "L'auditeur PRO annule l'événement",
                message: "L'auditeur PRO annule l'événement."
            };
        default:
            return {
                title: "Message inconnu",
                message: 'Veuillez contacter le servise admin.'
            };
    }
}

export default NotificationMsg;
