import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, {useState} from 'react';
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import {useSelector} from "react-redux";
import {useWindowSize} from "../../../tools/Tools";


function BankBtnHeaders({walletRef}) {

    const width = useWindowSize();
    const {isDev, countryOrangeMoneyAllowed, countryLog} = useSelector(state => state.global);
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabIndex = (event, index) => {
        setTabIndex(index);
    };

    const OrangeMoneyBtn = () => {
      return (
          <ToggleButton value={1} aria-label="centered" onClick={() => walletRef?.current?.toOrangeMoney()}>
              <SendToMobileIcon
                  sx={{color: 'lightGreen!important', marginRight: width < 600 ? 0 : 1}}
                  fontSize="small"/>
              <span>Orange Money</span>
          </ToggleButton>
      )
    }

    return (
        <div className="row justify-content-center width-full m-2 pb-3">
            <ToggleButtonGroup
                value={tabIndex}
                exclusive
                onChange={handleTabIndex}
                aria-label="text alignment"
            >

                <ToggleButton value={0} aria-label="left aligned" onClick={() => walletRef?.current?.toPayOut()}>
                    <AccountBalanceWalletIcon
                        sx={{color: 'blue!important', marginRight: width < 600 ? 0 : 1}}
                        fontSize="small"/>
                    <span>Retrait</span>
                </ToggleButton>

                {isDev
                    ? OrangeMoneyBtn()
                    : countryOrangeMoneyAllowed.includes(countryLog.toLowerCase())
                        ? OrangeMoneyBtn()
                        : null}

            </ToggleButtonGroup>
        </div>
    )
}

export default BankBtnHeaders;
