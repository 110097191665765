import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {FormControl, IconButton, InputLabel, MenuItem, Select} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {CardFooter, Col, Form, FormGroup, Row} from "reactstrap";
import {
    addArtistFormGoogleData,
    addArtistSignInArtistName,
    addArtistSignInEmail,
    addArtistSignInName,
    addArtistSignInPass,
    addArtistSignInRules,
    addArtistType
} from "../../reducer";

import {btnStyle, changeFields, ITextField, useWindowSize} from "../../tools/Tools";
import Privacy from "../Privacy";
import LoginGoogle from "./Google";

function ArtistForm() {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const [rules, setRules] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const artistFromData = useSelector(state => state.artistFromData);
    const artistTypes = useSelector(state => state.global.artistTypes);
    const auth = useSelector(state => state.session.authenticated);
    const profileData = useSelector(state => state.profile.profileData);

    const initGoogleConnexion = (gProfile) => {
        setDisabled(true);
        dispatch(addArtistSignInName(gProfile.name))
        dispatch(addArtistSignInEmail(gProfile.email))
        dispatch(addArtistFormGoogleData(gProfile))
    }

    const setProfileForm = () => {
        setDisabled(true);
        dispatch(addArtistSignInRules(true))
        dispatch(addArtistSignInName(profileData.name))
        dispatch(addArtistSignInEmail(profileData.email))
        dispatch(addArtistSignInPass("oqYwerrty11!"))
    }

    useEffect(() => {
        auth && setProfileForm()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auth]);

    return (
        <div className="justify-content-center pr-2">
            <Modal size="md" show={rules}>

                <div>
                    <h5 className="m-auto pl-3 pt-3 pr-3 text-center">
                        CONDITIONS GÉNÉRALES D'UTILISATION ET DE VENTE
                    </h5>
                    <hr/>
                </div>

                <div className="mb-4 pl-4 pr-4">
                    <Form>
                        <Col md="12">
                            <Privacy toModal/>
                        </Col>
                    </Form>
                </div>

                <CardFooter>
                    <Row className="justify-content-end">
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                aria-label="settings"
                                size="small"
                                sx={btnStyle('red')}
                                onClick={() => setRules(false)}>
                                <CloseIcon sx={{color: "red!important"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Accepter" placement="top">
                            <IconButton
                                size="small"
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => {
                                    changeFields(null, true, addArtistSignInRules, dispatch)
                                    setRules(false)
                                }}>
                                <small>Accepter&nbsp;</small>
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>
            <div className="text-center mb-3">
                <LoginGoogle callBack={initGoogleConnexion} />
            </div>
            <Row className="justify-content-center">
                <Col md="6">
                    <FormGroup>
                        {ITextField('Nom', null, artistFromData.name, (e) => changeFields(null, e, addArtistSignInName, dispatch), null, disabled)}
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        {ITextField('Email', null, artistFromData.email, (e) => changeFields(null, e, addArtistSignInEmail, dispatch), null, disabled)}
                    </FormGroup>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="6">
                    <FormGroup>
                        {ITextField("Votre nom d'artise", null, artistFromData.artist_name, (e) => changeFields(null, e, addArtistSignInArtistName, dispatch))}
                    </FormGroup>
                </Col>
                <Col md="6">
                    {ITextField('Mot de passe', 'password', artistFromData.password, (e) => changeFields(null, e, addArtistSignInPass, dispatch), null, disabled || Object.keys(artistFromData.googleData)?.length !== 0)}
                </Col>
            </Row>
            <FormGroup className={width < 500 ? "mt-3" : " "}>
                <FormControl size="small" variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">Quelle genre d'artiste que êtes-vous ?</InputLabel>
                    <Select id="demo-simple-select-filled"
                            value={artistFromData.user_type}
                            onChange={(e) => changeFields(null, e, addArtistType, dispatch)}
                    >
                        {artistTypes.map((val, index) =>
                            <MenuItem key={index} value={val.value}>{val.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </FormGroup>
            <div className="material-switch mb-3 text-left">
                <input type="checkbox"
                       className="cursor-pointer"
                       onChange={() => changeFields(null, !artistFromData.rules, addArtistSignInRules, dispatch)}
                       checked={artistFromData.rules}/>
                <small onClick={() => setRules(true)}
                       className="cursor-pointer border-bottom ml-1 text-muted">
                    J'ai lu et j'accepte les conditions d'utilisation.
                </small>
            </div>
        </div>
    );
}

export default ArtistForm;
