const initState = {
    credentials: {},
};

const RegisterCredentials = (state = initState, action) => {
    switch (action.type) {
        case "AUTH_CREDENTIALS_RETURN":
            return {
                ...state,
                credentials: action.data
            };
        default:
            return state;
    }
};

export default RegisterCredentials;
