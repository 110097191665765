import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useChatzInfoStyles} from "@mui-treasury/styles/info/chatz";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Row} from "reactstrap";
import {sessionService} from "redux-react-session";
import {addArtistFormGoogleData, addAuthCredentials, configAxios} from "../reducer";
import IStepper from "../tools/IStepper";
import {
    checkErrorMessage, checkUnit,
    cleanAuthData,
    deleteNullInObj,
    dispatchService,
    fetchUserData,
    fillInBodyForm,
    generateBodyFormOfGallery,
    registerValidation
} from "../tools/Tools";
import ArtistForm from "./auth/ArtistForm";
import Validation from "./auth/Validation";
import ServiceDetailForm from "./services/ServiceDetailForm";
import ServiceInfoForm from "./services/ServiceInfoForm";


function NewArtist({toClose, setProcess}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const role = useSelector(state => state.profile.role);
    // const socket = useSelector(state => state.global.socket);
    const serviceForm = useSelector(state => state.serviceForm);
    const artistFrom = useSelector(state => state.artistFromData);
    const ipAddress = useSelector(state => state.global.ipAddress);
    const profileData = useSelector(state => state.profile.profileData);
    const auditor = useSelector(state => state.axios.auditor);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const auth = useSelector(state => state.session.authenticated);
    const globalCurrencyCode = useSelector(state => state.global.globalCurrencyCode);

    const isValidArtistForm = () => {
        let validator = registerValidation(artistFrom.name, artistFrom.email, artistFrom.password, artistFrom.rules, false)
        if (validator['error']) {
            toast.error(validator['message']);
            return true
        } else if (!artistFrom.user_type) {
            toast.error("Quel genre d'artiste êtes-vous ?");
            return true
        } else if (!artistFrom.artist_name) {
            toast.error("Quel est votre nom d'artiste");
            return true
        }
        return false
    }

    const isValidServiceInfoForm = () => {
        if (!serviceForm.title) {
            toast.error("Titre est requis pour une prestation");
            return true
        } else if (!serviceForm.number_of_artists) {
            toast.error("Le nombre d'artiste minimum est de 1");
            return true
        } else if (serviceForm.thematics?.length <= 0) {
            toast.error("Veuillez choisir au moins un genre musical");
            return true
        }
        return false
    }

    const isValidServiceDetailForm = async () => {
        if (!serviceForm.price) {
            toast.error("Le montant minimum de votre fiche est requis");
            return true
        } else if (serviceForm.events?.length <= 1) {
            toast.error("veuillez choisir au moins une deux événements");
            return true
        } else if (serviceForm.galleries?.length === 0) {
            toast.error("veuillez choisir au moins une image");
            return true
        } else if (!serviceForm.duration_of_the_service) {
            toast.error("Veuillez rensigner la durée votre prestation");
            return true
        }

        if (serviceForm.duration_of_the_service) {
            let msg = "La durée d'une prestation artistique ne doit pas dépasser 5 heures, soit pas plus de 300 minutes, car la santé de l'artiste nous est primordiale."
            let _unit = checkUnit(serviceForm.unit_duration_of_the_service);
            if (_unit === 'hours') {
                if (serviceForm.duration_of_the_service > 5) {
                    toast(msg)
                    return true
                }
            } else {
                if (serviceForm.duration_of_the_service > 300) {
                    toast(msg)
                    return true
                }
            }
        }

        return auth ? auditorToArtist() : addNewArtist();
    }

    const auditorToArtist = () => {
        setProcess(true)
        return auditor.put("users/update_user_to/" + artistFrom.user_type).then(() => {
            let data = deleteNullInObj(dispatchService(serviceForm, false))
            let bodyFormData = fillInBodyForm(generateBodyFormOfGallery(new FormData(), serviceForm.galleries), data)
            return auditorForm.post("artist_services/newService", bodyFormData).then(async (resp) => {
                let tData = {...profileData}
                tData['artist_name'] = artistFrom.artist_name
                const bodyFormData = fillInBodyForm(new FormData(), tData);
                return auditorForm.put("profiles/updateProfile", bodyFormData).then(async resp => {
                    navigate(0)
                }).catch(async error => {
                    await setProcess(false)
                    toast.error(checkErrorMessage(error).message);
                    return true
                });
            }).catch(async (error) => {
                await setProcess(false)
                toast.error(checkErrorMessage(error).message)
                return true
            })
        }).catch(async error => {
            await setProcess(false)
            let message = checkErrorMessage(error).message;
            if (message === "You are already artist right now")
                toast.success("Vous artiste maintenant")
            else toast.error(message);
            return true
        })
    };

    const addNewArtist = async () => {
        await setProcess(true)
        let urlLink = 'users/register';

        let data = deleteNullInObj(dispatchService(serviceForm, false))

        let bodyFormData = new FormData()
        bodyFormData = generateBodyFormOfGallery(bodyFormData, serviceForm.galleries);

        if (Object.keys(artistFrom.googleData)?.length) {
            urlLink = 'users/gCallback'
            bodyFormData.append('google_data', JSON.stringify(artistFrom.googleData));
        } else {
            bodyFormData.append('name', artistFrom.name);
            bodyFormData.append('email', artistFrom.email);
            bodyFormData.append('password', artistFrom.password)
        }

        bodyFormData.append('currency', globalCurrencyCode);
        bodyFormData.append('user_type', artistFrom.user_type);
        bodyFormData.append('artist_name', artistFrom.artist_name);

        delete data['galleries'];
        bodyFormData.append('services', JSON.stringify(data));

        return await auditorForm.post(urlLink, bodyFormData).then(async (response) => {
            await setEmail(artistFrom.email)
            if (Object.keys(artistFrom.googleData)?.length !== 0) {
                const {token} = response.data;
                await dispatch(configAxios({ipAddress: ipAddress, token: token}))
                await sessionService.saveSession({token});
                await sessionService.saveUser(data);
                await cleanAuthData(dispatch)
                await dispatch(addArtistFormGoogleData({}))
                // await fetchUserData(token, dispatch, socket);
                await fetchUserData(token, dispatch);
                toast('Bienvenue, vous êtes connecté', {
                    icon: '👏',
                });
                await toClose()
                await setProcess(false)
                navigate('/')
            } else {
                dispatch(addAuthCredentials(response.data));
                toast.success('Inscription validé, veuillez maintenant valider votre compte.')
            }
            return false
        }).catch(async (error) => {
            await setProcess(false)
            let message = checkErrorMessage(error).message;
            message !== 'You need Password' && toast.error(message);
            message === 'You need Password' && toast.error("Adresse email existant");
            return true
        })
    }

    const steps = Object.keys(artistFrom.googleData)?.length !== 0
        ? [{
            title: <Info useStyles={useChatzInfoStyles}>
                <InfoTitle>Formulaire d'inscription</InfoTitle>
                <InfoSubtitle>Quel type d'artiste représentez-vous ?</InfoSubtitle>
            </Info>,
            description: '',
            component: <ArtistForm/>,
            isValid: isValidArtistForm
        }, {
            title: <Info useStyles={useChatzInfoStyles}>
                <InfoTitle>Fiche KantoBiz</InfoTitle>
                <InfoSubtitle>Créer votre première fiche de prestation</InfoSubtitle>
                <InfoCaption>C'est quoi une fiche prestation ?</InfoCaption>
            </Info>,
            description: '',
            component: <ServiceInfoForm newArtist/>,
            isValid: isValidServiceInfoForm
        }, {
            title: <Info useStyles={useChatzInfoStyles}>
                <InfoTitle>Details de la fiche</InfoTitle>
                <InfoSubtitle>Personnaliser votre fiche KantoBiz</InfoSubtitle>
            </Info>,
            description: '',
            component: <ServiceDetailForm/>,
            isValid: isValidServiceDetailForm
        }]
        : [{
            title: <Info useStyles={useChatzInfoStyles}>
                <InfoTitle>Formulaire d'inscription</InfoTitle>
                <InfoSubtitle>Quel type d'artiste représentez-vous ?</InfoSubtitle>
            </Info>,
            description: '',
            component: <ArtistForm/>,
            isValid: isValidArtistForm
        }, {
            title: <Info useStyles={useChatzInfoStyles}>
                <InfoTitle>Fiche KantoBiz</InfoTitle>
                <InfoSubtitle>Créer votre première fiche de prestation</InfoSubtitle>
                <InfoCaption>C'est quoi une fiche KantoBiz ? Cicquer ici</InfoCaption>
            </Info>,
            description: '',
            component: <ServiceInfoForm newArtist/>,
            isValid: isValidServiceInfoForm
        }, {
            title: <Info useStyles={useChatzInfoStyles}>
                <InfoTitle>Details de la fiche</InfoTitle>
                <InfoSubtitle>Personnaliser votre fiche KantoBiz</InfoSubtitle>
            </Info>,
            description: '',
            component: <ServiceDetailForm newArtist/>,
            isValid: isValidServiceDetailForm
        }, {
            title: <Info useStyles={useChatzInfoStyles}>
                <InfoTitle>Validation de compte</InfoTitle>
                <InfoSubtitle>Vérification de votre email.</InfoSubtitle>
            </Info>,
            description: '',
            component: <Validation email={email} closeModal={async () => {
                await toClose()
                await setProcess(false)
                navigate('/')
            }
            }/>,
        }]

    useEffect(() => {
        auth && role !== 'professional_auditor' && navigate('/')
    }, [auth, role, navigate])

    useEffect(() => {
        //
    }, [])

    return (
        <Row className="justify-content-center card-flex-row pt-3 bg-transparent border-0 overflow-auto"
             style={{maxHeight: "84vh"}}>
            <IStepper auth steps={steps}/>
        </Row>
    );
}

export default NewArtist;
