import RemoveIcon from '@mui/icons-material/Remove';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import {IconButton, TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addServiceFormTravelExp} from "../../reducer";
import {HtmlTooltip, setFrom, setTo} from "../../tools/Tools";

function TravelExpense() {

    const dispatch = useDispatch();
    const [activeExpenseTo, setActiveExpenseTo] = useState(false);
    const [activeExpenseFrom, setActiveExpenseFrom] = useState(false);
    const {travel_expenses} = useSelector(state => state.serviceForm);
    const conditions = useSelector(state => state.profile.conditions);
    const currencySymbol = useSelector(state => state.global.globalCurrencySymbol);

    return (
        <div className="row justify-content-center m-2">

            <div className="col-10 justify-content-center row">
                <em>
                    • C'est quoi un frais de transport ?&nbsp;
                    <IconButton sx={{p: '2px'}} aria-label="menu" small>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <React.Fragment>
                                    <em>{"• Il s'agit de vos frais de déplacement par défaut applicable à toute votre fiche prestation (Fiche KantoBiz) si ce dernier n'est pas encore personnalisé. "}</em>
                                    <br/>
                                    <em>{"• Vous pouvez modifier le frais de transport spécifique d'une fiche prestation (Fiche KantoBiz)."}</em>
                                    <br/>
                                    <em>{"• Le frais de transport général par défaut est de 0 et personnalisable en fonction de vos estimations."}</em>
                                </React.Fragment>
                            }
                        >
                            <StickyNote2Icon sx={{marginBottom: 1}}/>
                        </HtmlTooltip>
                    </IconButton>
                </em>
                <em>
                    • Savoir comment ça marche ?&nbsp;
                    <IconButton sx={{p: '2px'}} aria-label="menu" small>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <React.Fragment>
                                    <em>{"• Ce prix sera ajouté à chaque réservation. "}</em>
                                    <br/>
                                    <em>{"• Si le montant n'est pas fixe. l'auditeur prend un prix dans la fourchette."}</em>
                                    <br/>
                                    <em>{"• Le montant par défaut si fourchette est la moitié."}</em>
                                </React.Fragment>
                            }
                        >
                            <StickyNote2Icon sx={{marginBottom: 1}}/>
                        </HtmlTooltip>
                    </IconButton>
                </em>
            </div>

            <div className="col-10 justify-content-center row m-2 pt-2">
                {activeExpenseFrom && !activeExpenseTo
                    ? <TextField
                        rows={4}
                        type="number"
                        variant="filled"
                        value={travel_expenses.from}
                        label="Frais de transport"
                        id="filled-start-adornment"
                        placeholder={"Tapez ici votre frais de transport"}
                        helperText={"• Cette valeur s'applique à chaque nouveau(x) fiche prestation (Fiche KantoBiz). Cependant vous pouvez aussi " +
                            "personnaliser le frais de chaque fiche prestation (Fiche KantoBiz)."}
                        sx={{m: 1, width: '25ch'}}
                        onChange={(e) => setFrom(e, dispatch)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                        }}
                    />
                    : activeExpenseTo && !activeExpenseFrom ? <div className="row">
                        <TextField
                            rows={4}
                            type="number"
                            variant="filled"
                            value={travel_expenses.from}
                            label="Frais de transport minimum"
                            id="filled-start-adornment"
                            placeholder={"Tapez ici votre frais de transport minimum"}
                            sx={{m: 1, width: '25ch'}}
                            onChange={(e) => setFrom(e, dispatch)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                            }}
                        />
                        <TextField
                            rows={4}
                            type="number"
                            variant="filled"
                            value={travel_expenses.to}
                            label="Frais de transport maximum"
                            id="filled-start-adornment"
                            placeholder={"Tapez ici votre frais de transport minimum"}
                            sx={{m: 1, width: '25ch'}}
                            onChange={(e) => setTo(e, dispatch, travel_expenses)}
                            helperText={"• Par défaut, pendant la réservation\n, le frais de transport sera le minimum " +
                                "de la fourchette. Ensuite l'auditeur qui vous réserve pourrait ajuster le frais."}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                            }}
                        />
                    </div> : null}
            </div>

            {activeExpenseFrom || activeExpenseTo ?
                <div className="col-10 justify-content-center row m-2 pt-2">
                    <button
                        onClick={async () => {
                            setActiveExpenseTo(false)
                            setActiveExpenseFrom(false)
                            await dispatch(addServiceFormTravelExp({'from': 0, 'to': 0}))
                        }}
                        className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                        Annuler et révenir&nbsp;<RemoveIcon fontSize="small" sx={{color: "white!important"}}/>
                    </button>
                </div> : null}

            {!activeExpenseFrom && !activeExpenseTo &&
                <div className="col-10 justify-content-center row m-2 pt-2">
                    <button
                        onClick={() => {
                            setActiveExpenseTo(false)
                            setActiveExpenseFrom(true)
                        }}
                        className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                        Fixer un prix&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g strokeWidth="1" fill="#ffffff" stroke="#ffffff">
                                <path fill="none" stroke="#ffffff" strokeMiterlimit="10" strokeLinejoin="miter"
                                      strokeLinecap="butt" d="M11.62 5.62l-4.87-4.87h-6v6l8.25 8.25 3.51-3.51"/>
                                <path fill="none" stroke="#ffffff" strokeLinecap="square" strokeMiterlimit="10"
                                      strokeLinejoin="miter" d="M4 3a1 1 0 1 0 0 2 1 1 0 1 0 0-2z"/>
                                <path fill="none" strokeLinecap="square" strokeMiterlimit="10" strokeLinejoin="miter"
                                      d="M12.5 5.5a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"/>
                                <path fill="none" strokeLinecap="square" strokeMiterlimit="10" strokeLinejoin="miter"
                                      d="M12.5 7.25v1.25h1.25"/>
                            </g>
                        </svg>
                    </button>
                </div>}

            {!activeExpenseTo && !activeExpenseFrom &&
                <div className="col-10 justify-content-center row m-2">
                    <button
                        onClick={() => {
                            setActiveExpenseTo(true)
                            setActiveExpenseFrom(false)
                        }}
                        className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                        Faire une fourchette de prix&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g strokeWidth="1" fill="#ffffff" stroke="#ffffff">
                                <path
                                    d="M9.4 14.79l3.74-3.74a1.32 1.32 0 0 0 0-1.87l-6.54-6.54h-5.61v5.61l6.54 6.54a1.32 1.32 0 0 0 1.87 0z"
                                    fill="none" stroke="#ffffff" strokeLinecap="square" strokeMiterlimit="10"/>
                                <path fill="none" strokeLinecap="square" strokeMiterlimit="10"
                                      d="M14.52 7.59l-6.93-6.93h-5.61"/>
                                <path fill="none" strokeLinecap="square" strokeMiterlimit="10"
                                      d="M4.29 4.62a1.32 1.32 0 1 0 0 2.64 1.32 1.32 0 1 0 0-2.64z"/>
                            </g>
                        </svg>
                    </button>
                </div>}

            <div className="col-10 justify-content-center row mt-4">
                {!conditions?.travel_expenses.to && !conditions?.travel_expenses.from
                    ? <small>
                        • Votre frais de transport actuel est <strong>GRATUIT</strong>
                    </small>
                    : <small>
                        • Votre frais de transport actuel est de&nbsp;
                        <strong>
                            {conditions?.travel_expenses.to
                                ? conditions?.travel_expenses.from + currencySymbol
                                + " jusqu'à "
                                + conditions?.travel_expenses.to + currencySymbol
                                : conditions?.travel_expenses.from + currencySymbol}
                        </strong>
                    </small>}
            </div>

        </div>
    );
};

export default TravelExpense;
