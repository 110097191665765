import React, {useState} from "react";
import toast from "react-hot-toast";
import Tooltip from '@mui/material/Tooltip';
import {useDispatch, useSelector} from "react-redux";
import {Row, Input, InputGroupAddon, InputGroupText, InputGroup} from "reactstrap";
import {addProfileData} from "../../../reducer";
import {changeFields, checkErrorMessage, fillInBodyForm} from "../../../tools/Tools";
import InfoIcon from "@mui/icons-material/Info";

function EditProfileName(props) {

    const {closeModal} = props;
    const dispatch = useDispatch();
    const role = useSelector(state => state.profile.role);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const profileData = useSelector(state => state.profile.profileData);
    const [name, setName] = useState(profileData.name);
    const [artistName, setArtistName] = useState(profileData.artist_name);

    const upDateProfileInfo = async () => {
        let tData = {...profileData}
        tData['name'] = name
        tData['artist_name'] = artistName
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm.put("profiles/updateProfile", bodyFormData).then(async resp => {
            await dispatch(addProfileData(resp.data));
            toast.success("Votre pseudo a été mis à jour.");
            closeModal()
        }).catch(error => {
            toast.error(checkErrorMessage(error).message);
        });
    }

    const validate = () => {
        if (!name) {
            toast.error("Votre nom est nécessaire.")
        } else upDateProfileInfo().then(r => null)
    }

    return (
        <div>
            <Row className="justify-content-center">
                {role !== 'professional_auditor' &&
                    <Tooltip title="Votre pseudo" placement="top">
                        <InfoIcon sx={{margin: 1, color: "gray!important", cursor: "pointer"}}/>
                    </Tooltip>}
                <InputGroup className="no-border" style={{width: role !== 'professional_auditor' ? 215 : 250}}>
                    <Input
                        type="text"
                        value={name}
                        onChange={(e) => changeFields(setName, e)}
                        placeholder={name}/>
                    {role === 'professional_auditor' &&
                    <InputGroupAddon addonType="append">
                        <InputGroupText onClick={() => closeModal()}>
                            <Tooltip title="Annuler" placement="top">
                                <i className="nc-icon nc-simple-remove cursor-pointer text-danger ml-2"/>
                            </Tooltip>
                        </InputGroupText>
                        <InputGroupText onClick={() => validate()}>
                            <Tooltip title="Enregister" placement="top">
                                <i className="nc-icon nc-send cursor-pointer text-success ml-2"/>
                            </Tooltip>
                        </InputGroupText>
                    </InputGroupAddon>}
                </InputGroup>
            </Row>
            {role !== 'professional_auditor' &&
                <Row className="justify-content-center">
                    <Tooltip title="Votre nom d'artiste" placement="top">
                        <InfoIcon sx={{margin: 1, color: "gray!important", cursor: "pointer"}}/>
                    </Tooltip>
                    <InputGroup className="no-border" style={{width: role !== 'professional_auditor' ? 215 : 250}}>
                        <Input
                            type="text"
                            value={artistName}
                            onChange={(e) => changeFields(setArtistName, e)}
                            placeholder={artistName}/>
                        <InputGroupAddon addonType="append">
                            <InputGroupText onClick={() => closeModal()}>
                                <Tooltip title="Annuler" placement="top">
                                    <i className="nc-icon nc-simple-remove cursor-pointer text-danger ml-2"/>
                                </Tooltip>
                            </InputGroupText>
                            <InputGroupText onClick={() => validate()}>
                                <Tooltip title="Enregister" placement="top">
                                    <i className="nc-icon nc-send cursor-pointer text-success ml-2"/>
                                </Tooltip>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Row>}
        </div>
    );
}

export default EditProfileName;
