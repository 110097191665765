import NorthIcon from "@mui/icons-material/North";
import SendIcon from "@mui/icons-material/Send";
import SouthIcon from "@mui/icons-material/South";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "reactstrap";
import {switchContactFormProcess} from "../../../reducer";
import {cardValidation, checkContactForm, checkErrorMessage, validationNameAndLastName} from "../../../tools/Tools";
import OrangeMoney from "../OrangeMoney";
import AddEvent from "./AddEvent";
import AddLocation from "./AddLocation";
import AddPaymentMethods from "./AddPaymentMethods";
import AddSuggestEventAmount from "./AddSuggestEventAmount";
import CustomContactStepper from "./CustomContactStepper";

function StepWithPayments({isValid, sendContactCard}) {

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const contactForm = useSelector(state => state.contactForm);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [orange, setOrange] = useState(false);
    const [orangeResponse, setOrangeResponse] = useState({});
    const {globalCurrencyCode, paymentMethod} = useSelector(state => state.global);
    const auditor = useSelector(state => state.axios.auditor);
    const {email} = useSelector(state => state.profile.profileData)
    const auth = useSelector(state => state.session.authenticated);
    const {service, tabIndex, currencySymbol, process} = useSelector(state => state.toContact);

    const checkAmount = async () => {
        let tmp = parseFloat(contactForm.amountProposed) >= parseFloat(service.price)
        if (!tmp)
            toast.error(
                "Le montant que vous proposée est inferieur à " + service.price.toString() + currencySymbol + '.'
            )
        else {
            await handleNext()
        }
    };

    const sendBtn = () => {
        return (
            <button
                onClick={() => validationNameAndLastName(isValid, setLoading, validation)}
                disabled={process|| loading}
                className="mr-1 mb-1 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                {process || loading
                    ? 'Veuillez patienter ...'
                    : 'Contacter ' + service.artist_name}&nbsp;
                {process || loading
                    ? <Spinner type="grow" size="sm"/>
                    : <SendIcon
                        fontSize="small"
                        sx={{color: "white!important", paddingBottom: 0.3}}/>}
            </button>
        )
    }

    const orangeMoney = () => {
        dispatch(switchContactFormProcess(false))
        let data = {"amount": service.contact_amount, "currency": globalCurrencyCode}
        auditor.post("payments/orange_money", data).then(async (resp) => {
            await setOrangeResponse(resp.data)
            await setOrange(true)
        }).catch((error) => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    const validation = () => {
        if (!auth) {
            document.getElementById("login_button").click()
        } else {
            dispatch(switchContactFormProcess(true))
            if (tabIndex === 0) {

                if (paymentMethod === 'paypal') {
                    let tDate = new Date(
                        contactForm.eventDate.getTime() - contactForm.eventDate.getTimezoneOffset() * 60000,
                    )
                        .toISOString()
                        .slice(0, -1);
                    let contactCard = checkContactForm(contactForm, tDate, service, contactForm.amountProposed, email);
                    localStorage.setItem('contactForm', JSON.stringify(contactCard));
                }

                cardValidation(stripe, elements).then(token => {
                    if (token) {
                        sendContactCard(token, null);
                    } else {
                        dispatch(switchContactFormProcess(false))
                    }
                }).catch(error => {
                    dispatch(switchContactFormProcess(false))
                })
            } else {
                dispatch(switchContactFormProcess(false))
                orangeMoney()
            }
        }
    }

    const handleBack = async () => {
        await setLoading(true)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        await setLoading(false)
    };

    const backBtn = () => {
        return (
            <button
                onClick={() => handleBack()}
                disabled={process || loading}
                className="pl-2 pr-2 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                <NorthIcon
                    fontSize="small"
                    sx={{color: "white!important", paddingBottom: 0.3}}/>
            </button>
        )
    }

    const handleNext = async () => {
        await setLoading(true)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        await setLoading(false)
    };

    const nextBtn = (toNext, disabled) => {
        return (
            <button onClick={() => toNext()}
                    disabled={disabled}
                    className="mt-1 mr-1 mb-1 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                Continuer&nbsp;
                {loading
                    ? <Spinner type="grow" size="sm"/>
                    : <SouthIcon fontSize="small" sx={{color: "white!important", paddingBottom: 0.3}}/>}
            </button>
        )
    }

    const steps = [
        {
            label: "Type d'événement",
            description: "Quel événement préparez-vous ?",
            input: <AddEvent/>,
            button: nextBtn(handleNext, !isValid.event),
        },
        {
            label: "Emplacement",
            description: "Où aura lieu cet événement ?",
            input: <AddLocation/>,
            button: nextBtn(handleNext, !(isValid.city && isValid.address && isValid.postalCode)),
        },
        {
            label: "Estimation",
            description: "Proposition à partir de " + service.price.toString() + ' ' + currencySymbol,
            input: <AddSuggestEventAmount/>,
            button: nextBtn(checkAmount, false),
        },
        {
            label: "Faire la prise de contact",
            input: <AddPaymentMethods/>,
        },
    ];

    return (
        <div>

            <Modal size="lg" show={orange}>
                <OrangeMoney
                    setOrange={setOrange}
                    orangeResponse={orangeResponse}
                    sendContactCard={sendContactCard}/>
            </Modal>

            <CustomContactStepper
                steps={steps}
                isValid={isValid}
                backBtn={backBtn}
                sendBtn={sendBtn}
                validation={validation}
                handleBack={handleBack}
                activeStep={activeStep}/>
        </div>
    )
}

export default StepWithPayments;
