import React, {useEffect, useRef} from "react";
import {Row} from "reactstrap";

function NotFound() {

    const isMounted = useRef(false);

    useEffect(() => {
        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div className="content pt-3">
            <Row className="justify-content-center ml-2 mr-2 mt-2 bg-transparent border-0 overflow-auto"
                 style={{height: "80vh"}}>
                <div className="center mt-2">
                    <div className="t-circle">
                    </div>
                    <div className="line">
                    </div>
                    <div className="banner text-center">
                        <h1 className="text-white pt-3">
                            Oops!
                        </h1>
                    </div>
                    <h5 className="mt-4">
                        Erreur 404 page non trouvée
                    </h5>
                </div>
            </Row>
        </div>
    );
}

export default NotFound;
