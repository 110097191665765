import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Button from "@mui/material/Button";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import {useNavigate} from "react-router-dom";

function ContactCardInfos({bookedList, ContactCardToChat}) {

    const navigate = useNavigate();
    const showBook = ContactCardToChat?.reservation

    return (
        <CardContent className="ml-4 mr-4 mb-5 mt-1 p-4">
            <TextInfoContent
                useStyles={useBlogTextInfoContentStyles}
                overline={'Informations'}
                heading={'Quoi pour la suite ?'}
                body={
                    bookedList ?
                        <>
                            <br/>
                            <span className="text-bold-300">
                                            • La discussion sur l'événement sera ouvert après accord de l'artiste.
                                        </span>
                            <br/>
                            <br/>
                            <span className="text-bold-300">
                                            • Ce chat servira d'outils pour avoir des informations concernant l'événement en cours.
                                        </span>
                        </> :
                        <>
                            <br/>
                            <span className="text-bold-300">
                                        {['pending', 'declined'].includes(showBook?.status)
                                            ? "• Une discussion sera ouvert après votre accord. Tout échanges avec l'auditeur se passera dans le chat."
                                            : "• Une discussion est ouvert concernant la réservation de l'auditeur"}
                                        </span>
                            <br/>
                            <br/>
                            <span className="text-bold-300">
                                        {['pending', 'declined'].includes(showBook?.status)
                                            ? "• Ce chat servira d'outils pour avoir des informations concernant l'événement en cours."
                                            : "• Vous pouvez voir quelques astuces concernant le chat."}
                                        </span>
                            <br/>
                        </>
                }
            />
            <Button
                variant="contained"
                onClick={() => navigate('/faq')}
                endIcon={<ChevronRightIcon />}>
                Voir plus
            </Button>
        </CardContent>
    )
}

export default ContactCardInfos;
