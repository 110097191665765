import Skeleton from "@mui/material/Skeleton";
import React from "react";

function SkeletonContact() {

    return (
        <Skeleton
            sx={{borderTopRadius: '5px', marginRight: '1px'}}
            animation="wave"
            variant="rectangular"/>
    );
}

export default SkeletonContact;
