import React, {useEffect} from 'react';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setContactToChat, setMsgTabIndex} from "../../reducer";
import {removePaymentSearchParams, useWindowSize} from "../../tools/Tools";
import ContactCardList from "../contactCards/ContactCardList";

function ArtistContacted() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const width = useWindowSize();
    const [searchParams, setSearchParams] = useSearchParams();
    const auth = useSelector(state => state.session.authenticated);
    const role = useSelector(state => state.profile.role);


    const checkPaypalPayment = async () => {
        let tmp = {}
        searchParams.forEach((value, key) => {
            tmp[key] = value
        });

        let {redirect_status} = tmp;

        const tmpContactToChat = JSON.parse(localStorage.getItem('contactToChat'));

        if (redirect_status === 'failed') {
            localStorage.removeItem('contactToChat');
            removePaymentSearchParams(searchParams, setSearchParams)
            toast.error("Il y a eu une erreur lors de la transaction, veuillez réessayer.")
        }

        if (tmpContactToChat) {
            dispatch(setMsgTabIndex('book'))
            dispatch(setContactToChat(tmpContactToChat))
            document.getElementById("open_discussion").click()
        }
    }

    useEffect(() => {

        role === '' && !auth && navigate('/')
        ![null, ''].includes(role) && checkPaypalPayment().then(r => null)

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auth, navigate, role])

    return (
        <div className={`content ${width <= 764 ? "pt-5 mx-2" : "pt-1 mx-5"}`}>
            <ContactCardList bookedList/>
        </div>
    )
}

export default ArtistContacted;
