import React, {useEffect, useState, useRef} from "react";
import RangeSlider from "react-bootstrap-range-slider";
import {useDispatch, useSelector} from "react-redux";
import {addContactFormEventProposedAmount, addReservationTravel} from "../../../reducer";
import {changeFields, IPriceField} from "../../../tools/Tools";
import {Col, FormGroup, Row} from "reactstrap";

function AddSuggestEventAmount() {

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const {amountProposed} = useSelector(state => state.contactForm);
    const [tmpTE, setTmpTE] = useState(0);
    const {service, currencySymbol} = useSelector(state => state.toContact);
    const transport = service.travel_expenses
    const isBalanced =
        (transport.from && transport.to) || (!transport.from && transport.to);

    useEffect(() => {

        if (!isMounted.current && isBalanced) {
            let _sum = transport.from + transport.to;
            let transportMiddleChoice = _sum / 2;
            changeFields(setTmpTE, transportMiddleChoice, addReservationTravel, dispatch)
        } else if (!isMounted.current && transport.from) {
            changeFields(setTmpTE, transport.from, addReservationTravel, dispatch)
        } else {
            changeFields(setTmpTE, 0, addReservationTravel, dispatch)
        }

        return () => {
            isMounted.current = true
        };
    }, [isBalanced, transport.from, transport.to, dispatch]);

    return (
        <Row>
            <Col md={12}>
                <FormGroup>
                    {IPriceField(
                        'Cachet de la prestation artistique',
                        amountProposed,
                        currencySymbol,
                        (e) => changeFields(null, e, addContactFormEventProposedAmount, dispatch),
                        isBalanced
                            ? "+ Frais de déplacement entre " + transport?.from + currencySymbol + " et " + transport?.to + currencySymbol
                            : transport?.from ? '+ Frais de déplacement ' + transport?.from + currencySymbol
                                : null)}
                </FormGroup>
                {isBalanced ? <hr/> : null}
            </Col>
            {isBalanced ?
                <Col md={12} className="mr-4 mt-0">
                    <Row className="justify-content-between">
                        <Col className="justify-content-start">
                            {transport.from + currencySymbol}
                        </Col>
                        <Col className="justify-content-end text-right">
                            {transport.to + currencySymbol}
                        </Col>
                    </Row>
                    <RangeSlider
                        value={tmpTE}
                        variant="success"
                        tooltipLabel={() => <p>{tmpTE + ' ' + currencySymbol}</p>}
                        min={transport.from}
                        max={transport.to}
                        tooltipPlacement="top"
                        onChange={e => changeFields(setTmpTE, Number(e.target.value), addReservationTravel, dispatch)}
                    />
                    <Row className="justify-content-between">
                        <Col className="justify-content-start">
                            <p className="text-muted">
                                + Déplacement : &nbsp;<strong>{tmpTE + currencySymbol}</strong>
                            </p>
                        </Col>
                    </Row>
                </Col>
                : null}
        </Row>
    )

}

export default AddSuggestEventAmount
