import cx from 'clsx';
import Color from 'color';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import {makeStyles} from '@material-ui/core/styles';
import {Item, Row} from '@mui-treasury/components/flex';
import {useCoverCardMediaStyles} from '@mui-treasury/styles/cardMedia/cover';
import randomColor from "randomcolor";
import React from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useWindowSize} from "../../tools/Tools";
import {artist_t} from "../../tools/Variables";
import SkeletonServiceCard from "../skeletonCustom/SkeletonServiceCard";
import Flicking from "@egjs/react-flicking";

const useStyles = makeStyles(({palette}) => ({
    color: ({color}: { color: string }) => ({
        '&:before': {
            backgroundColor: Color(color)
                .darken(0.3)
                .desaturate(0.2)
                .toString(),
        },
    }),
    root: {
        cursor: "pointer",
        position: 'relative',
        borderRadius: '1rem',
        width: 250,
        '&:before': {
            transition: '0.2s',
            position: 'absolute',
            width: '100%',
            height: '100%',
            content: '""',
            display: 'block',
            borderRadius: '1rem',
            zIndex: 0,
            bottom: 0,
        },
        '&:hover': {
            '&:before': {
                bottom: -6,
            },
            '& $logo': {
                transform: 'scale(1.1)',
                boxShadow: '0 6px 20px 0 rgba(0,0,0,0.38)',
            },
        },
    },
    cover: {
        borderRadius: '1rem',
    },
    content: ({color}: { color: string }) => ({
        position: 'relative',
        zIndex: 1,
        borderRadius: '1rem',
        boxShadow: `0 6px 16px 0 ${Color(color).fade(0.5)}`,
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 0,
            width: '100%',
            height: '100%',
            clipPath:
                'polygon(0% 100%, 0% 35%, 0.3% 33%, 1% 31%, 1.5% 30%, 2% 29%, 2.5% 28.4%, 3% 27.9%, 3.3% 27.6%, 5% 27%,95% 0%,100% 0%, 100% 100%)',
            borderRadius: '1rem',
            background: `linear-gradient(to top, ${color}, ${Color(color)
                .rotate(24)
                .lighten(0.12)})`,
        },
    }),
    title: {
        fontFamily: 'Fjalla One',
        fontSize: '1.35rem',
        color: '#fff',
        margin: 0,
    },
    events: {
        fontFamily: 'Fjalla One',
        fontSize: '0.85rem',
        color: '#fff',
        margin: 0,
    },
    logo: {
        transition: '0.3s',
        width: 75,
        height: 75,
        fontSize: '4rem',
        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.24)',
        borderRadius: '1rem',
    },
    genre: {
        fontFamily: 'Sen',
        fontSize: '0.85rem',
        color: "#fff",
    },
    type: {
        fontFamily: 'Sen',
        fontSize: '0.85rem',
        color: "#fff",
    },
    service_title: {
        fontFamily: 'Sen',
        color: '#fff',
        backgroundColor: palette.text.hint,
        opacity: 0.72,
        fontSize: '0.95rem',
        padding: '0 0.5rem',
        borderRadius: 12,
    },
}));

const CustomCard = ({styles, cover, logo, title, brand, genre, events, id, artist_type}) => {

    const navigate = useNavigate();
    const mediaStyles = useCoverCardMediaStyles();

    return (
        <Box className={cx(styles.root, styles.color)} pt={20}
             onClick={() => navigate('/artist/service/' + id.toString())}>
            <CardMedia image={cover} className={styles.cover} classes={mediaStyles}/>
            <Box className={styles.content} p={2}>
                <Box position={'relative'} zIndex={1}>
                    <Row p={0} gap={2}>
                        <Item>
                            {logo ?
                                <Avatar className={styles.logo} src={logo}/> :
                                <Avatar className={styles.logo}>
                                    {brand?.substring(0, 1).toUpperCase()}
                                </Avatar>}
                        </Item>
                        <Item position={'bottom'}>
                            <h2 className={styles.title}>
                                {brand?.substring(0, 12)}
                                {brand.length > 12 && "..."}&nbsp;
                            </h2>
                            <small className={styles.type}>{artist_type}</small><br/>

                        </Item>
                    </Row>
                    <Row mt={1} alignItems={'center'}>
                        <Item className={styles.events}>
                            {events.join(", ")?.substring(0, 32)}
                            {events.join(", ")?.length > 32 && " ..."}
                        </Item>
                    </Row>
                    <Row mt={1} alignItems={'center'}>
                        <Item>
                            <div className={styles.genre}>
                                {genre.join(", ").substring(0, 20)}
                                {genre.join(", ").length > 20 && " ..."}
                            </div>
                        </Item>
                        <Item position={'right'}>
                            <div className={styles.service_title}>
                                {title?.substring(0, 22)}
                                {title.length > 22 && "..."}
                            </div>
                        </Item>
                    </Row>
                </Box>
            </Box>
        </Box>
    );
};

const checkAdaptationFlickingCamera = (width) => {
    if (width < 325 && width >= 300) {
        return "50%"
    } else if (width < 350 && width >= 325) {
        return "44%"
    }  else if (width < 375 && width >= 350) {
        return "43.8%"
    }  else if (width < 400 && width >= 375) {
        return "43.6%"
    }  else if (width < 425 && width >= 400) {
        return "43.3%"
    }  else if (width < 450 && width >= 425) {
        return "42.8%"
    }  else if (width < 475 && width >= 450) {
        return "42.2%"
    }  else if (width < 500 && width >= 475) {
        return "41.8%"
    }  else if (width < 525 && width >= 500) {
        return "41.2%"
    }  else if (width < 550 && width >= 525) {
        return "40.6%"
    }  else if (width < 575 && width >= 550) {
        return "39.8%"
    }  else if (width < 600 && width >= 575) {
        return "39.2%"
    } else if (width < 625 && width >= 600) {
        return "38.2%"
    } else if (width < 650 && width >= 625) {
        return "37.8%"
    } else if (width < 675 && width >= 650) {
        return "37.2%"
    } else if (width < 700 && width >= 675) {
        return "36.6%"
    } else if (width < 725 && width >= 700) {
        return "35.8%"
    } else if (width < 750 && width >= 725) {
        return "35.4%"
    } else if (width < 775 && width >= 750) {
        return "35%"
    } else if (width < 800 && width >= 775) {
        return "34.8%"
    } else if (width < 825 && width >= 800) {
        return "34.2%"
    } else if (width < 850 && width >= 825) {
        return "33.6%"
    } else if (width < 875 && width >= 850) {
        return "33%"
    } else if (width < 900 && width >= 875) {
        return "32.8%"
    } else if (width < 925 && width >= 900) {
        return "32%"
    } else if (width < 950 && width >= 925) {
        return "31.6%"
    } else if (width < 975 && width >= 950) {
        return "31%"
    } else if (width < 1000 && width >= 975) {
        return "30.6%"
    }  else if (width < 1025 && width >= 1000) {
        return "40%"
    }  else if (width < 1050 && width >= 1025) {
        return "29.8%"
    }  else if (width < 1075 && width >= 1050) {
        return "29.2%"
    }  else if (width < 1100 && width >= 1075) {
        return "28.8%"
    }  else if (width < 1125 && width >= 1100) {
        return "28.2%"
    }  else if (width < 1150 && width >= 1125) {
        return "27.8%"
    }  else if (width < 1175 && width >= 1150) {
        return "27.2%"
    }  else if (width < 1200 && width >= 1175) {
        return "26.8%"
    }  else if (width < 1225 && width >= 1200) {
        return "26.2%"
    }  else if (width < 1250 && width >= 1225) {
        return "25.8%"
    }  else if (width < 1275 && width >= 1250) {
        return "25.2%"
    }  else if (width < 1300 && width >= 1275) {
        return "24.8%"
    }  else if (width < 1325 && width >= 1300) {
        return "24.2%"
    }   else if (width < 1350 && width >= 1325) {
        return "23.8%"
    }   else if (width < 1375 && width >= 1350) {
        return "22%"
    }   else if (width < 1400 && width >= 1375) {
        return "21.8%"
    }   else if (width < 1425 && width >= 1400) {
        return "21.2%"
    }   else if (width < 1450 && width >= 1425) {
        return "20.8%"
    }   else if (width < 1475 && width >= 1450) {
        return "20.2%"
    }   else if (width < 1500 && width >= 1475) {
        return "20%"
    }   else if (width < 1525 && width >= 1500) {
        return "20%"
    }   else if (width < 1550 && width >= 1525) {
        return "20%"
    }
    // }   else if (width < 1575 && width >= 1550) {
    //     return "20%"
    // }   else if (width < 1600 && width >= 1575) {
    //     return "20%"
    // }   else if (width < 1625 && width >= 1600) {
    //     return "20%"
    // }   else if (width < 1650 && width >= 1625) {
    //     return "20%"
    // }   else if (width < 1675 && width >= 1650) {
    //     return "20%"
    // }   else if (width < 1700 && width >= 1675) {
    //     return "20%"
    // }   else if (width < 1725 && width >= 1700) {
    //     return "20%"
    // }   else if (width < 1750 && width >= 1725) {
    //     return "20%"
    // }   else if (width < 1775 && width >= 1750) {
    //     return "20%"
    // }   else if (width < 1800 && width >= 1775) {
    //     return "20%"
    // }

    return "20%"
}

export const ServiceCard = ({itemKey, data}) => {

    const width = useWindowSize();
    const styles = useStyles({color: randomColor({luminosity: 'dark'})});
    const currency = useSelector(state => state.global.globalCurrencySymbol);

    return (
        <Flicking
            key={itemKey}
            align={{camera: checkAdaptationFlickingCamera(width), panel: "40px"}}
            circular={true}>
            {!data?.length
                ? Array.from(Array(8).keys()).map((key, index) =>
                    <div className="panel p-2" key={key + "shadow"}>
                        <SkeletonServiceCard/>
                    </div>
                )
                : data?.map((_data, index) => {
                    return (
                        <div key={itemKey + index} className="panel p-2">
                            <CustomCard
                                id={_data.id}
                                styles={styles}
                                brand={_data.artist_name}
                                artist_type={artist_t[_data.artist_type]}
                                price={_data.price + ' ' + currency}
                                cover={_data.galleries[0]}
                                logo={_data.artist_photo}
                                title={_data.title}
                                events={_data.events}
                                genre={_data.thematics}
                            />
                        </div>
                    );
                })}
            {data?.length
                ? Array.from(Array(8 - data?.length).keys()).map((key, index) =>
                    <div className="panel p-2" key={key + "shadow"}>
                        <SkeletonServiceCard/>
                    </div>
                ) : null}
        </Flicking>
    );
}
