const initState = {
    dj: [],
    others: [],
    artistMusician: [],
    loadingResult: true,
};

const Services = (state = initState, action) => {
    switch (action.type) {
        case "ADD_DJ_SERVICES_DATA":
            return {
                ...state,
                dj: action.data
            };
        case "ADD_OTHERS_SERVICES_DATA":
            return {
                ...state,
                others: action.data
            };
        case "ADD_ARTIST_MUSICIAN_SERVICES_DATA":
            return {
                ...state,
                artistMusician: action.data
            };
        case "CHANGE_LOADING_RESULT":
            return {
                ...state,
                loadingResult: action.data
            };
        default:
            return state;
    }
};

export default Services;
