const initState = {
    userId: 0,
    profileData: {},
    role: '',
    notes: '',
    followers: 0,
    followings: 0,
    services: [],
    users_who_rated: {},
};

const ProfileChecked = (state = initState, action) => {
    switch (action.type) {
        case "ADD_PROFILE_CHECKED_USERS_RATED":
            return {
                ...state,
                users_who_rated: action.data
            };
        case "ADD_PROFILE_CHECKED_ROLE":
            return {
                ...state,
                role: action.data
            };
        case "ADD_PROFILE_CHECKED_USER_ID":
            return {
                ...state,
                userId: action.data
            };
        case "ADD_PROFILE_CHECKED_FOLLOWERS":
            return {
                ...state,
                followers: action.data
            };
        case "ADD_PROFILE_CHECKED_FOLLOWINGS":
            return {
                ...state,
                followings: action.data
            };
        case "ADD_PROFILE_CHECKED_DATA":
            return {
                ...state,
                profileData: action.data
            };
        case "ADD_PROFILE_CHECKED_SERVICES":
            return {
                ...state,
                services: action.data
            };
        case "ADD_PROFILE_CHECKED_NOTES":
            return {
                ...state,
                notes: action.data
            };
        default:
            return state;
    }
};

export default ProfileChecked;
