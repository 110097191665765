import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {FormControl, IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from 'react-hot-toast';
import {useDispatch, useSelector} from "react-redux";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {CardFooter, CardHeader, CardTitle, Col, Form, FormGroup, Row, Spinner} from "reactstrap";
import "../../assets/css/keyValidation.css";
import {addAuthCredentials} from "../../reducer";
import {
    AntSwitch, btnStyle,
    changeFields,
    checkErrorMessage, createSession,
    ITextField,
    registerValidation
} from "../../tools/Tools";
import NewArtist from "../NewArtist";
import Privacy from "../Privacy";
import LoginGoogle from "./Google";
import Validation from "./Validation";

function Register(props) {

    const {closeModal} = props
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rules, setRules] = useState(false);
    const [process, setProcess] = useState(false);
    const [toArtist, setToArtist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [rulesPop, setRulesPop] = useState(false);
    const currency = useSelector(state => state.global.globalCurrencyCode);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const [verificationCode, setVerificationCode] = useState(false);
    const ipAddress = useSelector(state => state.global.ipAddress);

    const register = useCallback(async () => {
        setLoading(true)
        let validator = registerValidation(name, email, password, rules, true)
        if (validator['error']) {
            setLoading(false)
            toast.error(validator['message']);
        } else {
            let bodyFormData = new FormData();
            bodyFormData.append('currency', currency);
            bodyFormData.append('name', name);
            bodyFormData.append('email', email);
            bodyFormData.append('password', password);
            await auditorForm.post('users/register', bodyFormData).then(response => {
                setLoading(false)
                dispatch(addAuthCredentials(response.data));
                setVerificationCode(true)
                toast.success('Inscription validé, veuillez maintenant valider votre compte.')
            }).catch(async (error) => {
                setLoading(false)
                let message = checkErrorMessage(error).message;
                toast.error(message);
            })
        }
    }, [auditorForm, dispatch, currency, name, email, password, rules])

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;

        if ((toArtist || rulesPop || verificationCode) && keyCode === 13 && key === 'Enter') {
            event.preventDefault()
        } else if (keyCode === 27 && key === 'Escape' && !toArtist && !rulesPop && !verificationCode) {
            closeModal()
        } else if (keyCode === 13 && key === 'Enter' && !toArtist && !rulesPop && !verificationCode) {
            register().then(r => null)
        }

    }, [closeModal, register, rulesPop, toArtist, verificationCode]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className="justify-content-center">

            <Modal size="md" show={toArtist}>
                <NewArtist toClose={async () => {
                    closeModal()
                    setToArtist(false)
                }} setProcess={setProcess}/>
                <CardFooter>
                    <Row className="justify-content-end">
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                size="small"
                                disabled={process}
                                aria-label="settings"
                                sx={btnStyle('gray')}
                                onClick={() => setToArtist(false)}>
                                <CloseIcon sx={{color: "gray!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            <Modal size="md" show={rulesPop}>

                <div>
                    <h5 className="m-auto pl-3 pt-3 pr-3 text-center">
                        CONDITIONS GÉNÉRALES D'UTILISATION ET DE VENTE
                    </h5>
                    <hr/>
                </div>

                <div className="mb-4 pl-4 pr-4">
                    <Form>
                        <Col md="12">
                            <Privacy toModal/>
                        </Col>
                    </Form>
                </div>

                <CardFooter>
                    <Row className="justify-content-end">
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                aria-label="settings"
                                size="small"
                                sx={btnStyle('gray')}
                                onClick={() => setRulesPop(false)}>
                                <CloseIcon sx={{color: "gray!important"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Accepter" placement="top">
                            <IconButton
                                size="small"
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => {
                                    setRules(true)
                                    setRulesPop(false)
                                }}>
                                <small>Accepter&nbsp;</small>
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            {!verificationCode &&
                <CardHeader className="m-auto text-center pt-3">
                    <CardTitle tag="h5">
                        Créer mon compte KantoBiz
                    </CardTitle>
                    <div className="row justify-content-center">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Auditeur Pro</Typography>
                            <AntSwitch
                                checked={toArtist}
                                onChange={() => setToArtist(true)}
                                inputProps={{'aria-label': 'ant design'}}/>
                            <Typography>Artiste</Typography>
                        </Stack>
                    </div>
                </CardHeader>}

            <div className="p-2 mb-2">
                {!verificationCode ?
                    <div className="mt-4">
                        <Row className="justify-content-center">
                            <Col md="10">
                                <FormGroup>
                                    {ITextField('Nom', null, name, (e) => changeFields(setName, e))}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="10">
                                <FormGroup>
                                    {ITextField('Email', null, email, (e) => changeFields(setEmail, e))}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="10">
                                <FormControl variant="standard" fullWidth>
                                    {ITextField('Mot de passe', 'password', password, (e) => changeFields(setPassword, e))}
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Col md="10">
                                <FormGroup>
                                    <div className="material-switch text-center">
                                        <input type="checkbox"
                                               disabled={loading || verificationCode}
                                               className="cursor-pointer"
                                               onChange={() => setRules(!rules)}
                                               checked={rules}/>
                                        <small onClick={() => setRulesPop(true)}
                                               className="text-monospacecursor-pointer border-bottom ml-1 text-muted">
                                            J'ai lu et j'accepte les conditions d'utilisation.
                                        </small>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div> :
                    <Validation email={email} closeModal={closeModal}/>}
            </div>

            <CardFooter>
                <Row className="justify-content-end">
                    <Tooltip title="Fermer cette onglet" placement="top">
                        <IconButton
                            disabled={loading || verificationCode}
                            aria-label="settings"
                            size="small"
                            sx={btnStyle('gray')}
                            onClick={() => closeModal()}>
                            <CloseIcon sx={{color: "gray!important"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="S'inscrire" placement="top">
                        <IconButton
                            size="small"
                            sx={btnStyle('lightGreen')}
                            disabled={loading || verificationCode}
                            aria-label="settings"
                            onClick={() => register().then(() => setLoading(false))}>
                            S'inscrire&nbsp;
                            {loading
                                ? <Spinner type="grow" size="sm"/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="S'inscrire avec Google" placement="top">
                        <IconButton disabled={loading || verificationCode} aria-label="settings">
                            <LoginGoogle LogIn callBack={(data) => createSession(data, dispatch, ipAddress, setLoading, closeModal)} _setLoad={setLoading}/>
                        </IconButton>
                    </Tooltip>
                </Row>
            </CardFooter>

        </div>
    );
}

export default Register;
