import React, {useRef, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {addContactCurrency, addContactCurrencySymbol, addContactFormToSend, addContactService} from "../../reducer";
import {checkServiceId} from "../../tools/Tools";
import ContactForm from "../reservations/ContactForm";
import SkeletonContact from "../skeletonCustom/SkeletonContact";
import {Row} from "reactstrap";


function Contact() {

    let {id} = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const isMounted = useRef(false);
    const userId = useSelector(state => state.profile.userId);
    const auditor = useSelector(state => state.axios.auditor);
    const {countries} = useSelector(state => state.global);
    const auth = useSelector(state => state.session.authenticated);
    const service = useSelector(state => state.toContact.service);
    const results = useSelector(state => state.searchBarData.results);
    const [loading, setLoading] = useState(true);
    const [lastContactForm, setLastContactForm] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState('');
    const [paymentIntentSecret, setPaymentIntentSecret] = useState('');
    const [redirectStatus, setRedirectStatus] = useState('');
    const send = useSelector(state => state.toContact.send);


    const serviceChecked = async (_s) => {

        send && dispatch(addContactFormToSend(false))

        let x = {}
        searchParams.forEach((value, key) => {
            x[key] = value
        });

        let {payment_intent, redirect_status, payment_intent_client_secret} = x

        if (payment_intent && redirect_status && payment_intent_client_secret) {
            setPaymentIntent(payment_intent)
            setRedirectStatus(redirect_status)
            setPaymentIntentSecret(payment_intent_client_secret)

            redirect_status === 'failed' && toast.error("Il y a eu une erreur lors de la transaction, veuillez réessayer.")
        }

        const tmp = JSON.parse(localStorage.getItem('contactForm'));
        if (tmp) {
            setLastContactForm(tmp)
        }

        dispatch(addContactService(_s))
        countries.forEach((country) => {
            if (_s.country.toLowerCase() === country['name'].toLowerCase()
                || _s.country.toLowerCase() === country['nativeName'].toLowerCase()) {
                dispatch(addContactCurrency(country?.currencies?.code))
                dispatch(addContactCurrencySymbol(country?.currencies?.symbol))
            }
        });

        setLoading(false)
    }

    useEffect(() => {
        if (countries?.length && !isMounted.current) {
            checkServiceId(results, serviceChecked, setLoading, navigate, auditor, id).then(r => null);

            return () => {
                isMounted.current = true
            };
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [id, navigate, results, auditor, countries]);

    useEffect(() => {
        if (auth && userId === service?.user_id) {
            navigate(-1)
        }
    }, [navigate, auth, userId, service?.user_id]);

    return (
        <div className="content pt-3">
            <Row className="justify-content-center ml-2 mr-2 mt-2 bg-transparent border-0 overflow-auto"
                 style={{height: "80vh"}}>
                {loading
                    ? <SkeletonContact/>
                    : <ContactForm
                        redirectStatus={redirectStatus}
                        paymentIntent={paymentIntent}
                        lastContactForm={lastContactForm}
                        paymentIntentSecret={paymentIntentSecret}/>}
            </Row>
        </div>
    )
}

export default Contact;
